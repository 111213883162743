<template>
  <div class="content-wrapper pa-4">
    <v-layout class="flex-column flex-md-row">
      <project-edit-left-side-panel-container />

      <div class="flex-grow-1 ma-4">
        <v-tabs
          v-model="selectedTab"
          active-class="tab-active"
          class="mb-4"
          hide-slider
          show-arrows
        >
          <v-tab
            key="catalogs"
            href="#catalogs"
          >
            Catalogues
          </v-tab>

          <v-tab
            key="files"
            href="#files"
          >
            Files
          </v-tab>

          <v-tab
            key="urls"
            href="#urls"
          >
            URLs
          </v-tab>

          <v-tab
            key="users"
            href="#users"
          >
            Users
          </v-tab>

          <v-tab
            key="configurations"
            href="#configurations"
          >
            Configurations
          </v-tab>

          <v-tab
            key="models"
            href="#models"
          >
            Background
          </v-tab>

          <v-tab
            key="grid"
            href="#grid"
          >
            Grid
          </v-tab>

          <v-tab
            key="rules"
            href="#rules"
          >
            Rule Models
          </v-tab>
        </v-tabs>

        <div class="tabs-content-section pa-5">
          <v-tabs-items v-model="selectedTab">
            <v-tab-item
              key="catalogs"
              value="catalogs"
            >
              <project-tab-catalogues-container />
            </v-tab-item>

            <v-tab-item
              key="configurations"
              value="configurations"
            >
              <project-tab-configurations-container :key="rerender"/>
            </v-tab-item>

            <v-tab-item
              key="models"
              value="models"
            >
              <project-tab-models-container />
            </v-tab-item>

            <v-tab-item
              key="grid"
              value="grid"
            >
              <project-tab-grid-container />
            </v-tab-item>

            <v-tab-item
              key="rules"
              value="rules"
            >
              <project-tab-rule-container/>
            </v-tab-item>

          </v-tabs-items>
        </div>
      </div>
    </v-layout>
  </div>
</template>

<script>
import ProjectEditLeftSidePanelContainer from '@/components/project/edit/ProjectEditLeftSidePanelContainer'
import ProjectTabConfigurationsContainer from '@/components/project/tab/ProjectTabConfigurationsContainer'
import ProjectTabModelsContainer from '@/components/project/tab/ProjectTabModelsContainer.vue'
import ProjectTabGridContainer from '@/components/project/tab/ProjectTabGridContainer.vue'
import ProjectTabCataloguesContainer from '@/components/project/tab/ProjectTabCataloguesContainer.vue'
import ProjectTabRuleContainer from '@/components/project/tab/ProjectTabRuleContainer'

export default {
  name: 'ProjectSingleOverview',
  components: {
    ProjectTabCataloguesContainer,
    ProjectTabGridContainer,
    ProjectTabModelsContainer,
    ProjectTabConfigurationsContainer,
    ProjectEditLeftSidePanelContainer,
    ProjectTabRuleContainer
  },

  computed: {
    selectedTab: {
      get () {
        return this.$route.query.tab
      },
      async set (tab) {
        await this.$router.push({
          query: { ...this.$route.query, tab }
        })
      }
    },

    rerender () {
      return this.$store.state.project.rerender
    }
  }

}
</script>

<style lang="scss" scoped>
.field-editable-text {
  color: var(--v-grey-base);
}
</style>
