<template>
  <div class="pl-5 pt-2 shell-container overflow-auto">
    <div v-for="shell in projectRuleModel.shells" :key="shell.id">
      <project-tab-rule-shell :shell="shell"/>
    </div>
  </div>
</template>

<script>
import ProjectTabRuleShell from '@/components/project/tab/rules/ProjectTabRuleShell'

export default {
  name: "ProjectTabRuleShellContainer",
  components: { ProjectTabRuleShell },
  data () {
    return {
    }
  },
  computed: {
    projectRuleModel () {
      return this.$store.state.project.ruleModel
    },
  }
}
</script>

<style scoped lang="scss">
  .shell-container {
    max-height: 400px;
  }
</style>