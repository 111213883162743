<template>
  <div>
    <app-single-item-edit-left-section
      v-if="moduleProp"
      :entity-item-prop="moduleProp"
      :entity-type-prop="'module'"
      @showEditDescriptionDialog="$emit('showEditDescriptionDialog')"
      @showEditImageDialog="$emit('showEditImageDialog')"
      @showEditModuleLogoDialog="$emit('showEditModuleLogoDialog')"
      @showEditNameDialog="$emit('showEditNameDialog')"
    >
      <template #other-edit-fields>

        <div class="flex-column justify-start">
          <div class="wtf text-regular text-justify pb-4">
            <v-hover v-slot="{ hover }">
              <div
                class="description-text hover-text-effect"
                style="cursor:pointer;"
                @click="$emit('showEditDimensionsDialog')"
              >
              <span
                v-if="moduleProp.width"
                class="entity-fields-text-span dimensions"
              >
                <b> Bounding size: </b> {{ moduleProp.width }} x {{ moduleProp.height }} x {{ moduleProp.depth }} m
              </span>

                <v-btn
                  v-if="true"
                  :class="{ 'element-invisible': !hover }"
                  class="entity-fields-edit-btn ml-1"
                  color="primary"
                  height="22"
                  icon
                  small
                  width="22"
                >
                  <app-svg
                    size="14"
                    src="~/assets/img/icons/edit.svg"
                  />
                </v-btn>
              </div>
            </v-hover>
          </div>

<!--          Request from Deyan Mihow to remove tags on Production-->
<!--          <div class="pt-8">-->
<!--            <v-hover v-slot="{ hover }">-->
<!--              <div-->
<!--                class="description-text hover-text-effect"-->
<!--                style="cursor:pointer;"-->
<!--                @click="$emit('showTagsDialog')"-->
<!--              >-->
<!--              <span-->
<!--                v-if="moduleProp.width"-->
<!--                class="entity-fields-text-span dimensions"-->
<!--              >-->
<!--                <b> Tags </b>-->
<!--              </span>-->

<!--                <v-btn-->
<!--                  v-if="true"-->
<!--                  :class="{ 'element-invisible': !hover }"-->
<!--                  class="entity-fields-edit-btn ml-1"-->
<!--                  color="primary"-->
<!--                  height="22"-->
<!--                  icon-->
<!--                  small-->
<!--                  width="22"-->
<!--                >-->
<!--                  <app-svg-->
<!--                    size="14"-->
<!--                    src="~/assets/img/icons/edit.svg"-->
<!--                  />-->
<!--                </v-btn>-->
<!--              </div>-->
<!--            </v-hover>-->
<!--          </div>-->


<!--          Request from Deyan Mihow to remove tags on Production-->
<!--          <div class="pt-8 pl-3">-->
<!--            <v-row class="align-center">-->
<!--              <v-chip-->
<!--                class="tag-item ma-1"-->
<!--                v-for="tag in moduleProp.tags"-->
<!--                :key="tag.id"-->
<!--                :color="'v-grey-darken1'"-->
<!--                small-->
<!--              >-->
<!--                {{ tag.name }}-->
<!--              </v-chip>-->
<!--            </v-row>-->
<!--          </div>-->

          <div class="pt-8" style="width: fit-content;">
            <v-tooltip
              bottom
              color="primary"
            >
              <span>Remove</span>
              <template #activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  @click="$emit('showDeleteDialog')"
                  v-on="on"
                >
                  <app-svg
                    size="24"
                    src="~/assets/img/icons/delete.svg"
                    color="primary"
                    class="delete-icon"
                  />
                </div>
              </template>

            </v-tooltip>
          </div>


        </div>

      </template>
    </app-single-item-edit-left-section>
  </div>
</template>

<script>
import AppSingleItemEditLeftSection from '@/components/app/AppSingleItemEditLeftSection'

import AppSvg from '@/components/app/AppSvg'

export default {
  name: "ModuleEditLeftSidePanel",
  components: {
    AppSvg,
    AppSingleItemEditLeftSection
  },

  props: {
    moduleProp: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.tag-item {
  font-size: 12px;
}

</style>
