<template>
  <div class="category-form">
    <div>Are you sure you want to delete this category?</div>

    <div class="form-actions guttered-row inline dense">
      <v-btn
        class="inline-category-delete-cancel-button"
        color="primary"
        outlined
        rounded
        small
        @click.prevent="$emit('cancelInlineEdit')"
      >
        Cancel
      </v-btn>

      <v-btn
        class="inline-category-delete-button"
        color="primary"
        rounded
        small
        @click.prevent.stop="$emit('removeCategory')"
      >
        Confirm
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryFormInlineDelete"

}
</script>

<style scoped>

</style>
