<template>
  <app-dialog
      v-model="isDialogVisible"
      :title-prop="'Remove Background'"
      max-width="800"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <div>
      Are you sure that you want to remove
      <strong>
        {{ projectBackgroundProp.name }}
      </strong>
      ?
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
              id="close-dialog-button"
              block
              color="primary"
              outlined
              raised
              rounded
              x-large
              @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
              id="submit-button"
              block
              color="primary"
              raised
              rounded
              x-large
              :loading="loading"
              @click="detachBackground"
          >
            <span class="text-capitalize">
              Confirm
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>

  </app-dialog>
</template>


<script>
import AppDialog from '@/components/app/AppDialog.vue'
import ProjectService from '@/services/project.service'

export default {
  name: 'ProjectTabBackgroundDialogRemoveBackground',
  components: {AppDialog},

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    projectBackgroundProp: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

    async detachBackground () {
      try {
        this.loading = true
        await ProjectService.detachBackgroundModel({
          id: this.projectBackgroundProp.id
        })
      } finally {
        this.loading = false
        this.$emit('fetchProject')
        this.hideDialog()
      }
    }
  }

}
</script>



<style scoped lang="scss">

</style>