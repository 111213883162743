import { render, staticRenderFns } from "./ProjectTabCatalogues.vue?vue&type=template&id=44e51ce2&scoped=true&"
import script from "./ProjectTabCatalogues.vue?vue&type=script&lang=js&"
export * from "./ProjectTabCatalogues.vue?vue&type=script&lang=js&"
import style0 from "./ProjectTabCatalogues.vue?vue&type=style&index=0&id=44e51ce2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44e51ce2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBtn,VDivider,VImg,VSpacer})
