import $axios from '../plugins/axios'

class ProjectService {
  getAllProjects () {
    return $axios.get(`/get-projects`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleProject (projectId) {
    return $axios.get(`/get-project/${ projectId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  createProject (project) {
    return $axios.post('/create-project', project)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  deleteProject (payload) {
    return $axios.post('/delete-project', payload)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeProjectName (project) {
    return $axios.post(`/change-project-name`, project)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeProjectPicture (project) {
    return $axios.post(`/change-project-image`, project)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeProjectLogo (project) {
    return $axios.post(`/change-project-logo`, project)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeProjectDescription (project) {
    return $axios.post(`/change-project-description`, project)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProjectConfigurations (projectId) {
    return $axios.get(`/get-project-configurations/${ projectId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProjectBackgroundModels (projectId) {
    return $axios.get(`/get-project-background-models/${ projectId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getOrganizationBackgroundModels () {
    return $axios.get('/get-organization-background-models')
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  attachBackgroundModel (data) {
    return $axios.post('/attach-background-model', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  detachBackgroundModel (data) {
    return $axios.post('/detach-background-model', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }


  getProjectGridModel (projectId) {
    return $axios.get(`/get-project-grid-model/${ projectId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProjectCatalogues (projectId) {
    return $axios.get(`/get-project-catalogues/${ projectId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProjectAvailableCatalogues (projectId) {
    return $axios.get(`/get-available-catalogues-for-project/${ projectId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  assignCataloguesToProject (data) {
    return $axios.post(`/assign-catalogues-to-project`, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  deleteCataloguesForProject (data) {
    return $axios.post(`/remove-catalogues-from-project`, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProjectRuleModel (projectId) {
    return $axios.get(`/get-project-rule-model/${projectId}`)
      .then((res) => {
        if (res.status === 204) {
          return null
        }
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProjectAvailableParameters (projectId) {
    return $axios.get(`get-projects-available-parameters/${projectId}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  updateProjectRuleModel ({ data, ruleModelId }) {
    return $axios.post(`/update-rule-model-rules/${ruleModelId}`, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  deleteConfiguration (configurationId) {
    return $axios.post('delete-configuration', { id: configurationId })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  restoreConfiguration (configurationId) {
    return $axios.post('restore-configuration', { id: configurationId })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  hardDeleteConfiguration (configurationId) {
    return $axios.post('hard-delete-configuration', { id: configurationId })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }


}

export default new ProjectService()
