<template>
  <app-dialog
      v-model="isDialogVisible"
      :persistent="isLoadingProp"
      :title-prop="'Remove'"
      max-width="500"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <div>
      <span>Do you really want to remove the following: ?</span>

      <ul v-for="(name, index) in itemNamesProp" :key="index" >
        <li>
          <strong> {{ name }} </strong>
        </li>
      </ul>
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
              id="close-dialog-button"
              :disabled="isLoadingProp"
              block
              color="primary"
              outlined
              raised
              rounded
              x-large
              @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
              raised
              rounded
              x-large
              block
              id="submit-button"
              color="primary"
              @click.native="deleteItems"
              :loading="isLoadingProp"
              :disabled="isLoadingProp"
          >
            <span class="text-capitalize">
              Confirm
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'

export default {
  name: 'AppDialogMultiDelete',
  components: { AppDialog },

  data () {
    return {
      loading: false
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    isLoadingProp: {
      type: Boolean,
      default: false,
      required: true
    },

    itemNamesProp: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },


    deleteItems () {
      this.$emit('deleteItems')
    }
  }
}
</script>

<style lang="scss" scoped>
.color-light-gray {
  color: var(--v-grey-lighten1);
}
</style>
