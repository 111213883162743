<template>
  <div>
    <module-edit-left-side-panel
      v-if="module"
      :module-prop="module"
      @showDeleteDialog="showDeleteDialog"
      @showEditDimensionsDialog="showEditDimensionsDialog"
      @showEditImageDialog="showEditImageDialog"
      @showEditNameDialog="showEditNameDialog"
      @showTagsDialog="showTagsDialog"
    />

    <app-image-dialog-edit
      v-if="isEditImageDialogVisible"
      :key="updateImageDialogKey + '-change-image-dialog'"
      v-model="isEditImageDialogVisible"
      :image-prop.sync="module.image"
      :is-error-occurred-while-uploading-prop.sync="isErrorOccurredWhileUploading"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      :validation-error-message-prop="validationErrorMessage"
      @changeImage="changeModuleImage"
    />

    <module-dialog-edit-name
      v-if="isEditNameDialogVisible"
      :key="editNameDialogKey + '-module-dialog'"
      v-model="isEditNameDialogVisible"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      :is-name-already-exist-prop.sync="isNameAlreadyExist"
      :module-name-prop="module.name"
      :title-prop="'Edit Module'"
      :validation-error-message-prop="validationErrorMessage"
      @changeModuleName="changeModuleName"
    />

    <module-dialog-edit-bounding-size
      v-if="isEditDimensionsDialogVisible"
      :key="editDimensionsDialogKey + '-module-bounding-size-dialog'"
      v-model="isEditDimensionsDialogVisible"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      :module-prop="module"
      :title-prop="'Edit Module'"
      @changeModuleDimensions="changeModuleDimensions"
    />

    <module-dialog-delete
      v-if="isDeleteDialogVisible"
      :key="deleteDialogKey + '-delete-dialog'"
      v-model="isDeleteDialogVisible"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      @deleteModule="deleteModule"
    />

    <module-dialog-tags
      v-if="isTagsDialogVisible"
      :key="isTagsDialogVisible + '-tags-dialog'"
      v-model="isTagsDialogVisible"
    />
  </div>
</template>

<script>
import AppImageDialogEdit from '@/components/app/image/AppImageDialogEdit'

import ModuleDialogEditName from '@/components/module/ModuleDialogEditName'
import ModuleDialogEditBoundingSize from '@/components/module/ModuleDialogEditBoundingSize'
import ModuleDialogDelete from '@/components/module/ModuleDialogDelete'
import ModuleDialogTags from '@/components/module/ModuleDialogTags'

import { mapGetters } from 'vuex'
import { actionStates } from '@/store/modules/module'
import ModuleEditLeftSidePanel from '@/components/module/edit/ModuleEditLeftSidePanel'

export default {
  name: "ModuleEditLeftSidePanelContainer",
  components: {
    ModuleEditLeftSidePanel,
    ModuleDialogDelete,
    ModuleDialogEditBoundingSize,
    ModuleDialogEditName,
    AppImageDialogEdit,
    ModuleDialogTags
  },

  data () {
    return {
      isEditNameDialogVisible: false,
      editNameDialogKey: 0,

      isEditImageDialogVisible: false,
      updateImageDialogKey: 0,

      isEditDimensionsDialogVisible: false,
      editDimensionsDialogKey: 0,

      isDeleteDialogVisible: false,
      deleteDialogKey: 0,

      actionStateConstants: actionStates,

      validationErrorMessage: '',
      isErrorOccurredWhileUploading: false,
      isNameAlreadyExist: false,

      isTagsDialogVisible: false,
      tagsDialogKey: 0
    }
  },

  computed: {
    ...mapGetters(
      {
        module: 'module/getModule',
        currentState: 'module/getCurrentState'
      }
    )
  },

  methods: {
    showEditNameDialog () {
      this.isEditNameDialogVisible = true
      this.editNameDialogKey++
    },

    closeEditNameDialog () {
      this.isEditNameDialogVisible = false
    },

    showEditImageDialog () {
      this.isEditImageDialogVisible = true
      this.updateImageDialogKey++
    },

    showEditDimensionsDialog () {
      this.isEditDimensionsDialogVisible = true
      this.editDimensionsDialogKey++
    },

    showTagsDialog () {
      this.isTagsDialogVisible = true
      this.tagsDialogKey++
    },

    showDeleteDialog () {
      this.isDeleteDialogVisible = true
      this.deleteDialogKey++
    },

    async changeModuleName (moduleName) {
      try {
        await this.$store.dispatch('module/updateModuleName', { id: this.module.id, name: moduleName })
      } catch (error) {
        if (error.response.status === 422) {
          this.isNameAlreadyExist = true
          this.validationErrorMessage = error.response.data.errors.name[0]
        }
      }
    },

    prepareFormDataForChangeImage (image) {
      let bodyFormData = new FormData()

      bodyFormData.set('id', this.module.id)
      bodyFormData.set('file', image)

      return bodyFormData
    },

    async changeModuleImage (image) {
      try {
        const dataForChangingModuleImage = this.prepareFormDataForChangeImage(image)
        await this.$store.dispatch('module/updateModulePicture', dataForChangingModuleImage)
      } catch (error) {
        if (error.response.status === 422) {
          this.isErrorOccurredWhileUploading = true
          this.validationErrorMessage = error.response.data.errors.file[0]
        }
      }
    },

    async changeModuleDimensions (moduleDimensions) {
      await this.$store.dispatch('module/updateModuleDimensions', { id: this.module.id, ...moduleDimensions })
    },

    async deleteModule () {
      await this.$store.dispatch('module/deleteModule',
  { ids: [ this.module.id ] }
      )
      await this.$router.replace('/modules')
    }
  }
}
</script>

<style scoped>

</style>
