var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-dialog',_vm._g(_vm._b({attrs:{"persistent":_vm.isLoadingProp,"title-prop":'Change image',"max-width":"800"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-spacer',{staticClass:"d-none d-sm-flex"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"id":"close-dialog-button","disabled":_vm.isLoadingProp,"block":"","color":"primary","outlined":"","raised":"","rounded":"","x-large":""},on:{"click":function($event){$event.preventDefault();return _vm.hideDialog.apply(null, arguments)}}},[_vm._v(" Cancel ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"id":"submit-button","disabled":_vm.isLoadingProp ||
            !_vm.$v.image.fileSizeValidation ||
            _vm.isImageChanged === false ||
            _vm.isErrorOccurredWhileUploadingProp ||
            _vm.isSelectedImageHaveValidFormat === false,"loading":_vm.isLoadingProp,"block":"","color":"primary","raised":"","rounded":"","x-large":""},nativeOn:{"click":function($event){return _vm.submitChangeImage.apply(null, arguments)}}},[_c('span',{staticClass:"text-capitalize"},[_vm._v(" Confirm ")])])],1)],1)]},proxy:true}]),model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},'app-dialog',_vm.$attrs,false),_vm.$listeners),[_c('div',[_c('div',{staticClass:"pl-4 pb-3"},[_c('span',[_vm._v(" Image ")])]),_c('div',{staticClass:"pa-4",staticStyle:{"border-radius":"4px","opacity":"1"},style:({'border': _vm.image ? '1px solid #C3C3C3' : 'none'})},[_c('v-img',{attrs:{"src":_vm.currentImageUrl,"contain":"","max-height":"300","max-width":"350"}},[(_vm.image)?_c('div',{staticClass:"selector-container"},[_c('v-tooltip',{staticClass:"text-center",attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":hover ? 'primary' : 'white',"fab":"","small":""},on:{"click":function($event){_vm.image = null}}},'v-btn',attrs,false),on),[_c('app-svg',{attrs:{"color":hover ? 'white' : 'var(--v-primary-base)',"size":"16","src":"~/assets/img/icons/delete.svg"}})],1)]}}],null,true)})]}}],null,false,4107057674)},[_c('span',[_vm._v("Delete")])])],1):_vm._e()])],1),_c('v-file-input',{attrs:{"append-icon":'mdi-camera',"clearable":false,"error-messages":_vm.imageErrors,"prepend-icon":'',"accept":".png, .jpg, .jpeg"},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }