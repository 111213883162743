<template>
  <div>
    <div class="header-sort-filter-search-wrapper">
      <app-data-sort-by
        :sort-options-prop="sortOptions"
        @changeSort="changeSort"
      />

      <app-data-filter
        :list-of-users-prop="listOfUsers"
        @filterByUser="filterByUser"
      />
    </div>

    <v-divider class="mt-5 mb-5" />

    <project-tab-configurations-list :configurations-prop="configurationsSortedAndFiltered" />
  </div>
</template>

<script>
import AppDataSortBy from '@/components/app/data/AppDataSortBy'
import AppDataFilter from '@/components/app/data/AppDataFilter'
import ProjectTabConfigurationsList from '@/components/project/tab/ProjectTabConfigurationsList'

export default {
  name: 'ProjectTabConfigurations',
  components: {
    ProjectTabConfigurationsList,
    AppDataFilter,
    AppDataSortBy
  },

  props: {
    configurationsProp: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      sortOptions: [
        { text: 'Name', value: 'name' },
        { text: 'Date Created', value: 'dateCreated' },
        { text: 'Last Updated', value: 'lastUpdated' },
        { text: 'Created By', value: 'createdBy' },
        { text: 'Number of Modules', value: 'numberOfModules' }
      ],

      configurationsSorted: this.configurationsProp,
      filteredUsersParam: []
    }
  },

  computed: {
    listOfUsers () {
      if (this.configurationsProp) {
        return [...new Map(this.configurationsProp.map((item) => [item.created_by_user.id, item.created_by_user])).values()]
      }

      return []
    },

    configurationsSortedAndFiltered () {
      if (this.filteredUsersParam.length === 0) {
        return this.configurationsSorted
      }

      return this.configurationsSorted.filter(
        (configuration) => this.filteredUsersParam.includes(configuration.created_by_user.id))
    }
  },

  methods: {
    configurationsSortByName () {
      this.configurationsSorted = this.configurationsSorted.sort((a, b) => a.name.localeCompare(b.name))
    },

    configurationsSortByDateCreated () {
      this.configurationsSorted = this.configurationsSorted.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    },

    configurationsSortByLastUpdated () {
      this.configurationsSorted = this.configurationsSorted.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
    },

    configurationsSortByCreatedByUserName () {
      this.configurationsSorted = this.configurationsSorted.sort((a, b) => a.created_by_user.name.localeCompare(b.created_by_user.name))
    },

    configurationsSortByNumberOfModules () {
      this.configurationsSorted = this.configurationsSorted.sort((a, b) => {
        let first = a.latest_save ? a.latest_save.modules : 0
        let seconds = b.latest_save ? b.latest_save.modules : 0
        return first - seconds
      })
    },

    changeSort (sortParam) {
      if (sortParam.value === 'name') {
        this.configurationsSortByName()
        return
      }

      if (sortParam.value === 'dateCreated') {
        this.configurationsSortByDateCreated()
        return
      }

      if (sortParam.value === 'lastUpdated') {
        this.configurationsSortByLastUpdated()
        return
      }

      if (sortParam.value === 'createdBy') {
        this.configurationsSortByCreatedByUserName()
        return
      }

      if (sortParam.value === 'numberOfModules') {
        this.configurationsSortByNumberOfModules()
      }
    },

    filterByUser (filteredUsersParam) {
      this.filteredUsersParam = filteredUsersParam
    }
  }

}
</script>

<style lang="scss">
.flex-item {
  min-width: 110px;
  flex-basis: 9%;
}

.header-sort-filter-search-wrapper {
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .v-input {
    height: 30px;

    .v-input__append-inner {
      padding: 2px;

      .v-input__icon {
        .v-icon {
          font-size: 1rem;
          color: var(--v-primary-base) !important;
        }
      }
    }

    .v-input__control {
      height: 30px;

      .v-select__slot {
        font-size: 0.75rem;

        input {
          color: var(--v-primary-base) !important;
        }

        .v-select__selections {
          .v-select__selection {
            font-size: 0.75rem;
            margin: 0;
            color: var(--v-primary-base) !important;
          }
        }
      }

      .v-select__slot ::placeholder {
        font-size: 0.75rem;
        color: var(--v-primary-base) !important;
        opacity: 1;
        padding-left: 4px;
      }

      .v-input__slot {
        padding: 7px;
        height: 30px;
        margin: 0;
        border-radius: 50px;
        display: flex;
        align-items: center;

        .v-input--selection-controls__input {
          margin: 0;
          padding-right: 4px;
        }
      }
    }
  }

}
</style>
