import $axios from '../plugins/axios'

class TagService {

  searchTags (search) {
      return $axios.get(`/search-tags?term=${search}`)
        .then((res) => {
          return res.data
        })
        .catch((error) => {
          throw error
        })
    }
}

export default new TagService()