<template>
  <app-dialog
    v-model="isDialogVisible"
    :persistent="isLoadingProp"
    :title-prop="titleProp"
    max-width="500"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <v-row class="align-center">
        <v-col>
          <v-text-field
            id="width-input-field"
            v-model.number="module.width"
            :error-messages="widthErrors"
            label="Width"
            min="0"
            required
            suffix="m"
            type="number"
            @blur="$v.module.width.$touch()"
            @change="inputModuleDimensionsWidthNumber(module.width)"
          />
        </v-col>

        <span class="color-light-gray"> X </span>

        <v-col>
          <v-text-field
            id="height-input-field"
            v-model="module.height"
            :error-messages="heightErrors"
            label="Height"
            required
            suffix="m"
            type="number"
            @blur="$v.module.height.$touch()"
            @change="inputModuleDimensionsHeightNumber(module.height)"
          />
        </v-col>

        <span class="color-light-gray"> X </span>

        <v-col>
          <v-text-field
            id="depth-input-field"
            v-model="module.depth"
            :error-messages="depthErrors"
            label="Depth"
            required
            suffix="m"
            type="number"
            @blur="$v.module.depth.$touch()"
            @change="inputModuleDimensionsDepthNumber(module.depth)"
          />
        </v-col>
      </v-row>
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            id="close-dialog-button"
            :disabled="isLoadingProp"
            block
            color="primary"
            outlined
            raised
            rounded
            x-large
            @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            id="submit-button"
            :disabled="isLoadingProp || $v.$anyError"
            :loading="isLoadingProp"
            block
            color="primary"
            raised
            rounded
            x-large
            @click.native="formSubmit"
          >
            <span class="text-capitalize">
              Edit
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import { maxValue, minValue, required } from 'vuelidate/lib/validators'
import AppDialog from '@/components/app/AppDialog'

export default {
  name: 'ModuleDialogEditBoundingSize',
  components: { AppDialog },

  props: {
    titleProp: {
      type: String,
      required: true
    },

    value: {
      type: Boolean,
      default: false,
      required: true
    },

    isLoadingProp: {
      type: Boolean,
      default: false,
      required: true
    },

    moduleProp: {
      type: Object,
      default: () => {
        return {
          width: 0,
          depth: 0,
          height: 0
        }
      }
    }
  },

  validations: {
    module: {
      width: {
        required,
        maxValueValue: maxValue(999.999),
        minValueValue: minValue(0.001)
      },

      depth: {
        required,
        maxValueValue: maxValue(999.999),
        minValueValue: minValue(0.001)
      },

      height: {
        required,
        maxValueValue: maxValue(999.999),
        minValueValue: minValue(0.001)
      }
    }
  },

  data () {
    return {
      module: Object.assign({}, this.moduleProp),
      maxInputValue: 100000
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    widthErrors () {
      const errors = []
      if (!this.$v.module.width.$dirty) {
        return errors
      }

      !this.$v.module.width.maxValueValue && errors.push('Max input number is 999.999')
      !this.$v.module.width.minValueValue && errors.push('Number should be positive')
      !this.$v.module.width.required && errors.push('Must contain at least 1 character')
      return errors
    },

    depthErrors () {
      const errors = []
      if (!this.$v.module.depth.$dirty) {
        return errors
      }

      !this.$v.module.depth.maxValueValue && errors.push('Max input number is 999.999')
      !this.$v.module.depth.minValueValue && errors.push('Number should be positive')
      !this.$v.module.depth.required && errors.push('Must contain at least 1 character')
      return errors
    },

    heightErrors () {
      const errors = []
      if (!this.$v.module.height.$dirty) {
        return errors
      }

      !this.$v.module.height.maxValueValue && errors.push('Max input number is 999.999')
      !this.$v.module.height.minValueValue && errors.push('Number should be positive')
      !this.$v.module.height.required && errors.push('Must contain at least 1 character')
      return errors
    }

  },

  watch: {
    'module.width' (newValue) {
      if (Number(newValue) > this.maxInputValue) {
        this.$nextTick(() => {
          this.module.width = this.maxInputValue
        })
      }
    },

    'module.height' (newValue) {
      if (Number(newValue) > this.maxInputValue) {
        this.$nextTick(() => {
          this.module.height = this.maxInputValue
        })
      }
    },

    'module.depth' (newValue) {
      if (Number(newValue) > this.maxInputValue) {
        this.$nextTick(() => {
          this.module.depth = this.maxInputValue
        })
      }
    }
  },

  methods: {
    hideDialog () {
      this.resetModule()
      this.isDialogVisible = false
    },

    resetModule () {
      this.module = null
    },

    formSubmit () {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      this.$emit('changeModuleDimensions', {
        depth: this.module.depth,
        width: this.module.width,
        height: this.module.height
      })
    },

    inputModuleDimensionsWidthNumber (value) {
      if (value) {
        this.module.width = Number.parseFloat(value).toFixed(3)
      }
    },

    inputModuleDimensionsDepthNumber (value) {
      if (value) {
        this.module.depth = Number.parseFloat(value).toFixed(3)
      }
    },

    inputModuleDimensionsHeightNumber (value) {
      if (value) {
        this.module.height = Number.parseFloat(value).toFixed(3)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.color-light-gray {
  color: var(--v-grey-lighten1);
}
</style>
