import { ProjectRuleShell } from '@/classes/project/ProjectRuleShell'

export class ProjectRuleModel {
  constructor (data = {}) {

    this.created_at = data.created_at
    this.id = data.id
    this.image = data.image
    this.map_image = data.map_image
    this.name = data.name
    this.shells = ProjectRuleShell.createList(data.shells)
    this.updated_at = data.updated_at
  }

  get _hasAnyErrors () {
    return this.shells.some(item => item._hasAnyErrors)
  }

  validate () {
    this.shells.forEach(shell => shell.validate())
  }

  get _payload () {
    return {
      shells: this.shells.map(shell => shell._payload)
    }
  }

  static setProjectSingleRuleDataType ({ ruleModel, ruleParams }) {
    for (const shell of ruleModel.shells) {
      for (const ruleSet of shell.rule_sets) {
        for (const rule of ruleSet.rules) {
          if ( rule.parameter_name && ruleParams ) {
            const currentRuleParam = ruleParams.find(item => item.name === rule.parameter_name)
            if (currentRuleParam) {
              rule.setDataType = currentRuleParam._realStorageType
            }
          }
        }
      }
    }
  }


}