<template>
  <app-dialog
      v-model="isDialogVisible"
      :title-prop="'Add Background'"
      max-width="800"
      v-bind="$attrs"
      v-on="$listeners"
  >

      <v-row>
        <v-col
            cols="12"
            sm="12"
        >
          <v-text-field
              placeholder="Search"
              class="custom-text-field-with-prepend-icon"
              v-model="searchInput"
          >
            <template #prepend-inner>
              <app-svg
                  src="~/assets/img/icons/search.svg"
                  size="16"
              />
            </template>
          </v-text-field>
        </v-col>
      </v-row>

    <app-loader-listing
        v-if="loadingGetBackgrounds"
    />

    <div v-else
        class="backgrounds-wrapper d-flex"
        style="max-width: 800px"
    >
      <div v-for="background in organisationBackgrounds"  :key=background.id>

          <v-card
              class="mx-auto"
              max-width="150px"
              :color="'var(--v-grey-lighten3)'"
              hover
              outlined
              @click="backgroundClick(background)"
              :class="background === selectedBackground ? 'bordered' : ''"
          >
            <v-img
                class="white--text align-end"
                height="100px"
                width="150px"
                :src="background.image && background.image.url"
            >
            </v-img>

            <v-card-text class="text--primary" style="background-color: #F9F9F9">
              <div> {{ background.name }} </div>
            </v-card-text>
          </v-card>

        </div>

    </div>

    <template #actions>
      <v-spacer class="d-none d-sm-flex" />
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
              id="close-dialog-button"
              block
              color="primary"
              outlined
              raised
              rounded
              x-large
              @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
              id="submit-button"
              block
              color="primary"
              raised
              rounded
              x-large
              :disabled="!selectedBackground || loadingAttachBackground"
              @click="attachBackgroundToProject"
              :loading="loadingAttachBackground"
          >
            <span class="text-capitalize">
              Add
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>

  </app-dialog>
</template>


<script>
import ProjectService from '@/services/project.service'
import AppDialog from '@/components/app/AppDialog'
import AppLoaderListing from '@/components/app/AppLoaderListing.vue'
import AppSvg from '@/components/app/AppSvg.vue'


import { cloneDeep } from 'lodash'

export default {
  name: 'ProjectTabBackgroundDialogAddBackground',
  components: {
    AppSvg,
    AppLoaderListing,
    AppDialog
  },

  data () {
    return {
      organisationBackgrounds: null,
      originalOrganisationBackgrounds: null,
      loadingGetBackgrounds: false,
      loadingAttachBackground: false,
      selectedBackground: null,
      searchInput: null
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

  },

  watch: {
    searchInput () {
      this.makeSearch()
    }
  },

  methods: {
    makeSearch () {
      if (this.searchInput && this.searchInput.length >= 3) {
        this.organisationBackgrounds = this.$search(
            {
              items: this.organisationBackgrounds,
              searchKey: this.searchInput
            }
        )
      } else {
        this.organisationBackgrounds = cloneDeep(this.originalOrganisationBackgrounds)
      }
    },

    async fetchOrganizationsBackgrounds () {
      try {
        this.loadingGetBackgrounds = true
        this.organisationBackgrounds = await ProjectService.getOrganizationBackgroundModels()
      }  finally {
        this.loadingGetBackgrounds = false
      }
    },

    backgroundClick (background) {
      this.selectedBackground = background
    },

    hideDialog () {
      this.isDialogVisible = false
    },

    async attachBackgroundToProject () {
      try {
        this.loadingAttachBackground = true
        await ProjectService.attachBackgroundModel({
          id: this.selectedBackground.id,
          project_id: this.$route.params.id
        })
      }
      finally {
        this.loadingAttachBackground = false
        this.$emit('fetchProject')
        this.hideDialog()
      }

    },
  },

    async created () {
      await this.fetchOrganizationsBackgrounds()
      this.originalOrganisationBackgrounds = cloneDeep(this.organisationBackgrounds)
    }
}
</script>



<style scoped lang="scss">
  .backgrounds-wrapper {
    background-color: var(--v-grey-lighten3);
    border: transparent;
    border-radius: 6px;

    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;

    .bordered {
      border: 2px solid var(--v-primary-base) !important;
    }
  }
</style>