import { BaseClass } from "@/classes/base/BaseClass"

export class ModuleClass extends BaseClass  {
    constructor (data = {}) {
        super()
        this.created_at = data.created_at
        this.depth = data.depth
        this.height = data.height
        this.id = data.id
        this.image = data.image
        this.name = data.name
        this.updated_at = data.updated_at
        this.width = data.width
        this.tags = data.tags
        this._selected = false
    }


    makeItemSelectedStoreAction () {
        return 'module/makeModuleSelectedUnselected'
    }
}