<template>
  <v-app>
    <div class="side-layout">
      <div class="background-section d-none" />

      <div class="content-section">
        <div class="content-inner-wrapper">
          <v-img
            id="app-logo-image"
            :aspect-ratio="3.25"
            :src="getLoginImageURL()"
            class="mx-auto"
            contain
            max-height="60"
            max-width="260"
            v-bind="$attrs"
            v-on="$listeners"
          />

          <transition :key="$router.currentRoute.path" mode="out-in" name="scale">
            <router-view></router-view>
          </transition>

        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "TheAuthLayoutWrap",

  methods: {
    getLoginImageURL () {
      return require('@/assets/img/sandbox-logo.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.side-layout {
  display: flex;
  width: 100%;
  height: 100%;
  background: #fff;
}

@media only screen and (min-width: 1024px) {
  .background-section {
    display: flex !important;
  }
}

.background-section {
  width: 50%;
  height: 100%;
  flex-shrink: 0;
  background-image: url("../assets/img/bg-simple.png");
  background-color: var(--v-primary-darken2);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.content-section {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-inner-wrapper {
  width: 440px;
}


.scale-enter-active,
.scale-leave-active {
  transition: all 0.4s ease;
}


.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
