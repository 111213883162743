<template>
  <v-expansion-panels>
    <app-expansion-panel>

      <template #header>
        <v-row align="center" class="pl-4 py-2">
          <v-tooltip top :color="'rgba(255, 255, 255, 0)'" max-width="140px">
            <template v-slot:activator="{ on }">
              <v-img
                v-on="on"
                :alt="`${shell.id} image`"
                :src="ruleModel.map_image.url"
                class="image-border mr-sm-8 background-image-container shell-map-image"
                width="39px"
                height="37px"
                style="border-radius: 6px; flex: 0 0 auto;"
              >
                <v-overlay :absolute="true" color="rgba(0, 0, 0, 0)" class="ma-0 pa-0">
                  <v-img
                    alt="some-name"
                    :src="shell.image.url"
                    class="image-border background-image-container shell-image"
                    width="39px"
                    height="37px"
                    style="border-radius: 6px; flex: 0 0 auto;"
                  />
                </v-overlay>
              </v-img>
            </template>

            <v-card>
              <v-img
                :alt="`${shell.id} image`"
                :src="ruleModel.map_image.url"
                class="shell-map-image-tooltip"
                width="134px"
                height="134px"
              >
                <v-overlay :absolute="true" color="rgba(0, 0, 0, 0)" class="ma-0 pa-0">
                  <v-img
                    :alt="`${shell.id} image`"
                    :src="shell.image.url"
                    class="shell-image-tooltip"
                    width="134px"
                    height="134px"
                  />
                </v-overlay>
              </v-img>
            </v-card>
          </v-tooltip>

          <span class="shell-item-name">
            {{shell.id}}
          </span>
        </v-row>
      </template>

      <project-tab-rule-shell-rule-set :shell="shell"/>

    </app-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import AppExpansionPanel from '@/components/app/AppExpansionPanel'
import ProjectTabRuleShellRuleSet from '@/components/project/tab/rules/ProjectTabRuleShellRuleSet'

export default {
  name: "ProjectTabRuleShell",
  components: { AppExpansionPanel, ProjectTabRuleShellRuleSet  },
  props: {
    shell: {
      type: Object,
      required: true
    }
  },
  computed: {
    ruleModel () {
      return this.$store.state.project.ruleModel
    }
  }
}
</script>

<style scoped lang="scss">
  @media (max-width: 600px) {
    .shell-item-name {
      padding-left: 10px;
    }
  }
</style>