<template>
  <div>
    <app-dialog
      v-model="isDialogVisible"
      :persistent="true"
      :title-prop="'Edit Tags'"
      max-width="700"

      v-bind="$attrs"
      v-on="$listeners"
    >
      <div class="select-tag-container">
        <v-combobox
          v-model="selectedTags"
          :items="searchedTags"
          item-text="name"
          @keydown.enter="onEnterKey"
          @update:search-input="inputChange"
          @change="tagSelectFromMenu"
          :search-input.sync="searchedText"
          label="Please type a tag name and press enter or add button"
          ref="combobox"
          :key="rerender"
          class="no-arrow small-label mb-1 text-small"
          :open-on-clear="false"
          :menu-props="{ value: openMenu, 'nudge-top': -10 }"
          color="'var(--v-grey-base)'"
          chips
          multiple
          solo
          bottom
          hide-selected
          deletable-chips
          dense
          hide-details
          flat
          small-chips
          :loading="isLoading"
        >
          <template #append-outer>
            <v-btn
              small
              rounded
              @click="addTag"
              class="pa-0 ma-0"
              color="v-primary-darken1"
            >
              Add
            </v-btn>
          </template>

          <template #no-data>
            <span class="no-data-text" ></span>
          </template>
        </v-combobox>
      </div>

      <div class="pt-8 pl-3">
        <v-row class="align-center">
          <v-chip
            v-model="currentTags"
            class="tag-item ma-1"
            v-for="tag in currentTags"
            :key="tag.id"
            @click:close="removeTag(tag)"
            color="'var(--v-grey-base)'"
            close
            small
          >
            {{ tag.name }}
          </v-chip>
        </v-row>
      </div>

      <template #actions>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              id="close-dialog-button"
              :disabled="isLoading"
              block
              color="primary"
              outlined
              raised
              rounded
              x-large
              @click.prevent="hideDialog"
            >
              Cancel
            </v-btn>
          </v-col>

          <v-col cols="12" sm="6">
            <v-btn
              id="submit-button"
              :disabled="isLoading"
              :loading="isLoading"
              block
              color="primary"
              raised
              rounded
              x-large
              @click.native="updateTags"
            >
            <span class="text-capitalize">
              Confirm
            </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
  </div>

</template>

<script>
import AppDialog from '@/components/app/AppDialog'

import moduleService from '@/services/module.service'
import tagService from '@/services/tag.service'
import { mapGetters } from 'vuex'

import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash'

export default {
  name: 'ModuleDialogDelete',
  components: { AppDialog },

  data () {
    return {
      searchedTags: [],
      selectedTags: [],
      currentTags: [],
      loading: false,
      openMenu: false,
      searchedText: null,
      rerender: 0

    }
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  computed: {
    ...mapGetters(
      {
        module: 'module/getModule',
      }
    ),

    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    isLoading () {
      return this.loading
    }
  },

  watch: {
    searchedTags (value) {
      this.openMenu = value.length > 0 && this.searchedText.length > 0
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

    async updateTags () {
      this.loading = true
      const payload = {
        id: this.$route.params.id,
        tags:this.currentTags
      }

      try {
        await moduleService.updateModuleTags(payload)
        await this.$store.dispatch('module/updateModuleTags', this.currentTags)
      } catch (e) {
        console.warn(e)
        throw e
      } finally {
        this.loading = false
        this.hideDialog()
      }
    },

    removeTag (item) {
      this.currentTags.splice(this.currentTags.indexOf(item), 1)
    },

    duplicateTagName (tagName) {
      return this.currentTags.some(tag => tag.name.toLocaleLowerCase() === tagName.toLocaleLowerCase())
    },

    pushTagToSelectedTags (tagName) {
      if (!this.duplicateTagName(tagName) && tagName.length > 0) {
        const tag = { id: uuidv4(), name: tagName }
        this.currentTags.push(tag)
      }
    },

    onEnterKey (event) {
      const tagName = event.target.value.trim()
      this.$refs.combobox.internalSearch = ''
      this.$refs.combobox.$refs.input.focus()
      this.pushTagToSelectedTags(tagName)
    },

    addTag () {
      const tagName = this.$refs.combobox.$refs.input.value.trim()
      this.pushTagToSelectedTags(tagName)
      this.$refs.combobox.internalSearch = ''
      this.$refs.combobox.$refs.input.focus()
    },

    async inputChange (input) {
      try {
        if ( input && input.length >=2 ) {
          this.loading = true
          this.searchedTags = await tagService.searchTags(input)
          this.openMenu = true
        } else {
          this.openMenu = false
        }
      } finally {
        this.loading = false
      }
    },

    tagSelectFromMenu (selectedTagsFromMenu) {
      const tag = selectedTagsFromMenu[0]
      if (tag.name && !this.duplicateTagName(tag.name) ) {
        this.currentTags.push(tag)
      }
      this.selectedTags = []
    }
  },

  created () {
    this.currentTags = cloneDeep(this.module.tags)
  }
}
</script>

<style lang="scss" scoped>
.color-light-gray {
  color: var(--v-grey-lighten1);
}
.select-tag-container {
  border-bottom: 1px solid;
  border-bottom-color: var(--v-grey-base);
  .combobox-container .v-menu__content {
    bottom: 50px;
  }
}

</style>
