<template>
  <div>
    <app-subpage-header
      back-link="/catalogues"
      title="Catalogue"
    />

    <app-loader-content
      v-if="currentState === actionStateConstants.LOADING"
    />

    <catalog-single-overview
      v-else-if="currentState !== actionStateConstants.LOADING"
    />
  </div>
</template>

<script>
import AppSubpageHeader from '@/components/app/AppSubpageHeader'
import AppLoaderContent from '@/components/app/AppLoaderContent'
import CatalogSingleOverview from '@/components/catalog/CatalogSingleOverview'

import { mapGetters } from 'vuex'
import { actionStates } from '@/store/modules/catalog'

export default {
  name: "CatalogueSingleView",
  components: {
    AppLoaderContent,
    CatalogSingleOverview,
    AppSubpageHeader
  },

  props: {
    isLoadingProp: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        currentState: 'catalog/getCurrentState'
      }
    )
  },

  methods: {
    async getCatalogInfo () {
      try {
        await this.$store.dispatch('catalog/fetchCatalog', this.$route.params.id)
      } catch (error) {
        await this.$router.replace('/catalogues')
      }
    }
  },

  mounted () {
    this.getCatalogInfo()
  }
}
</script>

<style scoped>

</style>
