<template>
  <app-dialog
    persistent
    :value="isDialogVisible"
    title-prop="Edit Modules and Categories"
    v-bind="$attrs"
    v-on="$listeners"
    :custom-scroll="true"
  >
    <app-loader-listing
      v-if="currentState === actionStateConstants.LOADING"
    />

    <v-row v-if="currentState !== actionStateConstants.LOADING">
      <v-col v-if="hasAtLeastOneItem" md="4" sm="5">
        <drop
          key="uncategorized_drop_header"
          class="uncategorized-list"
          mode="cut"
          @drop="insert($event, uncategorizedList)"
        >


          <div class="sticky-header-wrapper">
            <div class="header-sort-filter-search-wrapper" style="height: 40px; padding-left: 10px">
              <app-data-sort-by
                  :sort-options-prop="sortOptions"
                  @changeSort="makeSort"
              />

              <app-data-search-bar class="ml-2" @makeSearch="makeSearch" />
            </div>

            <v-divider class="mt-2" />
          </div>



          <header class="list-heading">
            <app-list-item
              :selected="areAllItemsSelected(uncategorizedList)"
              @update:selected="onBulkToggle($event, uncategorizedList)"
            >
              <template #title>

                All available modules ({{ uncategorizedList.length }})
              </template>
            </app-list-item>
          </header>

          <div class="list-body">
            <drop-list
              :items="uncategorizedList"
              mode="cut"
              no-animations
              @insert="insert($event, uncategorizedList)"
              @reorder="reorder($event, uncategorizedList)"
            >
              <template #item="{ item }">
                <drag
                  :key="'uncategorized_' + item.id"
                  :class="inlineCategorySelected.name === '' ? '' : 'dnd-no-drag'"
                  :data="item"
                  :delta="5"
                  @cut="cut(uncategorizedList, item)"
                  @dragend="onDragEnd(item)"
                  @dragstart="onDragStart(item)"
                >
                  <app-list-item
                    :selected="selectedItems.includes(item)"
                    hover
                    @update:selected="onItemToggle($event, item)"
                  >
                    <v-avatar class="image-border" rounded size="60">
                      <v-img
                        :alt="`${item.name} image`"
                        :src="item.image && item.image.url"
                      />
                    </v-avatar>

                    <template #title>
                      <span>{{ item.name }}</span>
                    </template>
                  </app-list-item>

                  <template #drag-image>
                    <app-dnd-drag-card :count="selectedItems.length" />
                  </template>
                </drag>
              </template>

              <template #feedback>
                <app-list-item
                  v-for="(selected, i) in selectedItems"
                  :key="selected.id + i"
                  min-height="90"
                >
                  <v-skeleton-loader boilerplate type="paragraph" />
                </app-list-item>
              </template>

              <template #empty>
                <app-list-item key="uncategorized_empty" class="text--secondary">
                  No uncategorized modules available.
                </app-list-item>
              </template>
            </drop-list>
          </div>
        </drop>
      </v-col>

      <v-col>
        <category-form-create @addNewCategory="addNewCategory" />

        <div v-if="!categorizedList.length" class="text--secondary text-center">
          Use the form above to add your first category.
        </div>

        <v-expansion-panels
          accordion
          flat
          focusable
          hover
          multiple
          tile
          style="max-height: 500px; overflow-y: auto"
        >
          <drop-list
            :items="categorizedList"
            mode="cut"
            no-animations
            row
            style="width: 100%;"
            @reorder="reorder($event, categorizedList)"
          >
            <template #item="{ item: oneCategory}">
              <drag
                :key="'categorizedList-' + oneCategory.id"
                :class="inlineCategorySelected.name === '' ? '' : 'dnd-no-drag'"
                :data="oneCategory"
                :delta="5"
                class="category-panels-drop-wrapper"
                @cut="cut(uncategorizedList, oneCategory)"
                @dragend="onDragEnd(oneCategory)"
                @dragstart="onDragStart(oneCategory)"
              >
                <app-expansion-panel
                  :disabled="isInlineEditActive(oneCategory) || isInlineDeleteActive(oneCategory)"
                  :selected="areAllItemsSelected(oneCategory.modules)"
                  class="category-expansion-panel"
                  fit
                >
                  <template #header>
                    <app-list-item
                      v-if="!isInlineEditActive(oneCategory) && !isInlineDeleteActive(oneCategory)"
                      :disabled="!oneCategory.modules.length"
                      :selected="areAllItemsSelected(oneCategory.modules)"
                      class="py-0 px-5"
                      style="height: 64px;"
                      @update:selected="onBulkToggle($event, oneCategory.modules)"
                    >
                        <span class="category-name">
                          {{ oneCategory.name }} ({{ oneCategory.modules.length }})
                        </span>

                      <div
                        v-if="areCategoryActionsVisible"
                        class="category-actions guttered-row dense inline"
                        @click.stop=""
                      >
                        <v-btn
                          class="category-inline-edit"
                          icon
                          small
                          @click.prevent="setInlineEdit(oneCategory)"
                        >
                          <app-svg
                            size="16"
                            src="~/assets/img/icons/edit.svg"
                          />
                        </v-btn>

                        <v-btn
                          class="category-inline-delete"
                          icon
                          small
                          :disabled="categorizedList.length === 1"
                          @click.prevent="setInlineDelete(oneCategory)"
                        >
                          <app-svg
                            size="16"
                            src="~/assets/img/icons/delete.svg"
                          />
                        </v-btn>
                      </div>
                    </app-list-item>

                    <category-form-inline-edit
                      v-if="isInlineEditActive(oneCategory)"
                      :inline-category-selected-name-prop="inlineCategorySelected.name"
                      @cancelInlineEdit="cancelInlineEdit"
                      @editCategoryName="editCategoryName(oneCategory, ...arguments)"
                    />

                    <category-form-inline-delete
                      v-if="isInlineDeleteActive(oneCategory)"
                      @cancelInlineEdit="cancelInlineEdit"
                      @removeCategory="removeCategory(oneCategory)"
                    />
                  </template>

                  <drop
                    mode="cut"
                    @drop="insert($event, oneCategory.modules)"
                  >
                    <drop-list
                      :items="oneCategory.modules"
                      class="category-list-items"
                      mode="cut"
                      no-animations
                      @insert="insert($event, oneCategory.modules)"
                      @reorder="reorder($event, oneCategory.modules)"
                    >
                      <template #item="{ item }">
                        <drag
                          :key="oneCategory.id + '_' + item.id"
                          :data="item"
                          :delta="5"
                          @cut="cut(oneCategory.modules, item)"
                          @dragend="onDragEnd(item)"
                          @dragstart="onDragStart(item)"
                        >
                          <app-list-item
                            :selected="selectedItems.includes(item)"
                            hover
                            @update:selected="onItemToggle($event, item)"
                          >
                            <v-avatar class="image-border" rounded size="60">
                              <v-img
                                :alt="`${item.name} image`"
                                :src="item.image && item.image.url"
                              />
                            </v-avatar>

                            <template #title>
                              <span>{{ item.name }} </span>
                            </template>
                          </app-list-item>

                          <template #drag-image>
                            <app-dnd-drag-card :count="selectedItems.length" />
                          </template>
                        </drag>
                      </template>

                      <template #feedback>
                        <app-list-item
                          v-for="selected in selectedItems"
                          :key="selected.id"
                          min-height="90"
                        >
                          <v-skeleton-loader boilerplate type="paragraph" />
                        </app-list-item>
                      </template>

                      <template #empty>
                        <app-list-item key="category_empty" class="text--secondary">
                          This category is empty. Drag and Drop modules here.
                        </app-list-item>
                      </template>
                    </drop-list>
                  </drop>

                </app-expansion-panel>

              </drag>

            </template>

            <template #feedback>
              <app-list-item>
                <v-skeleton-loader boilerplate type="paragraph" />
              </app-list-item>
            </template>

          </drop-list>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <template #actions>
        <v-spacer />
        <v-btn
            :disabled="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
            color="primary"
            min-width="200"
            rounded
            x-large
            @click="resetDataForCloseClick"
        >

          Cancel
        </v-btn>

        <v-btn
            :disabled="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING || !isDataChanged"
            :loading="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
            color="primary"
            min-width="200"
            rounded
            x-large
            @click="saveCatalogueCategories"
        >
          Confirm
        </v-btn>
    </template>
  </app-dialog>
</template>

<script>
import { Drag, Drop, DropList } from 'vue-easy-dnd'
import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import AppSvg from '@/components/app/AppSvg'
import AppExpansionPanel from '@/components/app/AppExpansionPanel'
import AppListItem from '@/components/app/AppListItem'
import AppDndDragCard from '@/components/app/dnd/AppDndDragCard'
import AppDialog from '@/components/app/AppDialog'
import AppLoaderListing from '@/components/app/AppLoaderListing'
import AppDataSortBy from "@/components/app/data/AppDataSortBy.vue"
import AppDataSearchBar from "@/components/app/data/AppDataSearchBar.vue"

import CategoryFormCreate from '@/components/category/CategoryFormCreate'
import CategoryFormInlineEdit from '@/components/category/CategoryFormInlineEdit'
import CategoryFormInlineDelete from '@/components/category/CategoryFormInlineDelete'

import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'




export default {
  name: 'CatalogDialogModulesAndCategoriesEdit',
  components: {
    AppLoaderListing,
    CategoryFormInlineDelete,
    CategoryFormInlineEdit,
    CategoryFormCreate,
    AppDialog,
    AppDndDragCard,
    AppListItem,
    AppExpansionPanel,
    AppDataSortBy,
    AppSvg,
    Drag,
    AppDataSearchBar,
    DropList,
    Drop
  },

  props: {
    value: {
      default: true
    },
  },

  data () {
    return {
      actionStateConstants: actionStates,
      isLoading: false,
      isSaving: false,
      isDraggedTypeIsCategory: false,
      isSaveBtnDisabled: true,

      inlineCategorySelected: {
        id: '',
        name: '',
        deleteId: ''
      },

      originalCategorizedList: [],
      originalUncategorizedList: [],

      selectedItems: [],

      sortOptions: [
        { text: 'Name - [A-Z]', key: 'name', direction: 'acs', id: uuidv4() },
        { text: 'Name - [Z-A]', key: 'name', direction: 'desc', id: uuidv4() },

        { text: 'Date Created - [A-Z]', key: 'dateCreated', direction: 'acs', id: uuidv4() },
        { text: 'Date Created - [Z-A]', key: 'dateCreated', direction: 'desc', id: uuidv4() },

        { text: 'Last Exported - [A-Z]', key: 'lastExported', direction: 'acs',  id: uuidv4() },
        { text: 'Last Exported - [Z-A]', key: 'lastExported', direction: 'desc',  id: uuidv4() },
      ],

      sortStrategy: [
        { key: 'name', direction: 'acs', sortFunc: (items) => {
          return items.sort((a, b) => a.name.localeCompare(b.name))
          }
        },

        { key: 'name', direction: 'desc', sortFunc: (items) => {
            return items.sort((a, b) => b.name.localeCompare(a.name))
          }
        },

        { key: 'dateCreated', direction: 'acs', sortFunc: (items) => {
            return items.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
          }
        },

        { key: 'dateCreated', direction: 'desc', sortFunc: (items) => {
            return items.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          }
        },

        { key: 'lastExported', direction: 'acs', sortFunc: (items) => {
            return items.sort((a, b) => new Date(a.exported_at) - new Date(b.exported_at))
          }
        },

        { key: 'lastExported', direction: 'desc', sortFunc: (items) => {
            return items.sort((a, b) => new Date(b.exported_at) - new Date(a.exported_at))
          }
        }
      ]
    }
  },

  computed: {
    ...mapGetters(
      {
        currentState: 'category/getCurrentState',
        categorizedList: 'category/getCategorizedList',
        uncategorizedList: 'category/getUncategorizedList'
      }
    ),

    isDialogVisible: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', !!value)
      }
    },

    hasAtLeastOneItem () {
      if (this.uncategorizedList.length > 0) {
        return true
      }
      return this.categorizedList && this.categorizedList.some(category => category.modules.length > 0)
    },

    areCategoryActionsVisible () {
      return !this.inlineCategorySelected.id.length > 0
    },

    isDataChanged () {
      const isCategorizedChanged = JSON.stringify(this.categorizedList) !== JSON.stringify(this.originalCategorizedList)
      const isUncategorizedSorted = this.isSorted(this.uncategorizedList) // Check if uncategorizedList is sorted
      const isUncategorizedChanged = !isUncategorizedSorted && JSON.stringify(this.uncategorizedList) !== JSON.stringify(this.originalUncategorizedList)
      return isCategorizedChanged || isUncategorizedChanged
    }
  },

  methods: {
    resetDataForSaveClick () {
      this.cancelInlineEdit()
      this.selectedItems = []
    },

    getDialogHeight () {
      const dialog = document.querySelector('.v-dialog')
      const dialogHeight =  window.getComputedStyle(dialog).getPropertyValue('height')
      const dialogHeightInt = parseInt(dialogHeight, 10)

      const unCatList = document.querySelector('.uncategorized-list')
      unCatList.style.maxHeight = `${dialogHeightInt - 190}px`
    },

    async makeSearch (searchInput) {
      if (searchInput && searchInput.length >= 3) {
        const foundItems =  this.$search({
          items: this.uncategorizedList,
          searchKey: searchInput
        })
        await this.$store.dispatch('category/updateUncategorizedList', foundItems)
      } else {
        await this.$store.dispatch('category/updateUncategorizedList', this.originalUncategorizedList)
      }
    },

    async resetDataForCloseClick () {
      this.cancelInlineEdit()
      this.selectedItems = []
      this.isDialogVisible = false
      await this.$store.dispatch('category/updateCategorizedList', this.originalCategorizedList)
      await this.$store.dispatch('category/updateUncategorizedList', this.originalUncategorizedList)

    },

    presetCatalogCategories () {
      this.originalCategorizedList = cloneDeep(this.categorizedList)
      this.originalUncategorizedList = cloneDeep(this.uncategorizedList)
    },

    async saveCatalogueCategories () {
      await this.$store.dispatch('category/updateCategories', {
        catalogue_id: this.$route.params.id,
        categories: this.categorizedList
      })
      this.isDialogVisible = false
      this.resetDataForSaveClick()
    },

   async addNewCategory (newCategoryName) {
      const category = {
        id: uuidv4(),
        name: newCategoryName.trim(),
        catalogue_id: this.$route.params.id,
        modules: []
      }

      await this.$store.dispatch('category/addNewCategory', category)
    },

    // Inline edit
    cancelInlineEdit () {
      this.inlineCategorySelected.id = ''
      this.inlineCategorySelected.name = ''
      this.inlineCategorySelected.deleteId = ''
    },

    setInlineEdit (category) {
      this.inlineCategorySelected.id = category.id
      this.inlineCategorySelected.name = category.name
    },

    editCategoryName (category, newName) {
      category.name = newName
      this.cancelInlineEdit()
    },

    isInlineEditActive (category) {
      return this.inlineCategorySelected.id === category?.id
    },

    // Inline delete
    setInlineDelete (category) {
      this.inlineCategorySelected.deleteId = category?.id
    },

    isInlineDeleteActive (category) {
      return this.inlineCategorySelected.deleteId === category?.id
    },

    removeCategory (selectedCategory) {
      this.$store.dispatch('category/deleteCategory', selectedCategory)
      this.deselectAllItems()
    },

    // Selections
    areAllItemsSelected (listItems) {
      if (!listItems.length) {
        return false
      }

      return listItems.every(item => this.selectedItems.includes(item))
    },

    selectItem (item) {
      if (!this.selectedItems.includes(item)) {
        this.selectedItems.push(item)
      }
    },

    bulkSelectItems (items) {
      items = items || []

      for (const item of items) {
        this.selectItem(item)
      }
    },

    deselectItem (item) {
      this.selectedItems = this.selectedItems.filter((oneSelectedItem) => oneSelectedItem.id !== item.id)
    },

    onItemToggle (state, item) {
      if (state) {
        this.selectItem(item)
        return
      }

      this.deselectItem(item)
    },

    bulkDeselectItems (items) {
      items = items || []

      for (let item of items) {
        this.deselectItem(item)
      }
    },

    deselectAllItems () {
      this.selectedItems = []
    },

    onBulkToggle (state, items) {
      if (state) {
        this.bulkSelectItems(items)
        return
      }

      this.bulkDeselectItems(items)
    },

    // Drag and drop functions
    reorder (event, list) {
      event.apply(list)
    },

    removeElementsFromUncategorized () {
      for (let i = this.uncategorizedList.length - 1; i >= 0; i--) {
        if (this.selectedItems.includes(this.uncategorizedList[i])) {
          this.uncategorizedList.splice(i, 1)
        }
      }
    },

    removeElementsFromCategorised () {
      for (const category of this.categorizedList) {
        for (let i = category.modules.length - 1; i >= 0; i--) {
          if (this.selectedItems.includes(category.modules[i])) {
            category.modules.splice(i, 1)
          }
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    cut (list, item) {
    },

    insert (event, list) {
      // if we're dragging category do not allow insert
      if (this.isDraggedTypeIsCategory) {
        return
      }

      this.removeElementsFromUncategorized()
      this.removeElementsFromCategorised()

      list.splice(event.index, 0, ...this.selectedItems)

      this.$nextTick(() => {
        this.deselectAllItems()
      })
    },

    onDragStart (item) {
      // if we're dragging category set flag to true
      if (item.catalogue_id) {
        this.isDraggedTypeIsCategory = true
        this.deselectAllItems()
      }

      //disable drag and drop if we make inline edit to category
      if (this.inlineCategorySelected.id) {
        return
      }

      this.selectItem(item)
      this.$nextTick(() => {
        const dragCardContent = document.querySelector('.dnd-ghost .drag-card-content')

        if (dragCardContent) {
          const total = this.selectedItems.length << 0
          dragCardContent.innerHTML = `Moving ${ total } ${ total === 1 ? 'item' : 'items' }`
        }
      })
    },

    onDragEnd (item) {
      // deselect the item if no other items are selected
      if (this.selectedItems.length <= 1) {
        this.deselectItem(item)
      }

      // NB! workaround for bug in easy-dnd
      // (not properly detecting the mouse up event after drag end)
      this.$el.click()
      this.isSaveBtnDisabled = false
      this.isDraggedTypeIsCategory = false
    },

    isSorted (arr) {
      for (let i = 1; i < arr.length; i++) {
        if (arr[i - 1] > arr[i]) {
          return false
        }
      }
      return true
    },

    makeSort (sortParam) {
      const currentSortStrategy = this.sortStrategy.find(item => item.key === sortParam.key && item.direction === sortParam.direction)
      if (currentSortStrategy) {
        currentSortStrategy.sortFunc(this.uncategorizedList)
      }
    }
  },

  created () {
    this.presetCatalogCategories()
  },

  mounted () {
    this.getDialogHeight ()
    window.addEventListener("resize", this.getDialogHeight)

  },

  destroyed () {
    window.removeEventListener("resize", this.getDialogHeight)
  }
}
</script>

<style lang="scss">
@import 'node_modules/vuetify/src/styles/styles';

.category-panels-drop-wrapper {
  width: 100%;
}

.uncategorized-list {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 30px;
  border: 1px solid var(--v-grey-lighten2);
  border-radius: $border-radius-root;
  overflow-y: auto;

  .sticky-header-wrapper{
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 100;
  }

  .list-heading {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom: 1px solid var(--v-grey-lighten2);
    background: #c3c3c31a;

    .app-list-item {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .list-body {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;

    & > :last-child {
      border-radius: inherit;
    }
  }
}

//category-list-items
.category-expansion-panel {
  .v-expansion-panel-header {
    //min-height: 64px;
    max-height: 64px;
    padding-left: 0;
  }

  .category-actions {
    opacity: 0;
    flex-wrap: unset;
    flex-shrink: 0;
    margin-top: -5px;
    margin-bottom: -5px;
    visibility: hidden;
    transition: opacity $secondary-transition,
    visibility $secondary-transition;
  }

  .category-form {
    display: flex;
    align-items: center;
    color: var(--v-grey-darken2);
    width: 100%;
    margin-top: -4px;
    margin-bottom: -4px;
    padding-left: 20px;

    & > * {
      margin-right: 30px;

      &:last-child {
        margin-right: 20px;
      }
    }

    .v-input {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    .form-actions {
      margin-left: auto
    }
  }

  .v-expansion-panel-header:hover .category-actions {
    opacity: 1;
    visibility: visible;
  }
}

.dnd-drop.drop-in .v-expansion-panel-header:hover .category-actions {
  opacity: 0;
  visibility: hidden;
}
</style>
