<template>
  <app-dialog
    v-model="isDialogVisible"
    :persistent="isLoadingProp"
    :title-prop="'Change image'"
    max-width="800"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <div class="pl-4 pb-3">
        <span> Image </span>
      </div>

      <div
        :style="{'border': image ? '1px solid #C3C3C3' : 'none'}"
        class="pa-4"
        style="border-radius: 4px; opacity: 1;"
      >
        <v-img
          :src="currentImageUrl"
          contain
          max-height="300"
          max-width="350"
        >
          <div v-if="image" class="selector-container">
            <v-tooltip bottom class="text-center" color="primary">
              <template #activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    :color="hover ? 'primary' : 'white'"
                    fab
                    small
                    v-bind="attrs"
                    @click="image = null"
                    v-on="on"
                  >
                    <app-svg
                      :color="hover ? 'white' : 'var(--v-primary-base)'"
                      size="16"
                      src="~/assets/img/icons/delete.svg"
                    />
                  </v-btn>
                </v-hover>
              </template>

              <span>Delete</span>
            </v-tooltip>
          </div>
        </v-img>
      </div>

      <v-file-input
        v-model="image"
        :append-icon="'mdi-camera'"
        :clearable="false"
        :error-messages="imageErrors"
        :prepend-icon="''"
        accept=".png, .jpg, .jpeg"
      />
    </div>

    <template #actions>
      <v-spacer class="d-none d-sm-flex" />
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            id="close-dialog-button"
            :disabled="isLoadingProp"
            block
            color="primary"
            outlined
            raised
            rounded
            x-large
            @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            id="submit-button"
            :disabled="
              isLoadingProp ||
              !$v.image.fileSizeValidation ||
              isImageChanged === false ||
              isErrorOccurredWhileUploadingProp ||
              isSelectedImageHaveValidFormat === false
            "
            :loading="isLoadingProp"
            block
            color="primary"
            raised
            rounded
            x-large
            @click.native="submitChangeImage"
          >
            <span class="text-capitalize">
              Confirm
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'
import AppSvg from '@/components/app/AppSvg'

const fileSizeValidation = (imageFile) => {
  if (!imageFile) {
    return true
  }

  return (imageFile.size < 15728640) // file should be less than 15MB
}

export default {
  name: 'AppImageDialogEdit',
  components: { AppSvg, AppDialog },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    isLoadingProp: {
      type: Boolean,
      default: false,
      required: true
    },

    imageProp: {
      type: Object,
      default: null
    },

    isErrorOccurredWhileUploadingProp: {
      type: Boolean,
      default: false
    },

    validationErrorMessageProp: {
      type: String,
      default: ''
    }
  },

  validations: {
    image: {
      fileSizeValidation
    }
  },

  data () {
    return {
      image: null,
      isImageChanged: false,
      validFormats: [
        'jpeg',
        'jpg',
        'png'
      ],
      isSelectedImageHaveValidFormat: true
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    currentImageUrl () {
      if (this.imageProp && this.image?.size <= 0) {
        return this.imageProp.url
      }

      if (!this.image) {
        return ''
      }

      return URL.createObjectURL(this.image)
    },

    imageErrors () {
      const errors = []

      !this.isSelectedImageHaveValidFormat && errors.push('Please choose valid format: jpeg, jpg, png')
      this.isErrorOccurredWhileUploadingProp && errors.push(this.validationErrorMessageProp)

      if (!this.$v.image.$invalid) {
        return errors
      }

      !this.$v.image.fileSizeValidation && errors.push('Size should be less than 15MB')
      return errors
    }
  },

  watch: {
    image (newValue) {
      if (this.imageProp === null && newValue === null) {
        this.isImageChanged = false
        return
      }

      if (newValue?.name === 'Choose an image') { // check if image is in preview mode
        this.isImageChanged = false
        return
      }

      if (this.isErrorOccurredWhileUploadingProp) {
        this.$emit('update:isErrorOccurredWhileUploadingProp', false)
        this.$emit('update:validationErrorMessageProp', '')
      }

      if (newValue) {
        const fileExtension = this.image.name.substring(this.image.name.lastIndexOf('.') + 1, this.image.name.length)
        this.isSelectedImageHaveValidFormat = this.validFormats.some(validFormat => validFormat === fileExtension)
      }

      this.isImageChanged = true
    }
  },

  methods: {
    hideDialog () {
      this.image = null
      this.isDialogVisible = false
    },

    submitChangeImage () {
      this.$emit('changeImage', this.image)
    },

    async createFile () {
      let metadata = { type: 'image/jpeg' }
      let file = new File([], "Choose an image", metadata)

      return file
    }
  },

  async created () {
    if (this.imageProp) {
      this.image = await this.createFile()
    }
  }

}
</script>

<style scoped>
.selector-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
