var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","clipped-left":""}},[_c('v-img',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"id":"home-logo-button","aspect-ratio":3.25,"src":_vm.getLogoImage(),"contain":"","max-height":"35","max-width":"140"},on:{"click":function($event){$event.stopPropagation();return _vm.redirectToHomePage.apply(null, arguments)}}},'v-img',_vm.$attrs,false),_vm.$listeners)),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{staticClass:"text-center",attrs:{"color":"primary","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on))]}}])},[_c('span',[_vm._v("This functionality is currently unavailable.")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({class:hover ? 'background-primary-lighten3' : '',attrs:{"color":"primary","icon":"","outlined":"","rounded":"","fill":"","small":"","fab":""},on:{"click":_vm.refreshBtnClick}},'v-btn',attrs,false),on),[_c('app-svg',{attrs:{"size":"16","src":"~/assets/img/icons/icon-help.svg"}})],1)]}}],null,true)})]}}])},[_c('span',[_vm._v("Help Desk")])]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{staticClass:"text-center",attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(data){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"bar-account-button","color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"primary","size":"30"}},'v-avatar',data.attrs,false),data.on),[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.userInitials)+" ")])])],1)]}}],null,true)},[(_vm.user && _vm.user.name)?_c('span',[_vm._v(_vm._s(_vm.user.name))]):(!(_vm.user && _vm.user.name))?_c('span',[_vm._v(" User ")]):_vm._e()])]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"id":"menu-item-profile","link":"","to":"/profile"}},[_c('v-list-item-title',[_vm._v("Edit Profile")])],1),_c('v-list-item',{attrs:{"id":"menu-item-sign-out","link":""},nativeOn:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-title',[_vm._v("Sign Out")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }