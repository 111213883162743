<template>
  <v-card
    :ripple="{ class: 'primary--text' }"
    :to="target"
    class="v-card--bordered"
    :class="entity._selected ? 'selected' : ''"
  >
    <v-img
      :src="entity.image ? entity.image.url : ''"
      height="150"
    />

    <v-divider />

    <v-card-subtitle>
      <div class="d-flex align-start justify-space-between text-subtitle-container">
        <div class="d-contents">
          <span
            v-if="!isTooltipVisible"
            class="text-h5 list-item-name"
          >
            {{ entity.name }}
          </span>

          <v-tooltip v-else-if="isTooltipVisible" bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                class="text-h5 list-item-name fade"
                v-bind="attrs"
                v-on="on"
              >
                {{ entity.name }}
              </span>
            </template>
            <span> {{ entity.name }}</span>
          </v-tooltip>

          <slot name="under-the-name" />
        </div>

        <v-checkbox
          v-if="selectable"
          :input-value="entity._selected"
          id="checkbox-entity"
          ref="checkbox-entity-ref"
          class="mt-0 pt-0 select-checkbox"
          color="primary"
          dense
          @click.prevent="checkBoxClick"
        />
      </div>
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: 'ListItemCommon',

  props: {
    entity: {
      type: Object,
      required: true
    },

    selectable: {
      type: Boolean,
      required: false,
      default: false
    },

    target: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      isTooltipVisible: false,
    }
  },

  methods: {
    async  checkBoxClick () {
      if (this.entity.makeItemSelectedStoreAction) {
        const storeAction = this.entity.makeItemSelectedStoreAction()
        await this.$store.dispatch(storeAction, {
          item: this.entity,
          selected: !this.entity._selected
        })
      }

    },

    getElementWidth (selector) {
      let selectedElement = this.$el.querySelector(`${ selector }`)
      let elementWidth = window.getComputedStyle(selectedElement).width

      return Number(elementWidth.split('px')[0])
    },

    checkIfTooltipIsVisible () {
      let listItemNameWidth = this.getElementWidth('.list-item-name')
      let containerWidth = this.getElementWidth('.text-subtitle-container')

      //+42px checkbox
      //+10px to show tooltip when there is fade effect
      return (listItemNameWidth + 42 + 10) >= containerWidth
    },

    onResize () {
      this.isTooltipVisible = this.checkIfTooltipIsVisible()
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.isTooltipVisible = this.checkIfTooltipIsVisible()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.v-card {
  .d-contents {
    display: inline-grid;
  }

  &__subtitle {
    padding-right: 10px;
  }

  .select-checkbox {
    opacity: 0;
    visibility: hidden;
    transform: translateX(5px);
    transition: transform $secondary-transition,
    opacity $secondary-transition,
    visibility $secondary-transition;
  }

  .list-item-name {
    color: black;
    width: unset !important;
    overflow: hidden;
    white-space: nowrap;
  }

  .fade {
    mask-image: linear-gradient(90deg, #000000 85%, transparent);
    -webkit-mask-image: linear-gradient(90deg, #000000 85%, transparent);
  }

  &:hover,
  &.selected {
    .select-checkbox {
      opacity: 1;
      visibility: visible;
      transform: translateX(0px);
    }

    .list-item-name {
      color: var(--v-primary-base)
    }
  }
}
</style>
