import $axios from '../plugins/axios'

class OrganizationService {

  getOrganization () {
    return $axios.get(`/get-organization`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

}

export default new OrganizationService()