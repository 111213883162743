<template>
  <div class="background-models-container">
      <div class="pa-5">
        <div class="app-list-item hover">
          <div class="d-flex align-center">
            <v-img
              :alt="`${gridModelProp.name} picture`"
              :src="gridModelProp.image && gridModelProp.image.url"
              class="image-border mr-sm-8 background-image-container"
              height="140px"
              style="border-radius: 6px; flex: 0 0 auto;"
              :contain="true"
              width="200px"
            />
<!--            :src="gridModelProp.image && gridModelProp.image.url"-->
            <div class="content-section content-title">
              <div class="d-flex flex-column text-subtitle-1 grey--text text--darken-2">
                 <span v-if="gridModelProp.name" class="list-item-name export-name-text">
                    <b> {{ gridModelProp.name }} </b>
                  </span>

                <div v-if="gridModelProp.created_at" class="list-item-name">
                  <span> <b>Last Exported </b> </span> <br>
                  <span class="exported-on-text"> {{ gridModelProp.created_at }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <v-divider />
  </div>
</template>

<script>
export default {
  name: "ProjectTabGrid",

  props: {
    gridModelProp: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>

@media (max-width: 600px) {
  .d-flex {
    flex-direction: column;
  }
}

</style>
