<template>
  <v-navigation-drawer
    :clipped="true"
    :expand-on-hover="navMini"
    :mini-variant="navMini"
    app
    fixed
    mini-variant-width="80"
    permanent
    width="200"
  >
    <v-list nav>
      <v-list-item
        v-for="(item, i) in itemsProp"
        :id="'menu-link-' + i"
        :key="i"
        :class="item.class"
        :to="item.to"
        class="nav-item"
        exact
        link
        router
      >
        <v-list-item-icon />
        <v-list-item-content>
          <v-list-item-title class="white--text">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

</template>

<script>
export default {
  name: "TheDefaultLayoutWrapMenuList",
  props: {
    itemsProp: {
      type: Array,
      required: true
    }
  },

  computed: {
    navMini () {
      return this.$vuetify.breakpoint.mdAndDown
    }
  }
}

</script>

<style lang="scss" scoped>

.v-list--nav {
  padding: 40px 10px;
}

.v-list--nav .nav-item {
  align-items: center;
  padding: 0;
  margin: 0 0 20px !important;
  border-radius: 30px;
  min-height: 60px;
  transition: background-color $secondary-transition;

  &:last-child {
    margin-bottom: 0 !important;
  }

  &:before {
    z-index: -1;
    border-radius: inherit;
    width: 60px;
    opacity: 0 !important;
    background: var(--v-primary-darken1);
    pointer-events: none;
    user-select: none;
  }

  .v-list-item__icon {
    position: relative;
    flex-shrink: 0;
    align-self: center;
    margin: 10px 15px !important;
    padding: 0;
    width: 31px;
    height: 31px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: 30px;
      background-repeat: no-repeat;
      background-position: top left;
      transition: opacity $secondary-transition;
    }

    &:before {
      z-index: 0;
      opacity: 1;
    }

    &:after {
      z-index: 1;
      opacity: 0;
      background-size: 31px;
    }
  }

  // states
  &:hover,
  &:active,
  &.v-list-item--active {
    &:before {
      opacity: 1 !important;
      width: 100%;
    }

    .v-list-item__icon:before {
      opacity: 0;
    }

    .v-list-item__icon:after {
      opacity: 1;
    }
  }

  // icon variations
  $icons: ('projects', 'catalogs', 'modules', 'clients', 'settings');
  @each $icon in $icons {
    &.nav-item-#{$icon} {
      .v-list-item__icon:before {
        background-image: url("@/assets/img/icons/#{$icon}-white.svg");
      }

      .v-list-item__icon:after {
        background-image: url("@/assets/img/icons/#{$icon}-color.svg");
      }
    }
  }
}

.v-navigation-drawer {
  background: var(--v-primary-darken1);
  background: -moz-linear-gradient(
      180deg,
      var(--v-primary-base) 0%,
      var(--v-primary-darken1) 100%
  );
  background: -webkit-linear-gradient(
      180deg,
      var(--v-primary-base) 0%,
      var(--v-primary-darken1) 100%
  );
  background: linear-gradient(
      180deg,
      var(--v-primary-base) 0%,
      var(--v-primary-darken1) 100%
  );

  // "overlay bg" as per design
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    mix-blend-mode: multiply;
    background: var(--v-grey-darken2);
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  }

  &.v-navigation-drawer--mini-variant .v-list-item {
    justify-content: flex-start;
  }
}

</style>
