<template>
  <v-dialog
    :max-width="maxWidth"
    :persistent="persistent"
    :value="value"
    overlay-color="#000"
    overlay-opacity="0.65"
    scrollable
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card height="auto">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title class="white--text text-h2">
          {{ titleProp }}
        </v-toolbar-title>

        <template v-if="!persistent">
          <v-spacer />
          <v-btn
            id="app-dialog-hide-button"
            icon
            @click.prevent="hide"
          >
            <app-svg
              color="white"
              size="16"
              src="@/assets/img/icons/close.svg"
            />
          </v-btn>
        </template>
      </v-toolbar>

      <div class="dialog-content" :class="!customScroll && 'scrollable'">
        <slot :hide="hide" />
      </div>

      <template v-if="hasActionsSlot">
        <v-divider />
        <v-card-actions class="pa-5">
          <slot :hide="hide" name="actions" />
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import AppSvg from '@/components/app/AppSvg'

export default {
  name: 'AppDialog',
  components: { AppSvg },

  props: {
    value: Boolean,
    persistent: Boolean,
    titleProp: {
      type: String,
      required: true
    },
    maxWidth: {
      type: [String, Number],
      default: 1140
    },

    customScroll: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },

  computed: {
    show: {
      get () {
        return !!this.value
      },
      set (value) {
        this.$emit('input', !!value)
      }
    },

    hasActionsSlot () {
      return this.$scopedSlots && this.$scopedSlots.actions
    }
  },

  watch: {
    value (newValue) {
      if (newValue) {
        this.$emit('show')
        return
      }

      this.$emit('hide')
    }
  },

  methods: {
    hide () {
      this.show = false
    }
  },
}
</script>

<style lang="scss" scoped>
.dialog-content {
  flex: 1 1 auto;
  overflow-y: hidden;
  height: 100%;
  padding: 35px 25px;
}

.scrollable {
  overflow-y: auto;
}

</style>
