<template>
  <div>
    <div class="content-wrapper pa-4">
      <v-layout class="flex-column flex-md-row">
        <module-edit-left-side-panel-container />

        <div class="flex-grow-1 ma-4">
          <v-tabs
            v-model="selectedTab"
            active-class="tab-active"
            class="mb-4"
            hide-slider
            show-arrows
          >

            <v-tab
                v-if="true"
                key="parameters"
                :disabled="!true"
                href="#parameters"
            >
              Parameters
            </v-tab>

<!--  Deyan Mihov had a request to remove these tabs from prod-->
<!--            <v-tab-->
<!--              v-if="true"-->
<!--              key="files"-->
<!--              :disabled="!true"-->
<!--              href="#files"-->
<!--            >-->
<!--              Files-->
<!--            </v-tab>-->

<!--            <v-tab-->
<!--              v-if="true"-->
<!--              key="catalogs"-->
<!--              :disabled="!true"-->
<!--              href="#catalogs"-->
<!--            >-->
<!--              Catalogues-->
<!--            </v-tab>-->

<!--            <v-tab-->
<!--              v-if="true"-->
<!--              key="urls"-->
<!--              :disabled="!true"-->
<!--              href="#urls"-->
<!--            >-->
<!--              Nested modules-->
<!--            </v-tab>-->

<!--            <v-tab-->
<!--              v-if="true"-->
<!--              key="bim"-->
<!--              :disabled="!true"-->
<!--              href="#bim"-->
<!--            >-->
<!--              BIM content-->
<!--            </v-tab>-->

<!--            <v-tab-->
<!--                v-if="true"-->
<!--                key="configurations"-->
<!--                href="#configurations"-->
<!--            >-->
<!--              Configurations-->
<!--            </v-tab>-->

          </v-tabs>

          <div class="tabs-content-section pa-5">
            <v-tabs-items v-model="selectedTab">
              <v-tab-item
                v-if="selectedTab === 'parameters'"
                key="parameters"
                value="parameters"
              >
                <module-tab-parameters
                  v-if="currentState === actionStateConstants.DATA_LOADED"
                  :parameters-prop="params"
                />
              </v-tab-item>


<!--  Deyan Mihov had a request to remove these tabs from prod-->
<!--              <v-tab-item-->
<!--                v-if="selectedTab === 'bim'"-->
<!--                key="bim"-->
<!--                value="bim"-->
<!--              >-->
<!--                <babylon-simple-module-->
<!--                  v-if="currentState === actionStateConstants.DATA_LOADED"-->
<!--                  :module-prop="module"-->
<!--                />-->
<!--              </v-tab-item>-->
<!--                <v-tabs-items v-model="selectedTab">-->
<!--                  <v-tab-item-->
<!--                    v-if="true"-->
<!--                    key="catalogs"-->
<!--                    value="catalogs"-->
<!--                  >-->
<!--                    <div>in progress</div>-->
<!--                  </v-tab-item>-->

<!--                  <v-tab-item-->
<!--                    v-if="true"-->
<!--                    key="files"-->
<!--                    value="files"-->
<!--                  >-->
<!--                    <div>in progress</div>-->
<!--                  </v-tab-item>-->

<!--                  <v-tab-item-->
<!--                    v-if="true"-->
<!--                    key="urls"-->
<!--                    value="urls"-->
<!--                  >-->
<!--                    <div>in progress</div>-->
<!--                  </v-tab-item>-->

<!--                  <v-tab-item-->
<!--                    v-if="true"-->
<!--                    key="levels"-->
<!--                    value="levels"-->
<!--                  >-->
<!--                    <div>in progress</div>-->
<!--                  </v-tab-item>-->

<!--                  <v-tab-item-->
<!--                    v-if="true"-->
<!--                    key="users"-->
<!--                    value="users"-->
<!--                  >-->
<!--                    <div>in progress</div>-->
<!--                  </v-tab-item>-->

<!--                  <v-tab-item-->
<!--                    v-if="true"-->
<!--                    key="tenants"-->
<!--                    value="tenants"-->
<!--                  >-->
<!--                    <div>in progress</div>-->
<!--                  </v-tab-item>-->

<!--                  <v-tab-item-->
<!--                    v-if="true"-->
<!--                    key="configurations"-->
<!--                    value="configurations"-->
<!--                    disabled-->
<!--                  >-->
<!--                    <div>in progress</div>-->
<!--                  </v-tab-item>-->
<!--                </v-tabs-items>-->
            </v-tabs-items>
          </div>
        </div>
      </v-layout>
    </div>
  </div>
</template>

<script>
import ModuleEditLeftSidePanelContainer from '@/components/module/edit/ModuleEditLeftSidePanelContainer'
// import BabylonSimpleModule from '@/components/babylon/BabylonSimpleModule'

import { mapGetters } from 'vuex'
import { actionStates } from '@/store/modules/module'
import ModuleTabParameters from '@/components/module/tab/ModuleTabParameters.vue'

export default {
  name: 'ModuleSingleOverview',
  components: {
    ModuleTabParameters,
    ModuleEditLeftSidePanelContainer,
  },

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    selectedTab: {
      get () {
        return this.$route.query.tab
      },
      async set (tab) {
        await this.$router.push({
          query: { ...this.$route.query, tab }
        })
      }
    },

    ...mapGetters(
      {
        module: 'module/getModule',
        currentState: 'module/getCurrentState',
        params: 'module/getParams'
      }
    )
  }

}
</script>

<style lang="scss" scoped>
.field-editable-text {
  color: var(--v-grey-base);
}
</style>
