<template>
  <div class="header-search-wrapper">
    <v-hover v-slot="{ hover }">
      <v-text-field
          v-model="searchInput"
          id="search-bar"
          placeholder="Search"
          height="30px"
          rounded
          :background-color="hover ? 'var(--v-primary-lighten2)' : ''"
          class="ma-0 pa-0"
          dense>
        <template #prepend-inner>
          <app-svg
              src="~/assets/img/icons/search.svg"
              size="16"
              color="primary"
          />
        </template>
      </v-text-field>
    </v-hover>
  </div>

</template>

<script>
import AppSvg from "@/components/app/AppSvg.vue"

export default {
  name: 'AppDataSearchBar',

  components: {
    AppSvg
  },

  data () {
    return {
      searchInput: '',
    }
  },
  watch: {
    searchInput (value) {
      this.$emit('makeSearch', value)
    }
  }
}
</script>

<style lang="scss">
.header-search-wrapper {
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .v-input--is-focused {
    background-color: #FFFFFF;
    padding: 0;
    margin: 0;
  }
  .v-input__slot {
    width: 200px;
    height: 30px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
  .v-input__control ::placeholder{
    font-size: 0.75rem;
  }
  .v-input {
    .v-input__control {
      .v-input__slot {
        .v-text-field__slot ::placeholder {
          color: var(--v-primary-base) !important;
          opacity: 1;
        }
        .v-text-field__slot {
          padding: 0;
          font-size: 0.75rem;
        }
      }
    }
  }
}
</style>
