import { render, staticRenderFns } from "./ProjectTabRuleShellRuleSet.vue?vue&type=template&id=294cda0c&"
import script from "./ProjectTabRuleShellRuleSet.vue?vue&type=script&lang=js&"
export * from "./ProjectTabRuleShellRuleSet.vue?vue&type=script&lang=js&"
import style0 from "./ProjectTabRuleShellRuleSet.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VHover } from 'vuetify/lib/components/VHover';
installComponents(component, {VAvatar,VExpansionPanels,VHover})
