<template>
  <app-dialog
      v-model="isDialogVisible"
      :title-prop="'Delete Configuration'"
      max-width="500"
      v-bind="$attrs"
      v-on="$listeners"
  >

    <div>
      {{ configurationProp.name }} will be permanently deleted. Are you sure you want to continue?
    </div>


    <template #actions>
<!--      <v-spacer class="d-none d-sm-flex" />-->
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
              id="close-dialog-button"
              block
              color="primary"
              outlined
              raised
              rounded
              x-large
              @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
              id="submit-button"
              block
              color="primary"
              raised
              rounded
              x-large
              :loading="loading"
              :disabled="loading"
              @click="hardDeleteConf"
          >
            <span class="text-capitalize">
              Confirm
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>

  </app-dialog>
</template>

<script>

import AppDialog from '@/components/app/AppDialog'
import ProjectService from '@/services/project.service'
export default {
  name: 'ProjectTabDeleteConfigurationDialog',

  components: {
    AppDialog
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    configurationProp: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

    async hardDeleteConf () {
      try {
        this.loading = true
        await ProjectService.hardDeleteConfiguration(this.configurationProp.id)
      } finally {
        await this.$store.dispatch('project/fetchProjectConfigurations', this.$route.params.id)
        this.loading = false
        this.hideDialog()
      }
    }
  }

}
</script>



<style scoped lang="scss">

</style>