<template>
  <div class="content-wrapper pa-4">
    <v-layout class="flex-column flex-md-row">
      <catalog-edit-left-side-panel-container />

      <catalog-edit-right-side-panel-container />
    </v-layout>
  </div>
</template>

<script>
import CatalogEditLeftSidePanelContainer from '@/components/catalog/edit/CatalogEditLeftSidePanelContainer'
import CatalogEditRightSidePanelContainer from '@/components/catalog/edit/CatalogEditRightSidePanelContainer'

export default {
  name: 'CatalogSingleOverview',
  components: {
    CatalogEditRightSidePanelContainer,
    CatalogEditLeftSidePanelContainer
  }

}
</script>

<style lang="scss" scoped>
.field-editable-text {
  color: var(--v-grey-base);
}
</style>
