<template>
  <app-dialog
    v-model="isDialogVisible"
    :persistent="isLoadingProp"
    :title-prop="titleProp"
    max-width="500"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <v-text-field
        id="name-field"
        v-model="name"
        :error-messages="nameErrors"
        :placeholder="placeholderProp"
        clearable
        label="Name"
        required
        @blur="$v.$touch()"
        @input="$v.name.$touch()"
      />
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            id="close-dialog-button"
            :disabled="isLoadingProp"
            block
            color="primary"
            outlined
            raised
            rounded
            x-large
            @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            id="submit-button"
            :disabled="isButtonDisabled"
            :loading="isLoadingProp"
            block
            color="primary"
            raised
            rounded
            x-large
            @click.native="submitForm"
          >
            <span class="text-capitalize">
              Edit
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
import AppDialog from '@/components/app/AppDialog'

export default {
  name: 'CatalogDialogEditName',
  components: { AppDialog },

  props: {
    titleProp: {
      type: String,
      required: true
    },

    placeholderProp: {
      type: String,
      default: 'Add catalog name...'
    },

    value: {
      type: Boolean,
      default: false,
      required: true
    },

    isLoadingProp: {
      type: Boolean,
      default: false,
      required: true
    },

    catalogNameProp: {
      type: String,
      default: ''
    },

    isNameAlreadyExistProp: {
      type: Boolean,
      default: false
    },

    validationErrorMessageProp: {
      type: String,
      default: ''
    }
  },

  validations: {
    name: {
      required,
      maxLength: maxLength(255)
    }
  },

  data () {
    return {
      name: this.catalogNameProp
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    isNameChangedOnEdit () {
      return this.name === this.catalogNameProp
    },

    nameErrors () {
      const errors = []

      this.isNameAlreadyExistProp && errors.push(this.validationErrorMessageProp)

      if (!this.$v.name.$dirty) {
        return errors
      }

      this.isNameChangedOnEdit && errors.push('Name should be different than the current name')
      !this.$v.name.maxLength && errors.push('Can contain maximum of 255 characters')
      !this.$v.name.required && errors.push('Must contain at least 1 character')
      return errors
    },

    isButtonDisabled () {
      return (this.isLoadingProp || this.$v.$anyError || this.isNameAlreadyExistProp || this.isNameChangedOnEdit)
    }
  },

  watch: {
    'name' () {
      if (this.isNameAlreadyExistProp) {
        this.resetIsNameAlreadyExist()
      }
    }
  },

  methods: {
    hideDialog () {
      this.resetCatalog()
      this.resetIsNameAlreadyExist()
      this.isDialogVisible = false
    },

    resetCatalog () {
      this.name = null
    },

    resetIsNameAlreadyExist () {
      this.$emit('update:isNameAlreadyExistProp', false)
      this.$emit('update:validationErrorMessageProp', '')
    },

    submitForm () {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      this.name = this.name.trim()
      this.$emit('changeCatalogName', this.name)
    }
  },

  beforeDestroy () {
    this.resetIsNameAlreadyExist()
  }
}
</script>

<style lang="scss" scoped>
.color-light-gray {
  color: var(--v-grey-lighten1);
}
</style>
