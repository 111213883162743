<template>
  <v-app dark>
    <the-default-layout-wrap-app-bar />

    <the-default-layout-wrap-menu-list
      v-if="menuItems && windowWidth > 540"
      :items-prop="menuItems"
    />

    <v-main>
      <transition mode="out-in" name="scale">
        <v-container :key="$router.currentRoute.path" class="pa-md-10" fluid>
          <router-view></router-view>
        </v-container>
      </transition>
    </v-main>

    <the-default-layout-wrap-mobile-footer-menu
      v-if="windowWidth <= 540"
      :items-prop="menuItems"
    />
  </v-app>
</template>

<script>
import TheDefaultLayoutWrapMenuList from './TheDefaultLayoutWrapMenuList.vue'
import TheDefaultLayoutWrapAppBar from '@/layouts/TheDefaultLayoutWrapAppBar'
import TheDefaultLayoutWrapMobileFooterMenu from '@/layouts/TheDefaultLayoutWrapMobileFooterMenu'

export default {
  name: 'TheDefaultLayoutWrap',
  components: {
    TheDefaultLayoutWrapAppBar,
    TheDefaultLayoutWrapMenuList,
    TheDefaultLayoutWrapMobileFooterMenu
  },

  data () {
    return {
      windowSize: {
        width: 0,
        height: 0
      },
      windowWidth: window.innerWidth,
      menuItems: [
        {
          title: 'Projects',
          to: '/dashboard',
          class: 'nav-item-projects'
        },
        {
          title: 'Catalogues',
          to: '/catalogues',
          class: 'nav-item-catalogs'
        },
        {
          title: 'Modules',
          to: '/modules',
          class: 'nav-item-modules'
        }
        // {
        //   title: 'Clients',
        //   to: '/clients',
        //   class: 'nav-item-clients'
        // }
      ]
    }
  },

  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }

}
</script>

<style lang="scss" scoped>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.4s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

@media only screen and (max-width: 1024px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 720px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

</style>
