import { BaseClass } from '@/classes/base/BaseClass'

export class ProjectClass extends BaseClass {
  constructor (data = {}) {
    super()
    this.id = data.id
    this.name = data.name
    this.description = data.description
    this.locations = data.locations
    this.image = data.image
    this.logo = data.logo
    this.has_background_models = data.has_background_models
    this.has_grid_model = data.has_grid_model
    this.has_rule_model = data.has_rule_model
    this.contact_person_id = data.contact_person_id
    this.created_at = data.created_at
    this.updated_at = data.updated_at
    this._selected = false

  }

  makeItemSelectedStoreAction () {
    return 'project/makeProjectSelectedUnselected'
  }

}