<template>
  <div>
    <app-loader-listing
        v-if="currentState === actionStateConstants.LOADING"
    />

    <v-expansion-panels
      v-else
      v-for="category in categorizedList"
      :key="category.id"
      accordion
      flat
      focusable
      hover
      tile
    >
      <app-expansion-panel fit>

        <template #header>
          <div class="category-name">
            {{ category.name }} ({{ category.modules.length }})
          </div>
        </template>

        <div
          class="module-overview-wrapper"
          v-for="module in category.modules"
          :key="module.id"
          @click="moduleClick(module)"
        >
          <div class="module-container d-flex align-center pl-4 py-2">

            <v-img
              :alt="`${module.name} picture`"
              :src="module.image && module.image.url"
              class="image-border mr-8 module-image"
              height="60px"
              style="border-radius: 6px; flex: 0 0 auto;"
              width="60px"
            >
            </v-img>
            <div>
              <div class="text-regular module-name">
                {{ module.name }}
              </div>

              <div class="d-flex text-small text--secondary mt-1">
                <div class="module-depth">
                  Depth: {{ module.depth }}
                </div>

                <div class="pl-3 module-height">
                  Height: {{ module.height }}
                </div>

                <div class="pl-3 module-width">
                  Width: {{ module.width }}
                </div>
              </div>

            </div>

          </div>

        </div>

      </app-expansion-panel>
    </v-expansion-panels>

  </div>
</template>

<script>
import AppExpansionPanel from '@/components/app/AppExpansionPanel'
import AppLoaderListing from "@/components/app/AppLoaderListing.vue"

import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'

export default {
  name: "CatalogCategoriesOverview",

  components: {
    AppLoaderListing,
    AppExpansionPanel
  },

  data () {
    return {
      actionStateConstants: actionStates,
    }
  },

  computed: {
    ...mapGetters(
      {
        currentState: 'category/getCurrentState',
        categorizedList: 'category/getCategorizedList'
      }
    ),
  },

  methods: {
    moduleClick (module) {
      this.$router.push(`/modules/${module.id}`)
    }
  }
}
</script>

<style scoped lang="scss">
.module-overview-wrapper {
  cursor: pointer;
  &:hover {
    background: var(--v-grey-lighten2);
  }
}
</style>