import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import $axios from './plugins/axios'
import VueCookies from 'vue-cookies'

import search from "@/plugins/search"

import 'animate.css'

Vue.config.productionTip = false

/** Vuelidate plugin settings */
Vue.use(Vuelidate)
Vue.use(search)

/** Axios plugin settings */
Vue.use({
  install (Vue) {
    Vue.prototype.$axios = $axios
  }
})


/** Vue cookies */
Vue.use(VueCookies)

new Vue({
  router: router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
