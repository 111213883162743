<template>
  <app-dialog
    v-model="isDialogVisible"
    :persistent="isLoadingProp"
    :title-prop="titleProp"
    max-width="500"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <v-text-field
        id="name-field"
        v-model="projectName"
        :error-messages="nameErrors"
        :placeholder="placeholderProp"
        clearable
        label="Name"
        required
        @blur="$v.$touch()"
        @input="$v.projectName.$touch()"
      />
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            id="close-dialog-button"
            :disabled="isLoadingProp"
            block
            outlined
            raised
            rounded
            x-large
            @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            id="submit-button"
            :disabled="isLoadingProp || $v.$anyError || isNameAlreadyExistProp || isNameChangedOnEdit"
            :loading="isLoadingProp"
            block
            color="primary"
            raised
            rounded
            x-large
            @click.native="createProject"
          >
            <span class="text-capitalize">
              {{ action }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
import AppDialog from '@/components/app/AppDialog'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'ProjectDialogNameCreateEdit',
  components: { AppDialog },

  props: {
    titleProp: {
      type: String,
      required: true
    },

    placeholderProp: {
      type: String,
      default: 'Add project name...'
    },

    action: {
      type: String,
      default: 'Create'
    },

    value: {
      type: Boolean,
      default: false,
      required: true
    },

    isLoadingProp: {
      type: Boolean,
      default: false,
      required: true
    },

    projectNameProp: {
      type: String,
      default: ''
    },

    isNameAlreadyExistProp: {
      type: Boolean,
      default: false
    },

    validationErrorMessageProp: {
      type: String,
      default: ''
    }
  },

  validations: {
    projectName: {
      required,
      maxLength: maxLength(255)
    }
  },

  data () {
    return {
      projectName: this.projectNameProp
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    isNameChangedOnEdit () {
      return this.action.toLowerCase() === 'edit' && this.projectName === this.projectNameProp
    },

    nameErrors () {
      const errors = []
      if (!this.$v.projectName.$dirty) {
        return errors
      }

      this.isNameAlreadyExistProp && errors.push(this.validationErrorMessageProp)
      this.isNameChangedOnEdit && errors.push('Name should be different than the current name')
      !this.$v.projectName.maxLength && errors.push('Can contain maximum of 255 characters')
      !this.$v.projectName.required && errors.push('Must contain at least 1 character')
      return errors
    }
  },

  watch: {
    projectName () {
      if (this.isNameAlreadyExistProp) {
        this.resetIsNameAlreadyExist()
      }
    }
  },

  methods: {
    hideDialog () {
      this.resetProjectName()
      this.resetIsNameAlreadyExist()
      this.isDialogVisible = false
    },

    resetProjectName () {
      this.projectName = null
    },

    resetIsNameAlreadyExist () {
      this.$emit('update:isNameAlreadyExistProp', false)
      this.$emit('update:validationErrorMessageProp', '')
    },

    createProject () {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      this.projectName = this.projectName.trim()

      if (this.action.toLowerCase() === 'create') {
        this.$emit('createProject', { id: uuidv4(), name: this.projectName })
        return
      }

      this.$emit('changeProjectName', this.projectName)
    }
  },

  beforeDestroy () {
    this.resetIsNameAlreadyExist()
  }
}
</script>

<style scoped>

</style>
