import CategoryService from "@/services/category.service"

import Vue from 'vue'

const actionStates = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  ERRORED: "ERRORED",
  NOT_FOUND: "NOT_FOUND",
  DATA_LOADED: "DATA_LOADED",
  DIALOG_SUBMIT_LOADING: "DIALOG_SUBMIT_LOADING",
  DIALOG_SUBMIT_ERROR: "DIALOG_SUBMIT_ERROR"
}

const state = {
  categorizedList: [],
  uncategorizedList: [],

  currentState: actionStates.INITIAL
}

const getters = {
  getCurrentState (state) {
    return state.currentState
  },

  getCategorizedList (state) {
    return state.categorizedList
  },

  getUncategorizedList (state) {
    return state.uncategorizedList
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setCategorizedList (state, categorizedList) {
    state.categorizedList = categorizedList
  },

  setUncategorizedList (state, uncategorizedList) {
    state.uncategorizedList = uncategorizedList
  },

  appendNewCategory (state, category) {
    const categoryIndex = state.categorizedList.length
    Vue.set(state.categorizedList, categoryIndex, category)
  },

  removeCategory (state, category) {
    const categoryIndex = state.categorizedList.indexOf(category)
    state.categorizedList.splice(categoryIndex, 1)
  },

  appendModulesToUncategorizedList (state, modules) {
    for (const module of modules) {
      const moduleIndex = state.uncategorizedList.length
      Vue.set(state.uncategorizedList, moduleIndex, module)
    }
  }
}

const actions = {
  async fetchCatalogueCategories (vuexContext, id) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      let response = await CategoryService.getCatalogueCategories(id)

      vuexContext.commit('setCategorizedList', response.categories)
      vuexContext.commit('setUncategorizedList', response.uncategorized)

      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
      return response
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },

  prepareDataFormatForUpdatingCategories (vuexContext, catalogList) {
    let dataForUpdatingCategories = {
      catalogue_id: catalogList.catalogue_id,
      categories: []
    }

    for (let category of catalogList.categories) {
      let oneCategory = {
        id: category.id,
        name: category.name,
        modules: []
      }

      for (let module of category.modules) {
        oneCategory.modules.push(module.id)
      }

      dataForUpdatingCategories.categories.push(oneCategory)
    }

    return dataForUpdatingCategories
  },

  async updateCategories (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      let dataForUpdatingCategories = await vuexContext.dispatch('prepareDataFormatForUpdatingCategories', data)
      await CategoryService.updateCatalogueCategories(dataForUpdatingCategories)
      vuexContext.commit('setActionState', actionStates.INITIAL)
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  addNewCategory (vuexContext, category) {
    vuexContext.commit('appendNewCategory', category)
  },

  deleteCategory (vuexContext, category) {
    vuexContext.commit('removeCategory', category)
    if (category.modules.length) {
      vuexContext.commit('appendModulesToUncategorizedList', category.modules)
    }
  },

  updateCategorizedList (vuexContext, categoryList) {
    vuexContext.commit('setCategorizedList', categoryList)
  },

  updateUncategorizedList (vuexContext, uncategorizedList) {
    vuexContext.commit('setUncategorizedList', uncategorizedList)
  }

}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
