<template>
  <app-dialog
    v-model="isDialogVisible"
    :persistent="isLoadingProp"
    :title-prop="titleProp"
    max-width="500"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <v-textarea
        id="description-field"
        v-model="projectDescription"
        :error-messages="descriptionErrors"
        :placeholder="placeholderProp"
        label="Description"
        outlined
        @blur="$v.$touch()"
        @input="$v.projectDescription.$touch()"
      />
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            id="close-dialog-button"
            :disabled="isLoadingProp"
            block
            color="primary"
            outlined
            raised
            rounded
            x-large
            @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            id="submit-button"
            :disabled="isLoadingProp || $v.$anyError"
            :loading="isLoadingProp"
            block
            color="primary"
            raised
            rounded
            x-large
            @click.native="createProject"
          >
            <span class="text-capitalize">
             Confirm
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import { maxLength } from 'vuelidate/lib/validators'
import AppDialog from '@/components/app/AppDialog'

export default {
  name: 'ProjectDialogDescriptionEdit',
  components: { AppDialog },

  props: {
    titleProp: {
      type: String,
      required: true
    },

    placeholderProp: {
      type: String,
      default: 'Add project description...'
    },

    value: {
      type: Boolean,
      default: false,
      required: true
    },

    isLoadingProp: {
      type: Boolean,
      default: false,
      required: true
    },

    projectDescriptionProp: {
      type: String,
      default: ''
    }
  },

  validations: {
    projectDescription: {
      maxLength: maxLength(1000)
    }
  },

  data () {
    return {
      projectDescription: this.projectDescriptionProp
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    descriptionErrors () {
      const errors = []
      if (!this.$v.projectDescription.$dirty) {
        return errors
      }

      !this.$v.projectDescription.maxLength && errors.push('Can contain maximum of 1000 characters')
      return errors
    }
  },

  methods: {
    hideDialog () {
      this.resetProjectDescription()
      this.isDialogVisible = false
    },

    resetProjectDescription () {
      this.projectDescription = null
    },

    createProject () {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      this.$emit('changeProjectDescription', this.projectDescription)
    }
  }
}
</script>

<style scoped>

</style>
