<template>
  <div>
    <the-default-layout-wrap>
      <router-view></router-view>
    </the-default-layout-wrap>
  </div>
</template>

<script>
import TheDefaultLayoutWrap from "./layouts/TheDefaultLayoutWrap.vue"

export default {
  name: 'AppLayoutContent',
  components: {
    TheDefaultLayoutWrap
  }

}
</script>
