<template>
  <div class="filter-wrapper flex-item">
    <v-hover v-slot="{ hover }">
      <v-select
        v-model="selected"
        :background-color="hover || isFocus ? 'primary lighten-2' : '' "
        :item-text="item => item.name"
        :items="listOfUsersProp"
        :menu-props="menuProps"
        append-icon="mdi-chevron-down"
        class="ma-0 pa-0"
        color="primary"
        dense
        height="30"
        item-value="id"
        multiple
        placeholder="Filter"
        rounded
        @blur="isFocus = false"
        @input="filterByUser"
        @mouseup="isFocus = true"
      >
        <template #prepend-inner>
          <app-svg
            class="ml-1"
            color="primary"
            size="16"
            src="~/assets/img/icons/filter.svg"
          />
        </template>

        <template v-slot:selection="{ item, index }">
          <span
            v-if="index === 0 && item"
            class="primary--text"
            style="padding: 2px 0 0 4px;"
          >
            Filter
          </span>
        </template>
      </v-select>
    </v-hover>
  </div>

</template>

<script>
import AppSvg from '@/components/app/AppSvg'

export default {
  name: 'AppDataFilter',
  components: { AppSvg },

  props: {
    listOfUsersProp: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      menuProps: {
        bottom: true,
        contentClass: 'sort-by-select-menu-bottom',
        maxWidth: 210
      },
      selected: [],
      isFocus: false
    }
  },

  methods: {
    filterByUser () {
      this.isFocus = false
      this.$emit('filterByUser', this.selected)
    }
  }
}
</script>

<style lang="scss">

.v-menu__content.theme--light.menuable__content__active.sort-by-select-menu-bottom {
  margin-top: 35px;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 10%), 0 2px 2px 0 rgb(0 0 0 / 10%), 0 1px 5px 0 rgb(0 0 0 / 10%);
}
</style>
