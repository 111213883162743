<template>
  <div>
    <h1 class="mb-10">
      Projects
    </h1>

    <app-select-sort-filter-search-header
        :items-prop="projects"
        :sort-options-prop="sortOptions"
        :is-loading-prop="isDeleteDialogLoading"
        @selectAllItems="selectAll"
        @makeSearch="makeSearch"
        @changeSort="makeSort"
        @deleteItems="deleteItems"
        ref="appSelectSortFilterSearch"
    />

    <v-divider class="mt-1 mb-8" />

    <list-overview
      :can-create=true
      :data-source.sync="projects"
      :is-list-loading-prop="currentState === actionStateConstants.LOADING"
      :selectable="true"
      list-type="project"
      target-url="/projects"
    >
      <template #create-card>
        <list-item-create-card
          action="Create"
          entity-type="project"
          @click="openCreateProjectDialog"
          :disabled-prop="isProjectLimitReached"
        />
      </template>

      <template #entity-card="{ entity, selectable, target }">
        <list-item-common
          :entity="entity"
          :selectable="selectable"
          :target="target"
        >
          <template #under-the-name>
            <v-img
              v-if="entity.logo"
              id="entity-logo"
              :src="entity.logo.url"
              contain
              max-height="20"
              max-width="80"
              position="left"
              style="cursor:pointer;"
              v-bind="$attrs"
              v-on="$listeners"
            />
          </template>
        </list-item-common>
      </template>
    </list-overview>

    <project-dialog-name-create-edit
      v-if="isDialogVisible"
      :key="createProjectDialogKey + '-project-dialog'"
      v-model="isDialogVisible"
      :is-loading-prop="isDialogLoading"
      :is-name-already-exist-prop.sync="isNameAlreadyExistOnCreate"
      :title-prop="'Create New Project'"
      :validation-error-message-prop.sync="validationErrorMessage"
      @createProject="createProject"
    />

    <project-dialog-limit-reached
      v-if="isProjectLimitDialogVisible"
      v-model="isProjectLimitDialogVisible"
      :key="keyProjectLimitDialog"
    />
  </div>

</template>

<script>

import ProjectDialogNameCreateEdit from '@/components/project/ProjectDialogNameCreateEdit'
import ListOverview from '@/components/list/ListOverview'
import ListItemCommon from '@/components/list/ListItemCommon'
import ProjectService from '@/services/project.service'
import ListItemCreateCard from '@/components/list/ListItemCreateCard'
import AppSelectSortFilterSearchHeader from '@/components/app/header/AppSelectSortFilterSearchHeader.vue'
import ProjectDialogLimitReached from '@/components/project/dialog/ProjectDialogLimitReached.vue'

import { mapGetters } from "vuex"
import { actionStates } from '@/constants'
import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'ProjectAllView',
  components: {
    AppSelectSortFilterSearchHeader,
    ProjectDialogNameCreateEdit,
    ListOverview,
    ListItemCreateCard,
    ListItemCommon,
    ProjectDialogLimitReached
  },

  data () {
    return {
      actionStateConstants: actionStates,
      isDialogVisible: false,
      createProjectDialogKey: 0,
      isDialogLoading: false,
      title: 'Projects',
      isNameAlreadyExistOnCreate: false,
      validationErrorMessage: '',
      isDeleteDialogLoading: false,
      originalProjects: [],

      isProjectLimitDialogVisible: false,
      keyProjectLimitDialog: uuidv4(),

      sortOptions: [
        { text: 'Name - [A-Z]', key: 'name', direction: 'acs', id: uuidv4() },
        { text: 'Name - [Z-A]', key: 'name', direction: 'desc', id: uuidv4() },

        { text: 'Date Created - [A-Z]', key: 'dateCreated', direction: 'acs', id: uuidv4() },
        { text: 'Date Created - [Z-A]', key: 'dateCreated', direction: 'desc', id: uuidv4() },

        { text: 'Date Updated - [A-Z]', key: 'updatedAt', direction: 'acs',  id: uuidv4() },
        { text: 'Date Updated - [Z-A]', key: 'updatedAt', direction: 'desc',  id: uuidv4() },
      ],

      sortStrategy: [
        { key: 'name', direction: 'acs', sortFunc: (items) => {
            return items.sort((a, b) => a.name.localeCompare(b.name))
          }
        },

        { key: 'name', direction: 'desc', sortFunc: (items) => {
            return items.sort((a, b) => b.name.localeCompare(a.name))
          }
        },

        { key: 'dateCreated', direction: 'acs', sortFunc: (items) => {
            return items.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
          }
        },

        { key: 'dateCreated', direction: 'desc', sortFunc: (items) => {
            return items.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          }
        },

        { key: 'updatedAt', direction: 'acs', sortFunc: (items) => {
            return items.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
          }
        },

        { key: 'updatedAt', direction: 'desc', sortFunc: (items) => {
            return items.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
          }
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      currentState: 'project/getCurrentState',
      projects: 'project/getProjects',
      organization: 'organization/getOrganization'
    }),

    isProjectLimitReached () {
      return this.projects.length >= this.organization.project_limit
    }
  },

  methods: {
    async getAllProjects () {
      await this.$store.dispatch('project/fetchProjects')
    },

    async selectAll (value) {
      for (const project of this.projects) {
        await this.$store.dispatch('project/makeProjectSelectedUnselected', {
          item: project,
          selected: value
        })
      }
    },
    makeSort (sortParam) {
      const currentSortStrategy = this.sortStrategy.find(item => item.key === sortParam.key && item.direction === sortParam.direction)
      if (currentSortStrategy) {
        currentSortStrategy.sortFunc(this.projects)
      }
    },

    async makeSearch (searchInput) {
      if (searchInput && searchInput.length >= 3) {
        const foundItems =  this.$search({
          items: this.projects,
          searchKey: searchInput,
        })
        await this.$store.dispatch('project/updateProjects', foundItems)
      } else {
        await this.$store.dispatch('project/updateProjects', this.originalProjects)
      }
    },


    openCreateProjectDialog () {
      this.createProjectDialogKey++
      this.isDialogVisible = true
    },

    closeCreateProjectDialog () {
      this.isDialogVisible = false
    },

    async createProject (project) {
      this.isDialogLoading = true

      try {
        await ProjectService.createProject(project)
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Successfully created project',
          duration: 4000,
          mode: 'success'
        })
        this.closeCreateProjectDialog()
        await this.$router.push(`/projects/${ project.id }`)
      } catch (error) {
        if (error.response.status === 422) {
          this.isNameAlreadyExistOnCreate = true
          this.validationErrorMessage = error.response.data.errors.name[0]
        }
      } finally {
        this.isDialogLoading = false
      }
    },

    async deleteItems () {
      this.isDeleteDialogLoading = true

      try {
        const itemsForDelete = this.projects.filter(item => item._selected)
        await ProjectService.deleteProject({
          ids: itemsForDelete.map(item => item.id)
        })

        this.isDeleteDialogLoading = false
        this.$refs.appSelectSortFilterSearch.closeDeleteDialog()

      } catch (e) {
        this.$refs.appSelectSortFilterSearch.closeDeleteDialog()
        this.isDeleteDialogLoading = false
      }
      finally {
        await this.getAllProjects()
      }
    },

    showProjectLimitDialog () {
      if (this.isProjectLimitReached) {
        this.isProjectLimitDialogVisible = true
        this.keyProjectLimitDialog = uuidv4()
      }
    }
  },

  async mounted () {
    await this.getAllProjects()
    this.originalProjects = cloneDeep(this.projects)
    this.showProjectLimitDialog()
  }
}
</script>
