<template>
  <form
    class="category-form"
    @submit.prevent="editCategoryName()"
  >
    <v-text-field
      id="inline-category-name-edit-field"
      v-model="inlineCategorySelectedName"
      :error-messages="inlineCategorySelectedErrorMessages"
      autocomplete="off"
      placeholder="Category Name"
      single-line
      @blur="$v.inlineCategorySelectedName.$touch()"
      @keyup.space.prevent=""
    />

    <div class="form-actions guttered-row inline dense">
      <v-btn
        id="inline-category-cancel-button"
        color="primary"
        outlined
        rounded
        small
        @click.prevent="$emit('cancelInlineEdit')"
      >
        Cancel
      </v-btn>

      <v-btn
        id="inline-category-save-button"
        :disabled="$v.inlineCategorySelectedName.$anyError"
        color="primary"
        rounded
        small
        type="submit"
        @click="editCategoryName()"
      >
        Save
      </v-btn>
    </div>
  </form>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'

export default {
  name: "CategoryFormInlineEdit",
  validations: {
    inlineCategorySelectedName: {
      required,
      maxLength: maxLength(35)
    }
  },

  props: {
    inlineCategorySelectedNameProp: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      inlineCategorySelectedName: this.inlineCategorySelectedNameProp
    }
  },

  computed: {
    inlineCategorySelectedErrorMessages () {
      const errors = []

      if (!this.$v.inlineCategorySelectedName.$dirty) {
        return errors
      }

      !this.$v.inlineCategorySelectedName.maxLength && errors.push('Category name must be at most 35 characters long!')

      return errors
    }
  },

  methods: {
    editCategoryName () {
      this.$emit('editCategoryName', this.inlineCategorySelectedName)
    }
  }

}
</script>

<style scoped>

</style>
