import { BaseClass } from '@/classes/base/BaseClass'
import { ProjectSingleRule } from '@/classes/project/project-rule/ProjectSingleRule'

// import store from '@/store'
export class ProjectRuleSet extends BaseClass {

  constructor (data) {
    super()
    this.id = data.id
    this.rules = ProjectSingleRule.createList(data.rules)
    this.name= data.name
    this._expand = 1
  }


  getCurrentShellAndCurrentRuleSet (ruleModel) {
    // const ruleModel = store.getters['project/getRuleModel']
    let currentRuleShell
    let currentRuleSet

    for (const shell of ruleModel.shells) {
      for ( const ruleSet of shell.rule_sets) {
        if (ruleSet.id === this.id) {
          currentRuleShell = shell
          currentRuleSet = ruleSet
        }
      }
    }

    return { currentRuleShell, currentRuleSet }
  }


  getPayloadDeleteRuleSet (ruleModel) {
    const { currentRuleShell }  = this.getCurrentShellAndCurrentRuleSet(ruleModel)
    const { currentRuleSet } = this.getCurrentShellAndCurrentRuleSet(ruleModel)

    const ruleSetIndexOfAllRuleSets = currentRuleShell.rule_sets.indexOf(currentRuleSet)

    return  {
      shell: currentRuleShell,
      ruleSetIndex: ruleSetIndexOfAllRuleSets
    }
  }

  get _hasAnyErrors () {
    return this.rules.some(item => item._hasAnyError)
  }

  validate () {
    this.rules.forEach(rule => rule.validate())
    if (this._hasAnyErrors) {
      this._expand = 0
    }
  }

  get _payload () {
    return {
      id: this.id,
      name: this.name,
      rules: this.rules.map(rule => rule._payload)
    }
  }

  get _nameIndex () {
    return Number(this.name.split(' ')[2])
  }

}