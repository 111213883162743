import { BaseClass } from "@/classes/base/BaseClass"

export class CatalogClass extends BaseClass {
    constructor (data = {}) {
        super()
        this.created_at = data.created_at
        this.description = data.description
        this.id = data.id
        this.image = data.image
        this.modules_count = data.modules_count
        this.name = data.name
        this.updated_at = data.updated_at
        this._selected = false
    }


    makeItemSelectedStoreAction () {
        return 'catalog/makeCatalogSelectedUnselected'
    }
}