<template>
  <div>
    <project-tab-configurations :configurations-prop="configurations" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProjectTabConfigurations from '@/components/project/tab/ProjectTabConfigurations'

export default {
  name: "ProjectTabConfigurationsContainer",
  components: { ProjectTabConfigurations },
  computed: {
    ...mapGetters(
      {
        configurations: 'project/getConfigurations'
      }
    )
  }
}
</script>

<style lang="scss" scoped>

</style>
