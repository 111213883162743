<template>
  <div class="sort-by-wrapper flex-item">
    <v-hover v-slot="{ hover }">
      <v-select
        v-model="selected"
        :background-color="hover || isFocus ? 'primary lighten-2' : '' "
        :items="sortOptionsProp"
        :menu-props="menuProps"
        return-object
        append-icon="mdi-chevron-down"
        class="ma-0 pa-0"
        color="primary"
        dense
        height="30"
        placeholder="Sort By"
        rounded
        width="90px"
        @blur="isFocus = false"
        @input="changeSort"
        @mouseup="isFocus = true"
      >
        <template #prepend-inner>
          <app-svg
            class="ml-1"
            color="primary"
            size="16"
            src="~/assets/img/icons/sort.svg"
          />
        </template>

<!--        <template v-slot:selection="{ item, index }">-->
<!--          <span-->
<!--            v-if="index === 0 && item"-->
<!--            class="primary&#45;&#45;text"-->
<!--            style="padding: 2px 0 0 4px;"-->
<!--          >-->
<!--            Sort By-->
<!--          </span>-->
<!--        </template>-->
      </v-select>
    </v-hover>
  </div>
</template>

<script>
import AppSvg from '@/components/app/AppSvg'

export default {
  name: 'AppDataSortBy',
  components: { AppSvg },

  props: {
    sortOptionsProp: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      menuProps: {
        bottom: true,
        contentClass: 'sort-by-select-menu-bottom',
        maxWidth: 210
      },
      selected: null,
      isFocus: false
    }
  },

  methods: {
    changeSort () {
      this.isFocus = false
      this.$emit('changeSort', this.selected)
    }
  }
}
</script>

<style lang="scss">

.sort-by-select-menu-bottom {
  margin-top: 35px;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 10%), 0 2px 2px 0 rgb(0 0 0 / 10%), 0 1px 5px 0 rgb(0 0 0 / 10%);
}
</style>
