import { BaseClass } from '@/classes/base/BaseClass'
import { ProjectRuleSet } from '@/classes/project/ProjectRuleSet'

export class ProjectRuleShell extends BaseClass {
  constructor (data = {}) {
    super()

    this.id = data.id
    this.available_modules = data.available_modules
    this.created_at =  data.created_at
    this.image = data.image
    this.rule_sets = ProjectRuleSet.createList(data.rule_sets)
    this.updated_at = data.updated_at
  }

  get _hasAnyErrors () {
    return this.rule_sets.some(item => item._hasAnyErrors)
  }

  validate () {
    this.rule_sets.forEach(ruleSet => ruleSet.validate())
  }

  get _payload () {
    return {
      id: this.id,
      rule_sets: this.rule_sets.map(rule_set => rule_set._payload)
    }
  }
}