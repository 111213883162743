import { BaseClass } from '@/classes/base/BaseClass'

export class ProjectRuleParameter extends BaseClass {
  constructor (data = {}) {
    super()

    this.name = data.Name
    this.storageType = data.StorageType
    this.parameterType = data.ParameterType
  }

  get _realStorageType () {
    return this.parameterType === 'Yes/No' ? 'Bool' : this.storageType
  }

  get _entireRuleParam () {
    return this
  }
}