<template>
  <div>
    <v-divider style="border-width: 1px;" />

    <project-tab-grid
      v-if="gridModel"
      :grid-model-prop="gridModel"
    />

    <div
      v-if="!gridModel"
      class="d-flex justify-center align-center tabs-content-empty-section"
    >
      <span> No Views has been added yet </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProjectTabGrid from '@/components/project/tab/ProjectTabGrid.vue'

export default {
  name: "ProjectTabGridContainer",
  components: { ProjectTabGrid },
  computed: {
    ...mapGetters(
      {
        gridModel: 'project/getGrid'
      }
    )
  }
}
</script>

<style lang="scss" scoped>
.tabs-content-empty-section {
  min-height: 525px;
}

</style>
