<template>
  <div>
    <h1 class="mb-10">
      Catalogues
    </h1>

    <app-select-sort-filter-search-header
        @makeSearch="makeSearch"
        @selectAllItems="selectAll"
        :items-prop="catalogs"
        :sort-options-prop="sortOptions"
        @changeSort="makeSort"
        @deleteItems="deleteItems"
        :is-loading-prop="isDeleteDialogLoading"
        ref="appSelectSortFilterSearch"
    />

    <v-divider class="mt-1 mb-8" />

    <list-overview
      :can-create=true
      :data-source.sync="catalogs"
      :is-list-loading-prop="currentState === actionStateConstants.LOADING"
      :selectable="true"
      list-type="catalog"
      target-url="/catalogues"
    >
      <template #create-card>
        <list-item-create-card
          action="Create"
          entity-type="catalogue"
          @click="openCreateCatalogDialog"
        />
      </template>

      <template #entity-card="{ entity, selectable, target }">
        <list-item-common
          :entity="entity"
          :selectable="selectable"
          :target="target"
        />
      </template>
    </list-overview>

    <catalog-dialog-create
      v-if="isDialogVisible"
      :key="createCatalogDialogKey + '-catalog-dialog'"
      v-model="isDialogVisible"
      :is-loading-prop="isDialogLoading"
      :is-name-already-exist-prop.sync="isNameAlreadyExistOnCreate"
      :title-prop="'Create New Catalogue'"
      :validation-error-message-prop.sync="validationErrorMessage"
      @createCatalog="createCatalog"
    />
  </div>

</template>

<script>

import ListOverview from '@/components/list/ListOverview'
import ListItemCommon from '@/components/list/ListItemCommon'
import ListItemCreateCard from '@/components/list/ListItemCreateCard'
import CatalogDialogCreate from '@/components/catalog/CatalogDialogCreate'
import AppSelectSortFilterSearchHeader from '@/components/app/header/AppSelectSortFilterSearchHeader'

import CatalogService from '@/services/catalog.service'
import { actionStates } from '@/constants'
import { mapGetters } from "vuex"
import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from "uuid"

export default {
  name: 'CatalogueAllView',
  components: {
    CatalogDialogCreate,
    ListOverview,
    ListItemCreateCard,
    ListItemCommon,
    AppSelectSortFilterSearchHeader
  },

  data () {
    return {
      actionStateConstants: actionStates,
      isDialogVisible: false,
      createCatalogDialogKey: 0,
      isDialogLoading: false,
      isDeleteDialogLoading: false,
      title: 'Catalogues',
      isNameAlreadyExistOnCreate: false,
      validationErrorMessage: '',
      originalCatalogs: [],

      fuseSearchOption: {
        shouldSort: true,
        threshold: 0.2,
        minMatchCharLength: 1,
        isCaseSensitive: false,
        keys: [
          'name',
          'description'
        ]
      },

      sortOptions: [
        { text: 'Name - [A-Z]', key: 'name', direction: 'acs', id: uuidv4() },
        { text: 'Name - [Z-A]', key: 'name', direction: 'desc', id: uuidv4() },

        { text: 'Date Created - [A-Z]', key: 'dateCreated', direction: 'acs', id: uuidv4() },
        { text: 'Date Created - [Z-A]', key: 'dateCreated', direction: 'desc', id: uuidv4() },

        { text: 'Date Updated - [A-Z]', key: 'updatedAt', direction: 'acs',  id: uuidv4() },
        { text: 'Date Updated - [Z-A]', key: 'updatedAt', direction: 'desc',  id: uuidv4() },
      ],

      sortStrategy: [
        { key: 'name', direction: 'acs', sortFunc: (items) => {
            return items.sort((a, b) => a.name.localeCompare(b.name))
          }
        },

        { key: 'name', direction: 'desc', sortFunc: (items) => {
            return items.sort((a, b) => b.name.localeCompare(a.name))
          }
        },

        { key: 'dateCreated', direction: 'acs', sortFunc: (items) => {
            return items.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
          }
        },

        { key: 'dateCreated', direction: 'desc', sortFunc: (items) => {
            return items.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          }
        },

        { key: 'updatedAt', direction: 'acs', sortFunc: (items) => {
            return items.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
          }
        },

        { key: 'updatedAt', direction: 'desc', sortFunc: (items) => {
            return items.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
          }
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      currentState: 'catalog/getCurrentState',
      catalogs: 'catalog/getCatalogs'
    }),
  },

  methods: {
    async getAllCatalogues () {
      await this.$store.dispatch('catalog/fetchCatalogs')
    },

    async selectAll (value) {
      for (const catalog of this.catalogs) {
        await this.$store.dispatch('catalog/makeCatalogSelectedUnselected', {
          item: catalog,
          selected: value
        })
      }
    },

    openCreateCatalogDialog () {
      this.createCatalogDialogKey++
      this.isDialogVisible = true
    },

    closeCreateCatalogDialog () {
      this.isDialogVisible = false
    },

    makeSort (sortParam) {
      const currentSortStrategy = this.sortStrategy.find(item => item.key === sortParam.key && item.direction === sortParam.direction)
      if (currentSortStrategy) {
        currentSortStrategy.sortFunc(this.catalogs)
      }
    },

    async makeSearch (searchInput) {
      if (searchInput && searchInput.length >= 3) {
        const foundItems =  this.$search({
          items: this.catalogs,
          searchKey: searchInput,
          fuseSearchOptions: this.fuseSearchOption
        })
        await this.$store.dispatch('catalog/updateCatalogs', foundItems)
      } else {
        await this.$store.dispatch('catalog/updateCatalogs', this.originalCatalogs)
      }
    },

    async createCatalog (catalog) {
      this.isDialogLoading = true

      try {
        await CatalogService.createCatalog(catalog)
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Successfully created catalog',
          duration: 4000,
          mode: 'success'
        })
        this.closeCreateCatalogDialog()
        await this.$router.push(`/catalogues/${ catalog.id }`)
      } catch (error) {
        if (error.response.status === 422) {
          this.isNameAlreadyExistOnCreate = true
          this.validationErrorMessage = error.response.data.errors.name[0]
        }
      } finally {
        this.isDialogLoading = false
      }
    },

    async deleteItems () {
      this.isDeleteDialogLoading = true
      try {
        const itemsForDelete = this.catalogs.filter(item => item._selected)
        await CatalogService.deleteCatalog({
          ids: itemsForDelete.map(item => item.id)
        })

        this.isDeleteDialogLoading = false
        this.$refs.appSelectSortFilterSearch.closeDeleteDialog()

      } catch (e) {
        this.isDeleteDialogLoading = false

      } finally {
        this.$refs.appSelectSortFilterSearch.closeDeleteDialog()
        await this.getAllCatalogues()
      }


    },
  },

  async mounted () {
    await this.getAllCatalogues()
    this.originalCatalogs = cloneDeep(this.catalogs)
  }
}
</script>
