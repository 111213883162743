import $axios from '../plugins/axios'

class CatalogService {
  getAllCatalogues () {
    return $axios.get(`/get-catalogues`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleCatalog (catalogId) {
    return $axios.get(`/get-catalogue/${ catalogId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeCatalogName (catalog) {
    return $axios.post(`/change-catalogue-name`, catalog)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeCatalogPicture (catalog) {
    return $axios.post(`/change-catalogue-image`, catalog)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeCatalogDescription (catalog) {
    return $axios.post(`/change-catalogue-description`, catalog)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  createCatalog (catalog) {
    return $axios.post('/create-catalogue', catalog)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
  deleteCatalog (payload) {
    return $axios.post('/delete-catalogue', payload)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new CatalogService()
