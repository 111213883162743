import $axios from '../plugins/axios'

class CategoryService {
  getCatalogueCategories (catalogId) {
    return $axios.get(`/get-catalogue-categories/${ catalogId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  updateCatalogueCategories (categoriesList) {
    return $axios.post(`/update-catalogue-categories`, categoriesList)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new CategoryService()
