<template>
  <div>
    <app-single-item-edit-left-section
      v-if="catalogProp"
      :entity-item-prop="catalogProp"
      :entity-type-prop="'catalog'"
      @showEditCatalogLogoDialog="$emit('showEditCatalogLogoDialog')"
      @showEditDescriptionDialog="$emit('showEditDescriptionDialog')"
      @showEditImageDialog="$emit('showEditImageDialog')"
      @showEditNameDialog="$emit('showEditNameDialog')"
    >
      <template #other-edit-fields>
        <div class="text-regular pt-10 pb-4">
          <v-hover v-slot="{ hover }">
            <div
              class="description-text hover-text-effect"
              style="cursor:pointer;"
              @click="$emit('showEditDescriptionDialog')"
            >
              <span
                v-if="catalogProp.description"
                style="
                 white-space: pre-wrap;
                 word-break: break-word;
                "
              >
                {{ catalogProp.description }}
              </span>

              <span
                v-else-if="!catalogProp.description"
                class="entity-fields-text-span "
              >
                Description is empty click here to edit it
              </span>

              <v-btn
                v-if="true"
                :class="{ 'element-invisible': !hover }"
                class="entity-fields-edit-btn ml-1"
                color="primary"
                height="22"
                icon
                small
                width="22"
              >
                <app-svg
                  size="14"
                  src="~/assets/img/icons/edit.svg"
                />
              </v-btn>
            </div>
          </v-hover>

          <div class="pt-10">
            <v-tooltip
              bottom
              color="primary"
            >
              <span>Remove</span>

              <template #activator="{ on, attrs }">
                <v-btn
                  class="delete-icon"
                  color="primary"
                  icon
                  x-small
                  v-bind="attrs"
                  @click="$emit('showDeleteDialog')"
                  v-on="on"
                >
                  <app-svg
                    size="24"
                    src="~/assets/img/icons/delete.svg"
                  />
                </v-btn>
              </template>

            </v-tooltip>
          </div>

        </div>
      </template>
    </app-single-item-edit-left-section>
  </div>
</template>

<script>
import AppSingleItemEditLeftSection from '@/components/app/AppSingleItemEditLeftSection'

import AppSvg from '@/components/app/AppSvg'

export default {
  name: "CatalogEditLeftSidePanel",
  components: {
    AppSvg,

    AppSingleItemEditLeftSection
  },

  props: {
    catalogProp: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
