<template>
  <div v-if="ruleModel && ruleParams.length">
    <v-divider style="border-width: 1px;" />

    <project-tab-rule-exported-view/>

    <project-tab-rule-shell-container/>
  </div>

  <div
    v-else
    class="d-flex justify-center align-center tabs-content-empty-section"
  >
    <span> No Rule Model has been added yet </span>
  </div>
</template>

<script>
import ProjectTabRuleExportedView from '@/components/project/tab/rules/ProjectTabRuleExportedView'
import ProjectTabRuleShellContainer from '@/components/project/tab/rules/ProjectTabRuleShellContainer'

export default {
  name: "ProjectTabRuleContainer",
  components: { ProjectTabRuleExportedView, ProjectTabRuleShellContainer },
  computed: {
    ruleModel () {
      return this.$store.state.project.ruleModel
    },
    ruleParams () {
      return this.$store.state.project.ruleParams
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs-content-empty-section {
  min-height: 525px;
}
</style>