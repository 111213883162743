import ProjectService from "@/services/project.service"
import { ProjectRuleModel } from '@/classes/project/ProjectRuleModel'
import { ProjectRuleParameter } from '@/classes/project/project-rule-parameters/ProjectRuleParameter'

import { Configuration } from '@/classes/configuration/Configuration'

import { ProjectClass } from '@/classes/project/ProjectClass'

import { v4 as uuidv4 } from 'uuid'
import Vue from 'vue'

const actionStates = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  ERRORED: "ERRORED",
  NOT_FOUND: "NOT_FOUND",
  DATA_LOADED: "DATA_LOADED",
  DIALOG_SUBMIT_LOADING: "DIALOG_SUBMIT_LOADING",
  DIALOG_SUBMIT_ERROR: "DIALOG_SUBMIT_ERROR"
}

const state = {
  currentState: actionStates.INITIAL,
  projects: null,
  project: null,
  configurations: null,
  backgroundModels: null,
  grid: null,
  catalogues: null,
  ruleModel: null,
  ruleParams: null,
  rerender: 0
}

const getters = {
  getProjects (state) {
    return state.projects
  },

  getProject (state) {
    return state.project
  },

  getCurrentState (state) {
    return state.currentState
  },

  getConfigurations (state) {
    return state.configurations
  },

  getBackgroundModels (state) {
    return state.backgroundModels
  },

  getGrid (state) {
    return state.grid
  },

  getCatalogues (state) {
    return state.catalogues
  },

  getRuleModel (state) {
    return state.ruleModel
  }
}

const mutations = {
  increaseRerender (){
    state.rerender ++
  },
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setProjects (state, projects) {
    state.projects = projects
  },

  setProject (state, project) {
    state.project = project ?? {}
  },

  setConfigurations (state, configurations) {
    state.configurations = configurations
  },

  setBackgroundModels (state, backgroundModels) {
    state.backgroundModels = backgroundModels
  },

  setGrid (state, grid) {
    state.grid = grid
  },

  setCatalogues (state, catalogues) {
    state.catalogues = catalogues ?? []
  },

  setProjectRuleModel (state, ruleModel) {
    state.ruleModel = ruleModel
  },

  setProjectRuleParams (state, ruleParams) {
    state.ruleParams = ruleParams
  },

  appendNewRuleSet (state, { shell, ruleSet }) {
    const shellFromTheState = state.ruleModel.shells.find(item => item.id === shell.id)

    const ruleSetIndex = shellFromTheState.rule_sets.length
    Vue.set(shellFromTheState.rule_sets, ruleSetIndex, ruleSet)
  },


  removeRuleSet (state, { shell, ruleSetIndex }) {
    const shellFromTheState = state.ruleModel.shells.find(item => item.id === shell.id)
    shellFromTheState.rule_sets.splice(ruleSetIndex, 1)
  },

  setRuleName (_, { entireRuleParamObj, rule }) {
    rule.parameter_name = entireRuleParamObj.name
    rule._dataType = entireRuleParamObj._realStorageType
    rule._hasValueError = false
    rule._valueError = null
    rule._rerenderOperator = uuidv4()
    rule._rerenderValue = uuidv4()
  },

  setRuleOperator (_, { operator, rule }) {
    rule.operator = operator
  },

  appendNewRule (_, { ruleSet, rule }) {
    const ruleIndex = ruleSet.rules.length
    Vue.set(ruleSet.rules, ruleIndex, rule)
  },

  removeRule (_, { ruleSet, ruleIndex }) {
      ruleSet.rules.splice(ruleIndex, 1)
  },

  setRuleValue (_, { rule, ruleValue }) {
    rule.value = ruleValue
  },

  setConfigurationLoading (state, { conf, value }) {
    conf._loading = value
  },

  setProjectSelectedUnselected (state, { item, selected }) {
    const currentProject = state.projects.find(project => project.id === item.id)
    Vue.set(currentProject, '_selected', selected)
  }

}

const actions = {

  async fetchProjects (vuexContext) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      const response = await ProjectService.getAllProjects()
      const projects = ProjectClass.createList(response)
      vuexContext.commit('setProjects', projects)

      vuexContext.commit('setActionState', actionStates.DATA_LOADED)

    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },


  async fetchProject (vuexContext, id) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      let response = await ProjectService.getSingleProject(id)
      vuexContext.commit('setProject', response)

      let responseConfigurations = await ProjectService.getProjectConfigurations(id)
      const configurations = Configuration.createList(
        // This is in order to pass tests, because the mocked response is of project is empty object
        Object.keys(vuexContext.state.project).length ? responseConfigurations : []
      )
      vuexContext.commit('setConfigurations', configurations)

      await vuexContext.dispatch('fetchProjectBackgroundModels', id)

      await vuexContext.dispatch('fetchSingleProjectGrid', id)

      let responseCatalogues = await ProjectService.getProjectCatalogues(id)
      vuexContext.commit('setCatalogues', responseCatalogues)

      await vuexContext.dispatch('fetchProjectRuleModel', id)

      vuexContext.commit('setActionState', actionStates.DATA_LOADED)

    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },

  async updateCurrentProject (vuexContext) {
    let currentProject = vuexContext.getters.getProject
    vuexContext.dispatch('fetchProject', currentProject.id)
  },

  async updateProjectName (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await ProjectService.changeProjectName(data)
      await vuexContext.dispatch('updateCurrentProject')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async updateProjectPicture (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await ProjectService.changeProjectPicture(data)
      await vuexContext.dispatch('updateCurrentProject')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async updateProjectLogo (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await ProjectService.changeProjectLogo(data)
      await vuexContext.dispatch('updateCurrentProject')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async updateProjectDescription (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await ProjectService.changeProjectDescription(data)
      await vuexContext.dispatch('fetchProject', data.id)
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async fetchSingleProjectGrid (vuexContext, id) {
    if (vuexContext.state.project.has_grid_model) {
      let responseGrid = await ProjectService.getProjectGridModel(id)
      vuexContext.commit('setGrid', responseGrid)
    } else {
      vuexContext.commit('setGrid', null)
    }
  },

  async fetchSingleProjectCatalogues (vuexContext, id) {
    let response = await ProjectService.getProjectCatalogues(id)
    vuexContext.commit('setCatalogues', response)
  },

  async assignCataloguesToProject (vuexContext, data) {
    await ProjectService.assignCataloguesToProject(data)
    await vuexContext.dispatch('fetchSingleProjectCatalogues', data.project_id)
  },

  async deleteCataloguesToProject (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      await ProjectService.deleteCataloguesForProject(data)
      await vuexContext.dispatch('fetchSingleProjectCatalogues', data.project_id)
      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.ERRORED)
      throw e
    }
  },

  async fetchProjectRuleModel (vuexContext, projectId) {
    if (vuexContext.state.project.has_rule_model) {
      try {
        // It is mandatory getProjectAvailableParameters to be the first request
        const responseProjectRuleAvailableParams = await ProjectService.getProjectAvailableParameters(projectId)
        const projectRuleAvailableParams = ProjectRuleParameter.createList(responseProjectRuleAvailableParams)
        vuexContext.commit('setProjectRuleParams', projectRuleAvailableParams)

        const responseRuleModel = await ProjectService.getProjectRuleModel(projectId)

        if (responseRuleModel) {
          const ruleModel = new ProjectRuleModel(responseRuleModel)
          ProjectRuleModel.setProjectSingleRuleDataType({ ruleModel: ruleModel, ruleParams: projectRuleAvailableParams})

          vuexContext.commit('setProjectRuleModel', ruleModel)
        } else {
          vuexContext.commit('setProjectRuleModel', null)
        }

      } catch (e) {
        vuexContext.commit('setActionState', actionStates.ERRORED)
        throw e
      }
    } else {
      vuexContext.commit('setProjectRuleModel', null)
    }
  },

  async fetchProjectConfigurations (vuexContext, projectId) {
    try {
      let responseConfigurations = await ProjectService.getProjectConfigurations(projectId)
      const configurations = Configuration.createList(responseConfigurations)
      vuexContext.commit('setConfigurations', configurations)
      vuexContext.commit('increaseRerender')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.ERRORED)
      throw e
    }
  },

  async fetchProjectBackgroundModels (vuexContext, projectId) {
    if (vuexContext.state.project.has_background_models) {
      let responseBackgroundModels = await ProjectService.getProjectBackgroundModels(projectId)
      vuexContext.commit('setBackgroundModels', responseBackgroundModels)
    } else {
      vuexContext.commit('setBackgroundModels', null)
    }
  },

  changeRuleName (vuexContext, { entireRuleParamObj, rule }) {
    vuexContext.commit('setRuleName', { entireRuleParamObj, rule })
  },

  changeRuleOperator (vuexContext, { operator, rule }) {
    vuexContext.commit('setRuleOperator', { operator, rule })
  },

  addNewRuleSet (vuexContext, { shell, ruleSet }) {
    vuexContext.commit('appendNewRuleSet', { shell, ruleSet })
  },

  deleteRuleSet (vuexContext, { shell, ruleSetIndex  }) {
    vuexContext.commit('removeRuleSet', { shell, ruleSetIndex  })
  },

  addNewRule (vuexContent, { ruleSet, rule }) {
    vuexContent.commit('appendNewRule', { ruleSet, rule })
  },
  deleteRule (vuexContent, { ruleSet, ruleIndex }) {
    vuexContent.commit('removeRule', { ruleSet, ruleIndex })
  },

  addRuleValue (vuexContent, {rule, ruleValue}) {
    vuexContent.commit('setRuleValue', { rule, ruleValue })
  },

  changeConfigurationLoading (vuexContent, { conf, value }) {
    vuexContent.commit('setConfigurationLoading', { conf, value })
  },

  makeProjectSelectedUnselected (vuexContext, { item, selected }) {
    vuexContext.commit('setProjectSelectedUnselected', { item, selected })
  },

  updateProjects (vuexContext, projects) {
    vuexContext.commit('setProjects', projects)
  }

}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
