<template>
  <form
    class="mt-3 mb-4"
    @submit.prevent="addNewCategory()"
  >
    <v-text-field
      id="new-category-name"
      v-model="newCategoryName"
      :error-messages="newCategoryNameErrors"
      autocomplete="off"
      clearable
      label="New Category"
      placeholder="New Category"
      required
      single-line
      @blur="$v.$touch()"
      @input="$v.newCategoryName.$touch()"
    >
      <template #append-outer>
        <v-btn
          id="add-new-category-button"
          :disabled="$v.newCategoryName.$anyError"
          class="ml-5 mt-1"
          color="primary"
          depressed
          fab
          height="36"
          type="submit"
          width="36"
          @click="addNewCategory()"
        >
          <app-svg
            color="white"
            size="16"
            src="~/assets/img/icons/plus.svg"
          />
        </v-btn>
      </template>
    </v-text-field>
  </form>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
import AppSvg from '@/components/app/AppSvg'

export default {
  name: "CategoryFormCreate",
  components: { AppSvg },
  validations: {
    newCategoryName: {
      required,
      maxLength: maxLength(35)
    }
  },

  data () {
    return {
      newCategoryName: null
    }
  },

  computed: {
    newCategoryNameErrors () {
      const errors = []

      if (!this.$v.newCategoryName.$dirty) {
        return errors
      }

      !this.$v.newCategoryName.maxLength && errors.push('Category name must be at most 35 characters long!')

      return errors
    }
  },

  methods: {
    addNewCategory () {
      this.$emit('addNewCategory', this.newCategoryName)
      this.newCategoryName = null
    }
  }

}
</script>

<style scoped>

</style>
