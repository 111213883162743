import { render, staticRenderFns } from "./ProjectTabRuleShell.vue?vue&type=template&id=53201600&scoped=true&"
import script from "./ProjectTabRuleShell.vue?vue&type=script&lang=js&"
export * from "./ProjectTabRuleShell.vue?vue&type=script&lang=js&"
import style0 from "./ProjectTabRuleShell.vue?vue&type=style&index=0&id=53201600&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53201600",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VExpansionPanels,VImg,VOverlay,VRow,VTooltip})
