import ModuleService from "@/services/module.service"
import { ModuleClass } from "@/classes/module/ModuleClass"

import Vue from 'vue'

const actionStates = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  ERRORED: "ERRORED",
  NOT_FOUND: "NOT_FOUND",
  DATA_LOADED: "DATA_LOADED",
  DIALOG_SUBMIT_LOADING: "DIALOG_SUBMIT_LOADING",
  DIALOG_SUBMIT_ERROR: "DIALOG_SUBMIT_ERROR"
}

const state = {
  currentState: actionStates.INITIAL,
  modules:null,
  module: null,
  params: null
}

const getters = {
  getModules (state) {
    return state.modules
  },

  getModule (state) {
    return state.module
  },

  getCurrentState (state) {
    return state.currentState
  },

  getParams (state) {
    return state.params
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setModules (state, modules) {
    state.modules = modules
  },

  setModule (state, module) {
    state.module = module ?? {}
  },

  setParams (state, params) {
    state.params = params ?? {}
  },

  setModuleTags (state, tags) {
    state.module.tags = tags
  },

  setModuleSelectedUnselected (state, { item, selected }) {
    const currentModule = state.modules.find(module => module.id === item.id)
    Vue.set(currentModule, '_selected', selected)
  }
}

const actions = {
  async fetchModules (vuexContext) {
    vuexContext.commit('setActionState', actionStates.LOADING)
    try {

      let response = await ModuleService.getAllModules()
      const modules = ModuleClass.createList(response)
      vuexContext.commit('setModules', modules)

      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },


  async fetchModule (vuexContext, id) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      let response = await ModuleService.getSingleModule(id)
      vuexContext.commit('setModule', response)

      let responseParams = await ModuleService.getAllModuleParameters(id)
      vuexContext.commit('setParams', responseParams)

      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },

  async updateCurrentModule (vuexContext) {
    let currentModule = vuexContext.getters.getModule
    vuexContext.dispatch('fetchModule', currentModule.id)
  },

  async updateModuleName (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await ModuleService.changeModuleName(data)
      await vuexContext.dispatch('updateCurrentModule')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async updateModulePicture (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await ModuleService.changeModulePicture(data)
      await vuexContext.dispatch('updateCurrentModule')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async updateModuleDimensions (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await ModuleService.changeModuleDimensions(data)
      await vuexContext.dispatch('updateCurrentModule')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async deleteModule (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await ModuleService.deleteModule(data)
      vuexContext.commit('setActionState', actionStates.INITIAL)
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  updateModuleTags (vuexContext, tags) {
    vuexContext.commit('setModuleTags', tags)
  },

  makeModuleSelectedUnselected (vuexContext, { item, selected }) {
    vuexContext.commit('setModuleSelectedUnselected', { item, selected })
  },

  updateModules (vuexContext, modules) {
    vuexContext.commit('setModules', modules)
  }
}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
