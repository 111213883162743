var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.projectProp)?_c('app-single-item-edit-left-section',{attrs:{"entity-item-prop":_vm.projectProp,"entity-type-prop":'project'},on:{"showEditDescriptionDialog":function($event){return _vm.$emit('showEditDescriptionDialog')},"showEditImageDialog":function($event){return _vm.$emit('showEditImageDialog')},"showEditNameDialog":function($event){return _vm.$emit('showEditNameDialog')},"showEditProjectLogoDialog":function($event){return _vm.$emit('showEditProjectLogoDialog')}},scopedSlots:_vm._u([{key:"other-edit-fields",fn:function(){return [_c('div',{staticClass:"text-center pb-7"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"hover-text-effect d-flex justify-center my-1 mx-auto",on:{"click":function($event){return _vm.$emit('showEditProjectLogoDialog')}}},[_c('div',[(_vm.projectProp.logo && _vm.projectProp.logo.url)?_c('v-img',_vm._g(_vm._b({staticStyle:{"cursor":"pointer","max-width":"80px"},attrs:{"id":"project-logo","src":_vm.projectProp.logo.url,"contain":"","max-height":"20","max-width":"80"}},'v-img',_vm.$attrs,false),_vm.$listeners)):(!_vm.projectProp.logo)?_c('span',{staticClass:"logo-text ",staticStyle:{"cursor":"pointer"}},[_vm._v(" Logo is empty click here to edit it ")]):_vm._e()],1),_c('div',[(true)?_c('v-btn',{staticClass:"entity-fields-edit-logo-btn ml-1",class:{ 'element-invisible': !hover },attrs:{"color":"primary","height":"auto","icon":"","small":""}},[_c('app-svg',{attrs:{"size":"16","src":"~/assets/img/icons/camera.svg"}})],1):_vm._e()],1)])]}}],null,false,46422386)})],1),_c('div',{staticClass:"text-regular pb-4"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"description-text hover-text-effect",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit('showEditDescriptionDialog')}}},[(_vm.projectProp.description)?_c('span',{staticStyle:{"white-space":"pre-wrap","word-break":"break-word"}},[_vm._v(" "+_vm._s(_vm.projectProp.description)+" ")]):(!_vm.projectProp.description)?_c('span',{staticClass:"entity-fields-text-span"},[_vm._v(" Description is empty click here to edit it ")]):_vm._e(),(true)?_c('v-btn',{staticClass:"entity-fields-edit-description-btn ml-1",class:{ 'element-invisible': !hover },attrs:{"color":"primary","height":"22","icon":"","small":"","width":"22"}},[_c('app-svg',{attrs:{"size":"14","src":"~/assets/img/icons/edit.svg"}})],1):_vm._e()],1)]}}],null,false,3420699808)})],1),_c('div',{staticClass:"pt-10"},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"delete-icon",attrs:{"color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.$emit('showDeleteDialog')}}},'v-btn',attrs,false),on),[_c('app-svg',{attrs:{"size":"24","src":"~/assets/img/icons/delete.svg"}})],1)]}}],null,false,2643642104)},[_c('span',[_vm._v("Remove")])])],1)]},proxy:true}],null,false,1078509712)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }