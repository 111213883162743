import { actionStates } from '@/constants'
import CatalogService from "@/services/catalog.service"
import { CatalogClass } from "@/classes/catalog/CatalogClass"

import Vue from 'vue'

const state = {
  currentState: actionStates.INITIAL,
  catalogs: null,
  catalog: null
}

const getters = {
  getCatalogs (state) {
    return state.catalogs
  },

  getCatalog (state) {
    return state.catalog
  },

  getCurrentState (state) {
    return state.currentState
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setCatalog (state, catalog) {
    state.catalog = catalog ?? {}
  },

  setCatalogs (state, catalogs) {
    state.catalogs = catalogs
  },

  setCatalogSelectedUnselected (state, { item, selected }) {
    const currentCatalog = state.catalogs.find(catalog => catalog.id === item.id)
    Vue.set(currentCatalog, '_selected', selected)
  }
}

const actions = {

  async fetchCatalogs (vuexContext) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      const response = await CatalogService.getAllCatalogues()
      const catalogs = CatalogClass.createList(response)

      vuexContext.commit('setCatalogs', catalogs)

      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },

  async fetchCatalog (vuexContext, id) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      let response = await CatalogService.getSingleCatalog(id)

      vuexContext.commit('setCatalog', response)
      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
      await vuexContext.dispatch('category/fetchCatalogueCategories', id, { root:true })
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },

  async updateCurrentCatalog (vuexContext) {
    let currentCatalog = vuexContext.getters.getCatalog
    vuexContext.dispatch('fetchCatalog', currentCatalog.id)
  },

  async updateCatalogName (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await CatalogService.changeCatalogName(data)
      await vuexContext.dispatch('updateCurrentCatalog')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async updateCatalogPicture (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await CatalogService.changeCatalogPicture(data)
      await vuexContext.dispatch('updateCurrentCatalog')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async updateCatalogDescription (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await CatalogService.changeCatalogDescription(data)
      await vuexContext.dispatch('updateCurrentCatalog')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  makeCatalogSelectedUnselected (vuexContext, { item, selected }) {
    vuexContext.commit('setCatalogSelectedUnselected', { item, selected })
  },

  updateCatalogs (vuexContext, catalogs) {
    vuexContext.commit('setCatalogs',catalogs)
  }

}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
