import Vue from 'vue'
import VueRouter from 'vue-router'
import ProjectAllView from '@/views/project/ProjectAllView.vue'
import AppLayoutContent from '@/AppLayoutContent'
import AuthLoginView from '@/views/auth/AuthLoginView'
import AuthForgottenPassword from '@/views/auth/AuthForgottenPassword'
import AppAuthLayoutContent from '@/AppAuthLayoutContent'
import ModulesAllView from '@/views/module/ModuleAllView'
import ClientsView from '@/views/ClientsView'
import SettingsView from '@/views/SettingsView'
import ProfileView from '@/views/ProfileView'

import guest from './middleware/guest'
import auth from './middleware/auth'

import middlewarePipeline from "./middlewarePipeline"
import ProjectSingleView from '@/views/project/ProjectSingleView'
import ModuleSingleView from '@/views/module/ModuleSingleView'
import CatalogueSingleView from '@/views/catalog/CatalogueSingleView'
import CatalogueAllView from '@/views/catalog/CatalogueAllView'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: AppLayoutContent,
    children: [
      {
        path: '',
        redirect: '/dashboard'
      },

      {
        path: '/dashboard',
        component: ProjectAllView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/projects/:id',
        component: ProjectSingleView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/catalogues',
        name: 'catalogs',
        component: CatalogueAllView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/catalogues/:id',
        component: CatalogueSingleView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/modules',
        name: 'modules',
        component: ModulesAllView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/modules/:id',
        component: ModuleSingleView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/clients',
        name: 'clients',
        component: ClientsView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/settings',
        name: 'settings',
        component: SettingsView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/profile',
        name: 'profile',
        component: ProfileView,
        meta: {
          middleware: [auth]
        }
      }
    ]
  },

  {
    path: '/login',
    component: AppAuthLayoutContent,
    children: [
      {
        path: '/login',
        component: AuthLoginView,
        meta: {
          middleware: [guest]
        }
      },

      {
        path: '/forgotten-password',
        component: AuthForgottenPassword,
        meta: {
          middleware: [guest]
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware
  const context = { from, next, store }

  return middleware[0]({ ...context, nextMiddleware: middlewarePipeline(context, middleware, 1) })
})

export default router
