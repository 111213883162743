<template>
  <div>
    <div class="d-flex">
      <v-spacer />
      <v-btn
        :disabled="!this.selectedItems || this.selectedItems.length === 0"
        class="primary--text"
        height="30px"
        rounded
        text
        @click="showDeleteDialog"
      >
        <div class="guttered-row">
          <app-svg
            class="mr-2"
            color="primary"
            src="@/assets/img/icons/delete.svg"
            width="16"
          />
          Remove
        </div>
      </v-btn>
    </div>

    <v-divider class="mt-5 mb-5" />

    <div
      class="guttered-row dense"
      v-bind="$attrs"
      v-on="$listeners"
    >
    </div>

    <div>
      <div
        class="edit-modules-categories d-flex align-center pa-5 my-5"
        @click="showEditCataloguesDialog"
      >
        <v-avatar size="60">
          <div class="icon">
            <app-svg
              alt="icon"
              color="white"
              size="32"
              src="~/assets/img/icons/plus.svg"
            />
          </div>
        </v-avatar>

        <div class="section-title ml-5">
          Add new catalogues
        </div>
      </div>
    </div>

    <div
      v-for="(catalogue, index) in projectCataloguesProp"
      :key="`catalogue-${catalogue.id}-${index}`"
      class="px-2"
    >
      <app-list-item
        :selected="selectedItems.includes(catalogue)"
        hover
        @update:selected="onItemToggle($event, catalogue)"
        @click="catalogClick(catalogue)"
      >
        <v-avatar class="image-border" rounded size="60">
          <v-img
            :alt="`${catalogue.name} image`"
            :src="catalogue.image && catalogue.image.url"
          />
        </v-avatar>

        <template #title>
          <span>{{ catalogue.name }}</span>
        </template>
      </app-list-item>
    </div>

    <project-dialog-add-catalogues
      :key=addCataloguesDialogKey
      v-model="isAddCataloguesDialogVisible"
      @removeSelectedCatalogues="removeSelectedCatalogues"
    />

    <project-catalogues-dialog-delete
      :key="'delete-dialog-' + deleteDialogKey"
      v-model="isDeleteDialogVisible"
      @deleteCatalogues="deleteCatalogues"
    />
  </div>
</template>

<script>

import AppSvg from '@/components/app/AppSvg.vue'
import ProjectDialogAddCatalogues from '@/components/project/ProjectDialogAddCatalogues.vue'
import AppListItem from '@/components/app/AppListItem.vue'
import ProjectCataloguesDialogDelete from '@/components/project/ProjectCataloguesDialogDelete.vue'

export default {
  name: "ProjectTabCatalogues",
  components: { ProjectCataloguesDialogDelete, AppListItem, ProjectDialogAddCatalogues, AppSvg },

  props: {
    projectCataloguesProp: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      selectedItems: [],

      isAddCataloguesDialogVisible: false,
      addCataloguesDialogKey: 0,

      isDeleteDialogVisible: false,
      deleteDialogKey: 0
    }
  },

  computed: {
    selectedIdes () {
      return this.selectedItems.map(catalogues => catalogues.id)
    }
  },

  methods: {
    catalogClick (catalogue) {
      if (catalogue && catalogue.id) {
        this.$router.push(`/catalogues/${catalogue.id}`)
      }
    },

    showEditCataloguesDialog () {
      this.isAddCataloguesDialogVisible = true
      this.addCataloguesDialogKey++
    },

    showDeleteDialog () {
      if (this.selectedItems && this.selectedItems.length > 0) {
        this.isDeleteDialogVisible = true
        this.deleteDialogKey++
      }
    },

    deleteCatalogues () {
      this.$emit('deleteCatalogues', {
        project_id: this.$route.params.id,
        catalogue_ids: this.selectedIdes
      })
    },

    removeSelectedCatalogues () {
      this.selectedItems = []
    },

    // Selections
    areAllItemsSelected (listItems) {
      if (!listItems.length) {
        return false
      }

      return listItems.every(item => this.selectedItems.includes(item))
    },

    selectItem (item) {
      if (!this.selectedItems.includes(item)) {
        this.selectedItems.push(item)
      }
    },

    bulkSelectItems (items) {
      items = items || []

      for (const item of items) {
        this.selectItem(item)
      }
    },

    deselectItem (item) {
      this.selectedItems = this.selectedItems.filter((oneSelectedItem) => oneSelectedItem.id !== item.id)
    },

    onItemToggle (state, item) {
      if (state) {
        this.selectItem(item)
        return
      }

      this.deselectItem(item)
    },

    bulkDeselectItems (items) {
      items = items || []

      for (let item of items) {
        this.deselectItem(item)
      }
    },

    deselectAllItems () {
      this.selectedItems = []
    },

    onBulkToggle (state, items) {
      if (state) {
        this.bulkSelectItems(items)
        return
      }

      this.bulkDeselectItems(items)
    }
  }
}
</script>

<style lang="scss" scoped>

.edit-modules-categories {
  cursor: pointer;
  user-select: none;
  color: var(--v-grey-base);
  border: 1px solid var(--v-grey-lighten2);
  border-radius: $border-radius-root;
  transition: background $secondary-transition,
  border $secondary-transition,
  color $secondary-transition;

  .v-avatar {
    background: var(--v-grey-lighten1);
    transition: background $secondary-transition;
  }

  &:hover {
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);

    .v-avatar {
      background: var(--v-primary-base);
    }
  }
}
</style>
