import { BaseClass } from '@/classes/base/BaseClass'

export class Configuration extends BaseClass {
  constructor (data = {}) {
    super()

    this.created_at = data.created_at
    this.created_by_user = data.created_by_user
    this.created_by_user_id = data.created_by_user_id
    this.deleted_at = data.deleted_at
    this.id = data.id
    this.latest_save = data.latest_save
    this.name = data.name
    this.project_id = data.project_id
    this.updated_at = data.updated_at
    this.updated_by_user_id = data.updated_by_user_id

    this._loading  = false
  }
}