<template>
  <v-footer app class="footer-menu" height="80">
    <v-row class="text-center no-gutters">
      <v-col
        v-for="(item, i) in itemsProp"
        :id="'menu-link-mobile-' + i"
        :key="'key-' + i"
      >
        <div
          :class="item.class"
          class="menu-item"
          exact-active-class="selected"
          @click="($route.path !== item.to) ? $router.push(item.to) : null"
        >
          <v-tooltip color="primary" top>
            <template v-slot:activator="{ on, attrs }">
              <div class="fill" v-bind="attrs" v-on="on" />
            </template>

            <span>{{ item.title }} overview</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "TheDefaultLayoutWrapMobileFooterMenu",
  props: {
    itemsProp: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_footer.scss';
</style>
