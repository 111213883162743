<template>
  <div
    class="select-all-checkbox-wrapper px-2"
    :class="checked && 'selected'"
  >
    <v-checkbox
      dense
      label="Select All"
      :ripple="false"
      color="primary"
      class="select-all-checkbox"
      v-model="checked"
      @change="checkboxClick"
      :indeterminate="indeterminate"
      :disabled="itemsProp && itemsProp.length < 1"
      @click.native.stop=""
    >
    </v-checkbox>
  </div>
</template>

<script>

export default {
  name: "AppDataSelectAll",
  data () {
    return {
      checked: false,
    }
  },

  props: {
    itemsProp: {
      type: Array,
      default: () => {
        return []
      }
    }
  },


  computed: {
    indeterminate () {
      if (this.itemsProp) {
        const selectedItems = this.itemsProp.filter(item => item._selected)
        return selectedItems.length > 0 && selectedItems.length < this.itemsProp.length
      }
      return false
    }

  },

  methods: {
    checkboxClick () {
      this.$emit('selectAllItems', this.checked)
    }
  },
}
</script>

<style lang="scss" scoped>
.select-all-checkbox-wrapper {
  height: 30px;
  border: transparent;
  border-radius: 50px;



  &:hover {
    background-color: var(--v-primary-lighten2);
  }
  &.selected {
    background-color: var(--v-primary-lighten2);
  }
}

</style>