<template>
  <app-dialog
    v-model="isDialogVisible"
    :persistent="isLoadingProp"
    :title-prop="titleProp"
    max-width="500"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <v-text-field
        id="name-field"
        v-model="catalogueName"
        :error-messages="nameErrors"
        :placeholder="placeholderProp"
        clearable
        label="Name"
        required
        @blur="$v.$touch()"
        @input="$v.catalogueName.$touch()"
      />
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            id="close-dialog-button"
            :disabled="isLoadingProp"
            block
            color="primary"
            outlined
            raised
            rounded
            x-large
            @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            id="submit-button"
            :disabled="isLoadingProp || $v.$anyError || isNameAlreadyExistProp || isNameChangedOnEdit"
            :loading="isLoadingProp"
            block
            color="primary"
            raised
            rounded
            x-large
            @click.native="createCatalog"
          >
            <span class="text-capitalize">
              {{ action }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
import AppDialog from '@/components/app/AppDialog'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'CatalogDialogCreate',
  components: { AppDialog },

  props: {
    titleProp: {
      type: String,
      required: true
    },

    placeholderProp: {
      type: String,
      default: 'Add catalogue name...'
    },

    action: {
      type: String,
      default: 'Create'
    },

    value: {
      type: Boolean,
      default: false,
      required: true
    },

    isLoadingProp: {
      type: Boolean,
      default: false,
      required: true
    },

    catalogueNameProp: {
      type: String,
      default: ''
    },

    isNameAlreadyExistProp: {
      type: Boolean,
      default: false
    },

    validationErrorMessageProp: {
      type: String,
      default: ''
    }
  },

  validations: {
    catalogueName: {
      required,
      maxLength: maxLength(255)
    }
  },

  data () {
    return {
      catalogueName: this.catalogueNameProp
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    isNameChangedOnEdit () {
      return this.action.toLowerCase() === 'edit' && this.catalogueName === this.catalogueNameProp
    },

    nameErrors () {
      const errors = []
      if (!this.$v.catalogueName.$dirty) {
        return errors
      }

      this.isNameAlreadyExistProp && errors.push(this.validationErrorMessageProp)
      this.isNameChangedOnEdit && errors.push('Name should be different than the current name')
      !this.$v.catalogueName.maxLength && errors.push('Can contain maximum of 255 characters')
      !this.$v.catalogueName.required && errors.push('Must contain at least 1 character')
      return errors
    }
  },

  watch: {
    catalogueName () {
      if (this.isNameAlreadyExistProp) {
        this.resetIsNameAlreadyExist()
      }
    }
  },

  methods: {
    hideDialog () {
      this.resetCatalog()
      this.resetIsNameAlreadyExist()
      this.isDialogVisible = false
    },

    resetCatalog () {
      this.catalogueName = null
    },

    resetIsNameAlreadyExist () {
      this.$emit('update:isNameAlreadyExistProp', false)
      this.$emit('update:validationErrorMessageProp', '')
    },

    createCatalog () {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      this.catalogueName = this.catalogueName.trim()
      this.$emit('createCatalog', { id: uuidv4(), name: this.catalogueName })
    }
  },

  beforeDestroy () {
    this.resetIsNameAlreadyExist()
  }
}
</script>

<style scoped>

</style>
