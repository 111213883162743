<template>
  <app-dialog
    :title-prop="'Delete Rule Set'"
    :persistent="true"
    max-width="800"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span v-if="itemName.length" class ="dialog-content">
      Are you sure, that you want to delete rule -
      <span style="font-weight: bold">{{ itemName }}</span>
      ?
    </span>
    <span class="dialog-content" v-else >Are you sure, that you want to delete an empty rule?</span>

    <template #actions>
      <v-spacer class="d-none d-sm-flex" />
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            id="close-dialog-button"
            block
            color="primary"
            outlined
            raised
            rounded
            x-large
            @click="cancelClick"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            id="submit-button"
            block
            color="primary"
            raised
            rounded
            x-large
            @click="yesClick"
          >
            <span class="text-capitalize">Yes</span>
          </v-btn>
        </v-col>
      </v-row>
    </template>

  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'
export default {
  name: "DeleteRuleSetDialog",
  components: { AppDialog },
  props: {
    itemName: {
      type: String,
      required: true
    },
  },
  methods: {
    cancelClick () {
      this.$emit('close')
    },
    yesClick () {
      this.$emit('delete')
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>