<template>
  <div>
    <project-tab-catalogues
      :project-catalogues-prop="projectCatalogues"
      @deleteCatalogues="deleteCatalogues"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'
import ProjectTabCatalogues from '@/components/project/tab/ProjectTabCatalogues.vue'

export default {
  name: "ProjectTabCataloguesContainer",
  components: { ProjectTabCatalogues },

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        projectCatalogues: 'project/getCatalogues'
      }
    )
  },

  methods: {
    deleteCatalogues (data) {
      this.$store.dispatch('project/deleteCataloguesToProject', data)
    }
  }
}
</script>

<style scoped>

</style>
