<template>
  <div>
    <v-divider class="pb-5" />

    <div class="pa-5">
      <v-row class="parameters-table-header text-theme-grey" style="background: #FAFAFA 0 0 no-repeat padding-box;">
        <v-col class="py-2" cols="5">
          Module parameters
        </v-col>

        <v-col class="py-2" cols="5">
          Values
        </v-col>

        <v-col class="py-2" cols="2">
          Active in Rules
        </v-col>
      </v-row>

      <div
        v-for="(singleParameter, index) in parametersProp"
        :key="'param-' + index"
        class="mt-3"
      >
        <v-row class="parameters-header py-1" style="background: #F0F0F0 0 0 no-repeat padding-box;">
          <v-col cols="12">
            <div>{{ index | capitalizeFirstSymbol }}</div>
          </v-col>
        </v-row>

        <v-row
          v-for="(singleRule, indexRule) in singleParameter"
          :key="'rule-' + indexRule"
          class="parameters-value align-center"
        >
          <v-col cols="5">
            {{ singleRule.Name }}
          </v-col>

          <v-divider class="my-4" vertical />

          <v-col cols="5">
            {{ singleRule.ValueAsString }}
          </v-col>

          <v-col cols="2">
            <v-checkbox
              :input-value="singleRule.active_in_rules"
              dense
              disabled
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModuleTabParameters",

  props: {
    parametersProp: {
      type: [Object, Array],
      default: () => []
    }
  },

  filters: {
    capitalizeFirstSymbol (text) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    }
  }
}
</script>

<style scoped>

</style>
