<template>
  <v-expansion-panel
    :class="{ fit, selected }"
    :readonly="loading"
    class="app-expansion-panel"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-expansion-panel-header :class="{ 'text--secondary': loading }">

      <div class="d-flex justify-space-between align-center">
        <slot name="header" />

        <slot name="delete-btn" />
      </div>



      <template #actions>


        <v-btn v-if="loading" icon loading x-small />
        <app-svg
          v-else
          alt="panel icon"
          color="grey darken-2"
          size="16"
          src="~/assets/img/icons/caret-down.svg"
        />

      </template>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <slot />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import AppSvg from '@/components/app/AppSvg'

export default {
  name: 'AppExpansionPanel',
  components: { AppSvg },
  inheritAttrs: false,
  props: {
    selected: Boolean,
    loading: Boolean,
    fit: Boolean
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-expansion-panel {
  border-bottom: 1px solid var(--v-grey-lighten2);

  &:before {
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  // styles
  &.fit {
    .v-expansion-panel-content .v-expansion-panel-content__wrap {
      padding: 10px 0;
    }
  }

  &.selected {
    background: var(--v-grey-lighten2) !important;

    &:hover,
    &:focus-within {
      background: var(--v-grey-lighten2)
    }
  }

  // header
  .v-expansion-panel-header {
    font-family: $body-font-family;
    font-weight: 400;
    transition: background $secondary-transition,
    min-height $secondary-transition;

    .next-list-item {
      padding: 0;
    }

    &:before {
      display: none;
    }

    &:hover {
      background: var(--v-grey-lighten2);
    }

    &:active {
      transition-duration: 0.1s;
    }

    &:active,
    &.v-expansion-panel-header--active {
      background: var(--v-grey-lighten2);
    }
  }

  .v-expansion-panel-header__icon {
    img, .next-svg, .icon {
      transition: transform $secondary-transition;
    }
  }

  .v-expansion-panel-header--active .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) {
    img, .next-svg, .icon {
      transform: rotate(-180deg);
    }
  }

  &.v-expansion-panel--disabled .v-expansion-panel-header {
    pointer-events: auto;

    .v-expansion-panel-header__icon {
      display: none !important;
    }
  }

  // content
  .v-expansion-panel-content {
    color: var(--v-grey-darken1);
    background: var(--v-grey-lighten3);

    .v-expansion-panel-content__wrap {
      & > :first-child {
        margin-top: 0;
      }

      & > :last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
