import { ArcRotateCamera, HemisphericLight, Mesh, MeshBuilder, Scene, Vector3, VertexData } from '@babylonjs/core'

// import 'babylonjs-inspector'
import { v4 as uuidv4 } from 'uuid'

export class BabylonScene {
  scene
  engine
  module
  box
  customMeshGeneratedFromBox

  constructor (canvas, module, engine) {
    this.module = module
    this.engine = engine
    this.scene = this.createScene(canvas)

    this.engine.runRenderLoop(() => {
      this.scene.render()
    })

    // Watch for browser/canvas resize events
    window.addEventListener("resize", function () {
      engine.resize()
    })
  }

  createCustomMesh (positions, indices) {
    let customMesh = new Mesh("custom", this.scene)

    //Create a vertexData object
    let vertexData = new VertexData()

    //Assign positions and indices to vertexData
    vertexData.positions = positions
    vertexData.indices = indices

    //Apply vertexData to custom mesh
    vertexData.applyToMesh(customMesh)

    return customMesh
  }

  createScene (canvas) {
    const scene = new Scene(this.engine)

    const camera = new ArcRotateCamera("camera", 3 * Math.PI / 4, Math.PI / 4, 50, Vector3.Zero(), scene)
    camera.attachControl(canvas, true)
    camera.useFramingBehavior = true

    let maxNumberFromDimensions = Math.max(this.module.height, this.module.width, this.module.depth)
    camera.upperRadiusLimit = (maxNumberFromDimensions + 1) * 5

    new HemisphericLight("light", new Vector3(1, 1, 0), scene)

    // TO DO
    // Generate 2d and make extrusion
    this.box = MeshBuilder.CreateBox("box", {
      height: this.module.height,
      width: this.module.width,
      depth: this.module.depth
    })

    this.customMeshGeneratedFromBox = this.getSixMeshesFromBox(this.box)

    // scene.debugLayer.show({
    //   embedMode: true
    // })
    camera.setTarget(this.box)
    this.box.dispose()

    scene.defaultMaterial.backFaceCulling = false

    return scene
  }

  extractPartialMesh (meshPositions, meshIndices, fromIndex, numberOfIndices) {
    let positions = []
    let indices = []

    for (let i = fromIndex; i < (fromIndex + numberOfIndices); i++) {
      const firstPositionIndex = meshIndices[fromIndex]
      const positionsIndex = meshIndices[i]

      let positionIsInMesh = this.findTriangleInPositions(positions, meshPositions[positionsIndex * 3], meshPositions[positionsIndex * 3 + 1], meshPositions[positionsIndex * 3 + 2])

      if (!positionIsInMesh) {
        positions.push(meshPositions[positionsIndex * 3])
        positions.push(meshPositions[positionsIndex * 3 + 1])
        positions.push(meshPositions[positionsIndex * 3 + 2])
      }

      indices.push(positionsIndex - firstPositionIndex)
    }

    return this.createCustomMesh(positions, indices)
  }

  findTriangleInPositions (positions, position1, position2, position3) {
    for (let i = 0; i < positions.length; i = i + 3) {
      if (positions[i] === position1 &&
        positions[i + 1] === position2 &&
        positions[i + 2] === position3) {
        return true
      }
    }

    return false
  }

  getSixMeshesFromBox (box) {
    let newCreatedMeshes = []
    let indices = box.getIndices()
    let positionData = box.getPositionData()

    let totalMeshesNumber = indices.length / 6
    let step = 6

    for (let i = 0; i < totalMeshesNumber; i++) {
      newCreatedMeshes.push(this.extractPartialMesh(positionData, indices, i * step, step))
    }

    return newCreatedMeshes
  }

  prepareGeneralModuleStructure (generatedMeshes, originRotation) {
    return {
      "origin": {
        "rotation": originRotation
      },
      "boundary": {
        "meshes": generatedMeshes
      },
      "content": []
    }
  }

  prepareDataFromAllMeshes () {
    let output = []

    for (let mesh of this.customMeshGeneratedFromBox) {
      let uuid = uuidv4()

      output.push(
        {
          "id": uuid,
          "name": uuid,
          "positions": mesh.getPositionData(),
          "indices": mesh.getIndices(),
          "normals": {},
          "connectors": {}
        }
      )
    }

    return output
  }

  exportDataToJSONFile (name) {
    let dataInJSON = JSON.stringify(this.getDataInJson())

    let dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataInJSON)
    let exportFileDefaultName = `${ name }-data.json`

    let linkElement = document.createElement('a')
    linkElement.setAttribute('href', dataUri)
    linkElement.setAttribute('download', exportFileDefaultName)
    linkElement.click()
  }

  getDataInJson () {
    let originRotation = this.box.rotation.y
    let generatedMeshes = this.prepareDataFromAllMeshes()
    let dataInJSONFormat = this.prepareGeneralModuleStructure(generatedMeshes, originRotation)

    return dataInJSONFormat
  }
}
