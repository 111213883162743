<template>
  <div>
<!--    Rule Headers-->
      <v-row class="rule-headers py-1 ma-0 px-4" justify="space-between" align-content="center">
        <v-col cols="3" class="pa-0">
          <span class="rule-header-module-parameter">
            Module Parameter
          </span>
        </v-col>

        <v-col cols="3" class="pa-0">
          <span class="rule-header-rule">
            Rule
          </span>
        </v-col>

        <v-col cols="4" class="pa-0" >
          <span class="rule-header-value">
            Value
          </span>
        </v-col>

<!--        This is empty on purpose-->
        <v-col cols="1" class="pa-0" >
          <span class="rule-header-empty">
          </span>
        </v-col>
      </v-row>

<!--    Each Rule-->
      <v-row
          class="single-rule-container ma-0 pa-0"
          justify="space-around"
          v-for="rule in ruleSet.rules" :key="rule.id"
          :class="ruleSet.rules.indexOf(rule) % 2 === 0 ? 'lighten4' : 'white'"
          align-content="center"
        >
          <v-col  md="3" xs="12" class="rule-column pl-1 pr-0 pt-1 pb-2 ma-0">
            <v-tooltip bottom :color="'var(--v-error-base)'" >
              <template v-slot:activator="{ on }">
                <v-select
                  v-on="rule._hasParameterError ? on : {}"
                  :items="ruleParams"
                  :value="ruleParams.find(item => item.name === rule.parameter_name)"
                  class="module-parameter-select"
                  item-text="name"
                  item-value="_entireRuleParam"
                  @change="selectRuleParam($event, rule)"
                  @blur="rule.validateParameterName()"
                  :class="rule._hasParameterError ? 'error-border' : ''"
                  :menu-props="menuProps"
                  placeholder="Module Parameter"
                  rounded
                  dense
                >
                </v-select>
              </template>

              <div class="param-name-error-msg">
                {{ rule._parameterError }}
              </div>

            </v-tooltip>
          </v-col>

          <v-col md="3" xs="12" class="rule-column pl-1 pr-0 pt-1 pb-2 ma-0" :key="rule._rerenderOperator">
            <v-tooltip bottom :color="'var(--v-error-base)'" >
              <template v-slot:activator="{ on }">
                <v-select
                  v-on="rule._hasOperatorError ? on : {}"
                  :items="rule._suitableRules"
                  :value="rule._currentOperator"
                  v-model="rule.operator"
                  item-text="name"
                  placeholder="Rule"
                  class="rule-select"
                  item-value="operator"
                  :class="rule._hasOperatorError ? 'error-border' : ''"
                  @change="selectRule($event, rule)"
                  @blur="rule.validateOperator()"
                  :disabled="!rule.parameter_name"
                  :menu-props="menuProps"
                  dense
                  rounded
                >
                </v-select>
              </template>

              <div>
                {{ rule._operatorError }}
              </div>
            </v-tooltip>
          </v-col>


          <v-col md="4" xs="12" class="rule-column pl-1 pr-0 pt-1 pb-2 ma-0" :key="rule._rerenderValue">
            <v-tooltip bottom :color="rule._hasValueError ? 'var(--v-error-base)' : 'var(--v-primary-base)'" >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  :disabled="rule._disabledValueField"
                  :value="rule.value"
                  class="value-field"
                  placeholder="Value"
                  :class="rule._hasValueError ? 'error-border' : ''"
                  @blur="rule.validateValue()"
                  @input="addRuleValue($event, rule)"
                  dense
                  rounded
                  flat
                >
                </v-text-field>
              </template>
              <div>
                {{rule._hasValueError ? rule._valueError : rule._toolTip  }}
              </div>
            </v-tooltip>
          </v-col>

          <v-col md="1" xs="12" class="rule-column pl-1 pr-0 pt-2 pb-2 ma-0">
              <span
                class="custom-button-action-rule delete-rule-bnt"
                @click="clickDeleteSingleRule(rule)"
              >
                Delete
              </span>
          </v-col>
        </v-row>

<!--    Create New Rule Button-->
        <v-row justify="center" class="ma-0 px-0 py-1" align-content="center">
          <v-col md="3" xs="12" class="ma-0 pa-0">
            <span class="custom-button-action-rule create-rule-btn" @click="createNewRule">Create New Rule</span>
          </v-col>
        </v-row>

        <delete-rule-set-or-single-rule-dialog
          class="delete-rule-dialog"
          v-model="showSingleRuleDeleteDialog"
          :item-name="entityDeleteName"
          @close="singleRuleDeleteDialog=false"
          @delete="deleteItem"
        />

      </div>
</template>

<script>
import DeleteRuleSetOrSingleRuleDialog from '@/components/project/tab/rules/dialogs/DeleteRuleSetOrSingleRuleDialog'

import { ProjectSingleRule } from '@/classes/project/project-rule/ProjectSingleRule'

import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash'

export default {
  name: "ProjectTabRule",
  components: { DeleteRuleSetOrSingleRuleDialog },
  props: {
    ruleSet: {
      type:Object,
      required: true
    }
  },
  data () {
    return {
      singleRuleDeleteDialog: false,
      entityForDelete: {},
      entityDeleteName: '',
      menuProps: {
        bottom: true,
        contentClass: 'sort-by-select-menu-bottom',
        maxWidth: 410
      }
    }
  },

  computed: {
    ruleParams () {
      return this.$store.state.project.ruleParams
    },

    showSingleRuleDeleteDialog: {
      get () {
        return this.singleRuleDeleteDialog
      },
      set (value) {
        this.singleRuleDeleteDialog = value
        if (!value) {
          this.entityForDelete = {}
          this.entityDeleteName = ''
        }
      }
    },

    projectRuleModel () {
      return this.$store.state.project.ruleModel
    }
  },
  methods: {
    clickDeleteSingleRule (rule) {
      this.entityForDelete = cloneDeep(rule)
      this.singleRuleDeleteDialog = true
      this.entityDeleteName =rule._deleteName
    },

    selectRuleParam (entireRuleParamObj, rule) {
      this.$store.dispatch('project/changeRuleOperator', { operator: null, rule })
      this.$store.dispatch('project/addRuleValue', { ruleValue: null, rule })
      this.$store.dispatch('project/changeRuleName', { entireRuleParamObj, rule })
      rule.validateParameterName()
    },
    selectRule (operator, rule) {
      this.$store.dispatch('project/changeRuleOperator', { operator, rule })
      rule.validateOperator()
    },

    addRuleValue (ruleValue, rule) {
      this.$store.dispatch('project/addRuleValue', { ruleValue, rule })
      rule.validateValue()
    },

    async createNewRule () {
      await this.$store.dispatch('project/addNewRule', {
        ruleSet: this.ruleSet,
        rule: new ProjectSingleRule ({
          id: uuidv4(),
          parameter_name: null,
          operator: null,
          value: null
        })
      })
    },

    async deleteItem () {
      const payload = this.entityForDelete.getPayloadDeleteRule(this.$store.getters['project/getRuleModel'])
      await this.$store.dispatch('project/deleteRule', payload)
    },
  }

}
</script>

<style lang="scss">
  .single-rule-container {
    &.white {
      background-color: var(--v-white-base) !important;
    }
    &.lighten4 {
      background: var(--v-grey-lighten4) !important;
    }

    .rule-column {

      .v-input {
        height: 30px;
        border: 1px solid var(--v-grey-lighten1);
        background-color: white;

        &.error-border:not(.v-input--is-disabled) {
          border-color: var(--v-error-base);
        }
        .v-input__control {
          height: 30px;
          font-size: 14px;

          .v-input__slot .v-text-field__slot {
            input {
              font-size: 14px !important;
            }
          }

          .v-select__slot .v-select__selections {
            font-size: 14px !important;
          }


          .v-text-field__details {
            position: absolute;
            font-size: 0.5rem !important;
            padding-left: 22px !important;
            padding-top: 2px !important;
          }
        }
      }

      .v-input:not(.v-input--is-disabled, .error-border) {
        &:hover  {
          border-color: var(--v-primary-base);
        }
      }

      .v-select.v-input--is-disabled:not(.v-input--is-readonly):not(.v-autocomplete) {
        pointer-events: auto !important;
      }
    }
  }

  .rule-headers {
    font-size: 0.75rem;
  }

  .custom-button-action-rule {
    color: var(--v-grey-darken2);
    border-bottom: 1px solid;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: var(--v-primary-base)
    }
  }

</style>