<template>
  <div>
    <h1 class="mb-10">
      Modules
    </h1>

    <app-select-sort-filter-search-header
      :items-prop="modules"
      :sort-options-prop="sortOptions"
      :is-loading-prop="isDeleteDialogLoading"
      @selectAllItems="selectAll"
      @makeSearch="makeSearch"
      @changeSort="makeSort"
      @deleteItems="deleteItems"
      ref="appSelectSortFilterSearch"
    />

    <v-divider class="mt-1 mb-8" />

    <list-overview
      :can-create=true
      :data-source.sync="modules"
      :is-list-loading-prop="currentState === actionStateConstants.LOADING"
      :selectable="true"
      list-type="module"
      target-url="/modules"
    >
      <template #create-card>
        <list-item-create-card
          action="Create"
          entity-type="module"
          @click="openCreateModuleDialog"

        />
      </template>

      <template #entity-card="{ entity, selectable, target }">
        <list-item-common
          :entity="entity"
          :selectable="selectable"
          :target="target"
        >
          <template #under-the-name>
            <span class="text-small grey--text">Single module</span>
          </template>
        </list-item-common>
      </template>
    </list-overview>

    <module-dialog-create
      v-if="isDialogVisible"
      :key="createModuleDialogKey + '-module-dialog'"
      v-model="isDialogVisible"
      :is-loading-prop="isDialogLoading"
      :is-name-already-exist-prop.sync="isNameAlreadyExistOnCreate"
      :title-prop="'Create New Module'"
      :validation-error-message-prop.sync="validationErrorMessage"
      @createModule="createModule"
    />

    <canvas class="d-none" id="renderCanvasHidden" touch-action="none"></canvas>
  </div>

</template>

<script>

import ModuleDialogCreate from '@/components/module/ModuleDialogCreate'
import ListOverview from '@/components/list/ListOverview'
import ListItemCommon from '@/components/list/ListItemCommon'
import ModuleService from '@/services/module.service'
import ListItemCreateCard from '@/components/list/ListItemCreateCard'
import AppSelectSortFilterSearchHeader from '@/components/app/header/AppSelectSortFilterSearchHeader'

import { actionStates } from '@/constants'
import { mapGetters } from "vuex"

import { Engine } from '@babylonjs/core'
import { BabylonScene } from '@/babylon/BabylonScene'

import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from "uuid"

export default {
  name: 'ModuleAllView',
  components: {
    ModuleDialogCreate,
    ListOverview,
    ListItemCreateCard,
    ListItemCommon,
    AppSelectSortFilterSearchHeader
  },

  data () {
    return {
      actionStateConstants: actionStates,
      isDialogVisible: false,
      createModuleDialogKey: 0,
      isDialogLoading: false,
      isDeleteDialogLoading: false,
      title: 'Modules',
      isNameAlreadyExistOnCreate: false,
      validationErrorMessage: '',
      bimScene: null,
      originalModules: [],
      sortOptions: [
        { text: 'Name - [A-Z]', key: 'name', direction: 'acs', id: uuidv4() },
        { text: 'Name - [Z-A]', key: 'name', direction: 'desc', id: uuidv4() },

        { text: 'Date Created - [A-Z]', key: 'dateCreated', direction: 'acs', id: uuidv4() },
        { text: 'Date Created - [Z-A]', key: 'dateCreated', direction: 'desc', id: uuidv4() },

        { text: 'Date Updated - [A-Z]', key: 'updatedAt', direction: 'acs',  id: uuidv4() },
        { text: 'Date Updated - [Z-A]', key: 'updatedAt', direction: 'desc',  id: uuidv4() },
      ],

      sortStrategy: [
        { key: 'name', direction: 'acs', sortFunc: (items) => {
            return items.sort((a, b) => a.name.localeCompare(b.name))
          }
        },

        { key: 'name', direction: 'desc', sortFunc: (items) => {
            return items.sort((a, b) => b.name.localeCompare(a.name))
          }
        },

        { key: 'dateCreated', direction: 'acs', sortFunc: (items) => {
            return items.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
          }
        },

        { key: 'dateCreated', direction: 'desc', sortFunc: (items) => {
            return items.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          }
        },

        { key: 'updatedAt', direction: 'acs', sortFunc: (items) => {
            return items.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
          }
        },

        { key: 'updatedAt', direction: 'desc', sortFunc: (items) => {
            return items.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
          }
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      currentState: 'module/getCurrentState',
      modules: 'module/getModules'
    })
  },

  methods: {
    async getAllModules () {
      await this.$store.dispatch('module/fetchModules')
    },

    async selectAll (value) {
      for (const module of this.modules) {
        await this.$store.dispatch('module/makeModuleSelectedUnselected', {
          item: module,
          selected: value
        })
      }
    },

    openCreateModuleDialog () {
      this.createModuleDialogKey++
      this.isDialogVisible = true
    },

    closeCreateModuleDialog () {
      this.isDialogVisible = false
    },

    makeSort (sortParam) {
      const currentSortStrategy = this.sortStrategy.find(item => item.key === sortParam.key && item.direction === sortParam.direction)
      if (currentSortStrategy) {
        currentSortStrategy.sortFunc(this.modules)
      }
    },

    async makeSearch (searchInput) {
      if (searchInput && searchInput.length >= 3) {
        const foundItems =  this.$search({
          items: this.modules,
          searchKey: searchInput,
        })
        await this.$store.dispatch('module/updateModules', foundItems)
      } else {
        await this.$store.dispatch('module/updateModules', this.originalModules)
      }
    },

    async generateJsonStructureForTheModule (module) {
      const canvas = await document.getElementById("renderCanvasHidden")
      let engine = await new Engine(canvas, true, {
        preserveDrawingBuffer: true,
        stencil: true,
        disableWebGL2Support: false
      })
      this.bimScene = await new BabylonScene(canvas, module, engine)

      return JSON.stringify(this.bimScene.getDataInJson())
    },

    async createModule (module) {
      this.isDialogLoading = true

      const moduleDataInJsonFormat = await this.generateJsonStructureForTheModule(module)

      try {
        await ModuleService.createModule({...module, json_structure: moduleDataInJsonFormat })
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Successfully created module',
          duration: 4000,
          mode: 'success'
        })
        this.closeCreateModuleDialog()
        await this.$router.push(`/modules/${ module.id }`)
      } catch (error) {
        if (error.response.status === 422) {
          this.isNameAlreadyExistOnCreate = true
          this.validationErrorMessage = error.response.data.errors.name[0]
        }
      } finally {
        this.isDialogLoading = false
      }
    },

    async deleteItems () {
      this.isDeleteDialogLoading = true

      try {
        const itemsForDelete = this.modules.filter(item => item._selected)
        await ModuleService.deleteModule({
          ids: itemsForDelete.map(item => item.id)
        })

        this.isDeleteDialogLoading = false
        this.$refs.appSelectSortFilterSearch.closeDeleteDialog()

      } catch (e) {
        this.$refs.appSelectSortFilterSearch.closeDeleteDialog()
        this.isDeleteDialogLoading = false
      }
      finally {
        await this.getAllModules()
      }
    }
  },

  async mounted () {
    await this.getAllModules()
    this.originalModules = cloneDeep(this.modules)
  }
}
</script>
