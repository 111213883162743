<template>
  <app-dialog
    :value="isDialogVisible"
    title-prop="Add to Project"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <app-loader-listing
        v-if="isLoading"
      />

      <div v-else-if="cataloguesList.length <= 0">
        <span> No available catalogues </span>
      </div>

      <div v-else-if="cataloguesList.length > 0">
        <div
          v-for="(catalogue, index) in cataloguesList"
          :key="`catalogue-${catalogue.id}-${index}`"
          class="px-2"
        >
          <app-list-item
            :selected="selectedItems.includes(catalogue)"
            hover
            @update:selected="onItemToggle($event, catalogue)"
            @click="onCatalogClick(catalogue)"
          >
            <v-avatar class="image-border" rounded size="60">
              <v-img
                :alt="`${catalogue.name} image`"
                :src="catalogue.image && catalogue.image.url"
              />
            </v-avatar>

            <template #title>
              <span>{{ catalogue.name }}</span>
            </template>
          </app-list-item>
        </div>
      </div>
    </div>

    <template #actions>
      <v-spacer />
      <v-btn
        :disabled="hasAtLeastOneSelected === false"
        color="primary"
        min-width="200"
        rounded
        x-large
        @click="saveCatalogues"
      >
        Add
      </v-btn>
    </template>
  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'
import { cloneDeep } from 'lodash'
import ProjectService from '@/services/project.service'
import AppListItem from '@/components/app/AppListItem.vue'
import AppLoaderListing from '@/components/app/AppLoaderListing.vue'

export default {
  name: 'ProjectDialogAddCatalogues',
  components: {
    AppLoaderListing,
    AppListItem,
    AppDialog
  },

  props: {
    value: {
      default: true
    }
  },

  data () {
    return {
      isSaveBtnDisabled: true,
      isLoading: true,

      originalCataloguesList: [],
      cataloguesList: [],
      selectedItems: []
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', !!value)
      }
    },

    hasAtLeastOneSelected () {
      return this.selectedItems && this.selectedItems.length > 0
    },

    selectedIdes () {
      return this.selectedItems.map(catalogues => catalogues.id)
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

    async getProjectCatalogues () {
      try {
        let result = await ProjectService.getProjectAvailableCatalogues(this.$route.params.id)
        this.originalCataloguesList = cloneDeep(result)
        this.cataloguesList = result

        this.isLoading = false
      } catch (e) {
        this.isLoading = false
      }
    },

    async saveCatalogues () {
      await this.$store.dispatch('project/assignCataloguesToProject', {
        project_id: this.$route.params.id,
        catalogue_ids: this.selectedIdes
      })
      this.$emit('removeSelectedCatalogues')
      this.hideDialog()
    },

    // Selections
    areAllItemsSelected (listItems) {
      if (!listItems.length) {
        return false
      }

      return listItems.every(item => this.selectedItems.includes(item))
    },

    selectItem (item) {
      if (!this.selectedItems.includes(item)) {
        this.selectedItems.push(item)
      }
    },

    bulkSelectItems (items) {
      items = items || []

      for (const item of items) {
        this.selectItem(item)
      }
    },

    deselectItem (item) {
      this.selectedItems = this.selectedItems.filter((oneSelectedItem) => oneSelectedItem.id !== item.id)
    },

    onCatalogClick (item) {
      if (this.selectedItems.includes(item)) {
        this.onItemToggle(false, item)
      } else {
        this.onItemToggle(true, item)
      }
    },

    onItemToggle (state, item) {
      if (state) {
        this.selectItem(item)
        return
      }

      this.deselectItem(item)
    },

    bulkDeselectItems (items) {
      items = items || []

      for (let item of items) {
        this.deselectItem(item)
      }
    },

    deselectAllItems () {
      this.selectedItems = []
    },

    onBulkToggle (state, items) {
      if (state) {
        this.bulkSelectItems(items)
        return
      }

      this.bulkDeselectItems(items)
    }

  },

  created () {
    this.getProjectCatalogues()
  }
}
</script>

<style lang="scss">
.category-panels-drop-wrapper {
  width: 100%;
}

.uncategorized-list {
  position: sticky;
  top: 0;
  display: block;
  width: 100%;
  margin: 0 0 30px;
  border: 1px solid var(--v-grey-lighten2);
  border-radius: $border-radius-root;

  .list-heading {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom: 1px solid var(--v-grey-lighten2);
    background: #c3c3c31a;

    .app-list-item {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .list-body {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;

    & > :last-child {
      border-radius: inherit;
    }
  }
}

//category-list-items
.category-expansion-panel {
  .v-expansion-panel-header {
    //min-height: 64px;
    max-height: 64px;
    padding-left: 0;
  }

  .category-actions {
    opacity: 0;
    flex-wrap: unset;
    flex-shrink: 0;
    margin-top: -5px;
    margin-bottom: -5px;
    visibility: hidden;
    transition: opacity $secondary-transition,
    visibility $secondary-transition;
  }

  .category-form {
    display: flex;
    align-items: center;
    color: var(--v-grey-darken2);
    width: 100%;
    margin-top: -4px;
    margin-bottom: -4px;
    padding-left: 20px;

    & > * {
      margin-right: 30px;

      &:last-child {
        margin-right: 20px;
      }
    }

    .v-input {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    .form-actions {
      margin-left: auto
    }
  }

  .v-expansion-panel-header:hover .category-actions {
    opacity: 1;
    visibility: visible;
  }
}

.dnd-drop.drop-in .v-expansion-panel-header:hover .category-actions {
  opacity: 0;
  visibility: hidden;
}
</style>
