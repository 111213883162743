<template>
  <div class="flex-grow-1 ma-4">
    <div class="tabs-content-section pa-5">
      <div
        class="guttered-row dense"
        v-bind="$attrs"
        v-on="$listeners"
      >
      </div>

      <div>
        <div
          class="edit-modules-categories d-flex align-center pa-5 my-5"
          @click="showEditModulesAndCategoriesDialog"
        >
          <v-avatar size="60">
            <div class="icon">
              <app-svg
                alt="icon"
                color="white"
                size="32"
                src="~/assets/img/icons/edit.svg"
              />
            </div>
          </v-avatar>

          <div class="section-title ml-5">
            Edit modules and categories
          </div>
        </div>
      </div>

      <catalog-categories-overview />
    </div>

    <catalog-dialog-modules-and-categories-edit
      v-if="isEditModulesAndCategoriesDialogVisible"
      :key="editModulesAndCategoriesDialogKey"
      v-model="isEditModulesAndCategoriesDialogVisible"
    />
  </div>
</template>

<script>
import { actionStates } from '@/store/modules/catalog'
import AppSvg from '@/components/app/AppSvg'
import CatalogDialogModulesAndCategoriesEdit from '@/components/catalog/CatalogDialogModulesAndCategoriesEdit'
import CatalogCategoriesOverview from '@/components/catalog/CatalogCategoriesOverview'

export default {
  name: "CatalogEditRightSidePanelContainer",
  components: {
    CatalogDialogModulesAndCategoriesEdit,
    AppSvg,
    CatalogCategoriesOverview
  },

  data () {
    return {
      isEditModulesAndCategoriesDialogVisible: false,
      editModulesAndCategoriesDialogKey: 0,

      actionStateConstants: actionStates
    }
  },

  methods: {
    showEditModulesAndCategoriesDialog () {
      this.isEditModulesAndCategoriesDialogVisible = true
      this.editModulesAndCategoriesDialogKey++
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.edit-modules-categories {
  cursor: pointer;
  user-select: none;
  color: var(--v-grey-base);
  border: 1px solid var(--v-grey-lighten2);
  border-radius: $border-radius-root;
  transition: background $secondary-transition,
  border $secondary-transition,
  color $secondary-transition;

  .v-avatar {
    background: var(--v-grey-lighten1);
    transition: background $secondary-transition;
  }

  &:hover {
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);

    .v-avatar {
      background: var(--v-primary-base);
    }
  }
}
</style>
