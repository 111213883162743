<template>
  <div class="drag-card">
    <div class="drag-card-content">
      Moving
      <strong>{{ count }}</strong>
      {{ count === 1 ? 'item' : 'items' }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppDndDragCard',
  props: {
    count: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.drag-card {
  display: flex;
  align-items: center;
  width: 250px;
  max-width: 100%;
  min-height: 40px;
  padding: 10px 15px;
  border-radius: $border-radius-root;
  background: var(--v-primary-base);
  color: #fff;
  font-family: $body-font-family;
  opacity: 1 !important;
}

.drag-card-content {
  display: block;
  width: 100%;
  flex-grow: 1;
}
</style>
