<template>
  <div>

    <div v-if="backgroundModelsProp" >
      <div
          v-for="(singleBackgroundModel, index) in backgroundModelsProp"
          :key="`background-model-${singleBackgroundModel.id}-${index}`"
          class="background-models-container"
      >
        <div class="pa-5 d-flex justify-lg-space-between">
          <div class="app-list-item hover">
            <div class="d-flex align-center">
              <v-img
                  :alt="`${singleBackgroundModel.name} picture`"
                  :src="singleBackgroundModel.image && singleBackgroundModel.image.url"
                  class="image-border mr-sm-8 background-image-container"
                  height="140px"
                  style="border-radius: 6px; flex: 0 0 auto;"
                  width="200px"
              />

              <div class="content-section content-title">
                <div class="d-flex flex-column text-subtitle-1 grey--text text--darken-2">
                 <span v-if="singleBackgroundModel.name" class="list-item-name export-name-text">
                    <b> {{ singleBackgroundModel.name }} </b>
                  </span>

                  <div v-if="singleBackgroundModel.created_at" class="list-item-name">
                    <span> <b>Last Exported </b> </span> <br>
                    <span class="exported-on-text"> {{ singleBackgroundModel.created_at }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex">
            <v-spacer />
            <v-btn
                class="primary--text"
                height="30px"
                rounded
                text
                @click="removeBtnClick(singleBackgroundModel)"
            >
              <div class="guttered-row">
                <app-svg
                    class="mr-2"
                    color="primary"
                    src="@/assets/img/icons/delete.svg"
                    width="16"
                />
                Remove
              </div>
            </v-btn>
          </div>
        </div>

        <v-divider />
      </div>
    </div>



    <div v-else>
      <div
          class="btn-main-large-full-width d-flex align-center pa-5 my-5"
          @click="addNewBackgroundClick"
      >
        <v-avatar size="60">
          <div class="icon">
            <app-svg
                alt="icon"
                color="white"
                size="32"
                src="~/assets/img/icons/plus.svg"
            />
          </div>
        </v-avatar>

        <div class="section-title ml-5">
          Add new Background
        </div>
      </div>
    </div>

    <project-tab-background-dialog-add-background
        v-if="isAddNewBackgroundDialogVisible"
        v-model="isAddNewBackgroundDialogVisible"
        :key="keyAddNewBackgroundDialogVisible"
        @fetchProject="fetchProject"
    />

    <project-tab-background-dialog-remove-background
        v-if="isRemoveBackgroundDialogVisible"
        v-model="isRemoveBackgroundDialogVisible"
        :key="keyRemoveBackgroundDialog"
        @fetchProject="fetchProject"
        :project-background-prop="currentBackground"
    />

  </div>
</template>

<script>
import AppSvg from '@/components/app/AppSvg.vue'
import ProjectTabBackgroundDialogAddBackground
  from '@/components/project/tab/dialog/ProjectTabBackgroundDialogAddBackground.vue'
import ProjectTabBackgroundDialogRemoveBackground
  from '@/components/project/tab/dialog/ProjectTabBackgroundDialogRemoveBackground.vue'

import { v4 as uuidv4 } from 'uuid'

export default {
  name: "ProjectTabModels",
  components: {
    AppSvg,
    ProjectTabBackgroundDialogAddBackground,
    ProjectTabBackgroundDialogRemoveBackground
  },

  data () {
    return {
      isAddNewBackgroundDialogVisible: false,
      keyAddNewBackgroundDialogVisible: uuidv4(),

      isRemoveBackgroundDialogVisible: false,
      keyRemoveBackgroundDialog: uuidv4(),

      currentBackground: null,
    }
  },

  props: {
    backgroundModelsProp: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    addNewBackgroundClick () {
      this.isAddNewBackgroundDialogVisible = true
      this.keyAddNewBackgroundDialogVisible = uuidv4()
    },

    removeBtnClick (background) {
      this.currentBackground = background
      this.isRemoveBackgroundDialogVisible = true
      this.keyRemoveBackgroundDialog = uuidv4()
    },

    async fetchProject () {
      await this.$store.dispatch('project/fetchProject', this.$route.params.id)
    }
  },

  destroyed () {
    this.currentBackground = null
  }
}
</script>

<style lang="scss" scoped>

@media (max-width: 600px) {
  .d-flex {
    flex-direction: column;
  }
}

</style>
