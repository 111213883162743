<template>
  <div class="exported-view-container">
    <div class="px-5 py-2">
      <div class="app-list-item hover">
        <div class="d-flex align-start">
          <v-img
            ref="ruleModel"
            :alt="`${ruleModel.name} image`"
            :src="ruleModel.image.url"
            id="rule-model-main-img"
            class="image-border mr-sm-8 background-image-container"
            width="370px"
            height="165px"
            style="border-radius: 6px; flex: 0 0 auto;"
          />

          <div class="" style=" width: 100% !important;">
            <div class="d-flex flex-column text-subtitle-1 grey--text text--darken-2">
               <span class="list-item-name export-name-text">
                  <b>View Name</b>
                </span>
                <span id="rule-model-name">
                  {{ ruleModel.name }}
                </span>

              <div  class="list-item-name">
                <span> <b>Last Exported </b> </span> <br>
                <span id="rule-model-exported-at" class="exported-on-text">{{ ruleModel.created_at }}</span>
              </div>
            </div>

            <div class="d-flex flex-row  justify-end">
              <v-btn
                x-small
                rounded
                outlined
                raised
                color="primary"
                class="rule-save-btn"
                id="save-rule-model-btn"
                :disabled="ruleModel._hasAnyErrors"
                :loading="loading"
                @click="clickSaveChanges"
              >
                Save Changes
              </v-btn>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ProjectService from '@/services/project.service'

export default {
  name: "ProjectTabRuleExportedView",
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ruleModel () {
      return this.$store.state.project.ruleModel
    },
  },
  methods: {
    async clickSaveChanges () {
      this.ruleModel.validate()
      if (this.ruleModel._hasAnyErrors) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'You have validation Error!',
          duration: 4000,
          mode: 'fail'
        })
        return
      }

      try {
        this.loading = true
        await ProjectService.updateProjectRuleModel({
          data: this.ruleModel._payload,
          ruleModelId: this.ruleModel.id
        })
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Successfully updated project rule models',
          duration: 4000,
          mode: 'success'
        })
      } catch (error) {
        await this.$router.replace('/dashboard')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .d-flex {
    flex-direction: column;
  }
}


</style>