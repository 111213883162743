<template>
  <div class="header-sort-filter-search-wrapper">

    <app-data-select-all
      @selectAllItems="(value) => $emit('selectAllItems', value)"
      :items-prop="itemsProp"
    />

    <app-data-sort-by
        :sort-options-prop="sortOptionsProp"
        @changeSort="(selectedOption) => $emit('changeSort', selectedOption)"
    />

    <app-data-search-bar
        @makeSearch="(searchInput) => $emit('makeSearch', searchInput)"
    />

    <div class="d-flex justify-end" style="position: absolute; right: 30px;">
      <v-btn
        class="primary--text"
        height="30px"
        rounded
        text
        :disabled="disabled"
        @click="showDeleteDialog"
      >
        <div class="guttered-row">
          <app-svg
            class="mr-2"
            :color="disabled ? 'var(--v-grey-lighten1)' : 'primary'"
            src="@/assets/img/icons/delete.svg"
            width="16"
          />
          Remove
        </div>
      </v-btn>
    </div>


    <app-dialog-multi-delete
      v-if="isDeleteDialogVisible"
      :key="deleteDialogKey"
      v-model="isDeleteDialogVisible"
      :item-names-prop="itemsProp.filter(item => item._selected).map(item => item.name)"
      @deleteItems="deleteItems"
      :is-loading-prop="isLoadingProp"
    />

  </div>
</template>

<script>
import AppDataSelectAll from '@/components/app/data/AppDataSelectAll'
import AppSvg from '@/components/app/AppSvg'
import AppDialogMultiDelete from "@/components/app/AppDialogMultiDelete.vue"
import AppDataSearchBar from "@/components/app/data/AppDataSearchBar.vue"
import AppDataSortBy from "@/components/app/data/AppDataSortBy.vue"


import { v4 as uuidv4 } from 'uuid'
export default {
  name: "AppSelectSortFilterSearchHeader",
  components: {
    AppDataSelectAll,
    AppSvg,
    AppDialogMultiDelete,
    AppDataSearchBar,
    AppDataSortBy
  },

  props: {
    itemsProp: {
      type: Array,
      default: () => {
        return []
      }
    },

    isLoadingProp: {
      type: Boolean,
      default: false,
      required: true
    },

    sortOptionsProp: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data () {
    return {
      isDeleteDialogVisible: false,
      deleteDialogKey: uuidv4()
    }
  },

  computed: {
    disabled () {
      if (this.itemsProp) {
        return !this.itemsProp.filter(item => item._selected).length > 0
      }
      return false
    }
  },

  methods: {
    showDeleteDialog () {
      this.isDeleteDialogVisible = true
      this.deleteDialogKey = uuidv4()
    },

    closeDeleteDialog () {
      this.isDeleteDialogVisible = false
      this.deleteDialogKey = uuidv4()
    },

    deleteItems () {
      this.$emit('deleteItems')
    }
  }
}
</script>

<style scoped>

</style>