<template>
  <div>
    <v-expansion-panels
      v-for="(configuration, index) in configurationsProp"
      :key="`configuration-${configuration.id}-${index}`"
      accordion
      flat
      focusable
      hover
      multiple
      tile
    >
      <app-expansion-panel fit :class="configuration.deleted_at ? 'color-disabled' : ''">
        <template #header>
          <h5 class="text-h5 py-0">
            {{ configuration.name }}
          </h5>
        </template>

        <template #delete-btn>
          <div @click.prevent.stop="showDeleteConfDialog(configuration)">
            <v-hover>
              <template v-slot="{ hover }">
                <app-svg
                    class="mr-6 delete-rule-set-btn"
                    :color="hover ? 'primary' : 'var(--v-grey-darken2)'"
                    src="@/assets/img/icons/delete.svg"
                    size="20"
                />
              </template>
            </v-hover>
          </div>

        </template>

        <div class="pa-5" :class="configuration.deleted_at ? 'color-disabled' : ''">
          <div class="app-list-item hover">
            <div class="d-flex align-center">
              <v-img
                :alt="`${configuration.name} picture`"
                :src="configuration.image && configuration.image.url"
                class="image-border mr-8"
                height="140px"
                style="border-radius: 6px; flex: 0 0 auto;"
                width="200px"
              />

              <div class="content-section content-title">
                <div class="d-flex flex-column text-subtitle-2" style="gap: 5px;">
                  <span class="list-item-name">
                    <b> Created by: </b>
                    {{ configuration.created_by_user.name }} / {{ configuration.created_by_user.email }}
                  </span>

                  <span class="list-item-name">
                    <b> Date Created: </b> {{ configuration.created_at }}
                  </span>

                  <span class="list-item-name">
                    <b> Last Updated: </b> {{ configuration.updated_at }}
                  </span>

                  <span class="list-item-name">
                    <b> Number of Modules: </b>
                    <template v-if="configuration.latest_save && configuration.latest_save.modules > 0">
                      {{ configuration.latest_save.modules }}
                    </template>

                    <template v-else-if="!(configuration.latest_save && configuration.latest_save.modules > 0)">
                      0
                    </template>
                  </span>
                </div>
              </div>
            </div>

            <div class="list-item-actions mt-9">
              <v-btn
                height="30px"
                rounded
                small
                text
                :disabled="!!configuration.deleted_at"
              >
                <div class="guttered-row">
                  <img
                    alt="download-configuration-summary-icon"
                    class="mr-2"
                    src="@/assets/img/icons/icon-download-line.png"
                    width="16"
                  />
                  <span>Summary</span>
                </div>

              </v-btn>

<!--              <v-btn-->
<!--                v-if="configuration.latest_save"-->
<!--                class="ml-2 get-configuration-button"-->
<!--                height="30px"-->
<!--                rounded-->
<!--                small-->
<!--                text-->
<!--                @click="getConfigurationSaveDbaTextFile(configuration.latest_save.id)"-->
<!--                :disabled="!!configuration.deleted_at"-->
<!--              >-->
<!--                Request from Dido, this is only DBA feature-->
<!--                <div class="guttered-row">-->
<!--                  <img-->
<!--                    alt="download-configuration-txt-icon"-->
<!--                    class="mr-2"-->
<!--                    src="@/assets/img/icons/icon-download-line.png"-->
<!--                    width="16"-->
<!--                  />-->
<!--                  <span>Download (Text)</span>-->
<!--                </div>-->
<!--              </v-btn>-->

              <v-btn
                height="30px"
                rounded
                small
                text
                style="position: absolute;  right: 10px"
                :class="!!configuration.deleted_at ? 'color-disabled-bnt' : ''"
                class="disable-enable-conf-btn"
                @click="clickDisableEnableConf(configuration)"
                :loading="configuration._loading"
              >
                <div class="guttered-row">
                  <img
                    v-if="configuration.deleted_at"
                    alt="disable-enable-configuration-summary-icon"
                    class="mr-2 eye-icon"
                    src="@/assets/img/icons/eye.png"
                    width="16"
                  />
                  <img
                    v-else
                    alt="download-configuration-summary-icon"
                    class="mr-2 crossed-eye-icon"
                    src="@/assets/img/icons/eye-disabled.png"
                    width="16"
                  />
                  <span>{{ configuration.deleted_at ? 'Enable Configuration': 'Disable Configuration'  }}</span>
                </div>
              </v-btn>

            </div>

          </div>
        </div>
      </app-expansion-panel>
    </v-expansion-panels>

    <project-tab-delete-configuration-dialog
        v-if="isDeleteConfDialogVisible"
        v-model="isDeleteConfDialogVisible"
        :key="keyDeleteConfDialog"
        :configuration-prop="currentConf"
    />

  </div>
</template>

<script>
import AppExpansionPanel from '@/components/app/AppExpansionPanel'
import ConfigurationService from '@/services/configuration.service'
import ProjectService from '@/services/project.service'
import AppSvg from '@/components/app/AppSvg.vue'
import ProjectTabDeleteConfigurationDialog
  from '@/components/project/tab/dialog/ProjectTabDeleteConfigurationDialog.vue'

import { v4 as uuidv4 } from 'uuid'

export default {
  name: "ProjectTabConfigurationsList",
  components: {ProjectTabDeleteConfigurationDialog, AppExpansionPanel, AppSvg },

  props: {
    configurationsProp: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      isDeleteConfDialogVisible: false,
      keyDeleteConfDialog: uuidv4(),
      currentConf: null
    }
  },

  methods: {
    async getConfigurationSaveDbaTextFile (configurationSaveId) {
      try {
        let result = await ConfigurationService.getConfigurationSaveDbaTextFile(configurationSaveId)

        let dataUri = 'data:text/plain;charset=utf-8,' + encodeURIComponent(result)
        let exportFileDefaultName = `configuration-data.txt`

        let linkElement = document.createElement('a')
        linkElement.setAttribute('href', dataUri)
        linkElement.setAttribute('download', exportFileDefaultName)
        linkElement.click()

        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Successfully download the file',
          duration: 4000,
          mode: 'success'
        })
      } catch {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Failed to download the file',
          duration: 4000,
          mode: 'fail'
        })
      }
    },

    showDeleteConfDialog (conf) {
      this.currentConf = conf
      this.isDeleteConfDialogVisible = true
      this.keyDeleteConfDialog = uuidv4()
    },

    async clickDisableEnableConf (configuration) {

      try {
        await this.$store.dispatch('project/changeConfigurationLoading',
          { conf: configuration, value: true })
        if (configuration.deleted_at) {
          await ProjectService.restoreConfiguration(configuration.id)
        } else {
          await ProjectService.deleteConfiguration(configuration.id)
        }

        await this.$store.dispatch('project/fetchProjectConfigurations', this.$route.params.id)

      } catch (e) {
        await this.$store.commit('project/setActionState', 'ERRORED')
        await this.$router.replace('/dashboard')
      } finally {
        await this.$store.dispatch('project/changeConfigurationLoading',
          { conf: configuration, value: false })
      }
    }
  },

  destroyed () {
    this.currentConf = null
  }
}
</script>

<style lang="scss">
.color-disabled {
  background-color:  rgba(240, 240, 240, 0.2) !important;
  backdrop-filter: blur(10px) !important;
  -webkit-backdrop-filter: blur(10px) !important; /* for old Safari */
  opacity: 0.6 !important;
  .color-disabled-bnt {
    color: black !important;
  }
}


</style>
