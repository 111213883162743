<template>
  <div class="heading-wrapper">
    <v-layout>
      <h2>{{ title }}</h2>

      <v-spacer />

      <app-button-back
        v-if="backLink.length"
        :to="backLink"
      />
    </v-layout>
  </div>
</template>

<script>
import AppButtonBack from '@/components/app/AppButtonBack'

export default {
  name: 'AppSubpageHeader',
  components: { AppButtonBack },

  props: {
    title: {
      type: String,
      required: true
    },
    backLink: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
