<template>
  <div>
    <app-subpage-header
      back-link="/modules"
      title="Module"
    />

    <app-loader-content
      v-if="currentState === actionStateConstants.LOADING"
    />

    <module-single-overview
      v-else-if="currentState !== actionStateConstants.LOADING"
    />
  </div>
</template>

<script>
import AppSubpageHeader from '@/components/app/AppSubpageHeader'
import { mapGetters } from 'vuex'
import AppLoaderContent from '@/components/app/AppLoaderContent'
import { actionStates } from '@/store/modules/module'
import ModuleSingleOverview from '@/components/module/ModuleSingleOverview'

export default {
  name: "ModuleSingleView",
  components: {
    ModuleSingleOverview,
    AppLoaderContent,
    AppSubpageHeader
  },

  props: {
    isLoadingProp: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        currentState: 'module/getCurrentState'
      }
    )
  },

  methods: {
    async getModuleInfo () {
      try {
        await this.$store.dispatch('module/fetchModule', this.$route.params.id)
      } catch (error) {
        await this.$router.replace('/modules')
      }
    }
  },

  mounted () {
    this.getModuleInfo()
  }
}
</script>

<style scoped>

</style>
