<template>
  <v-app-bar app clipped-left>
    <v-img
      id="home-logo-button"
      :aspect-ratio="3.25"
      :src="getLogoImage()"
      contain
      max-height="35"
      max-width="140"
      style="cursor:pointer;"
      v-bind="$attrs"
      v-on="$listeners"
      @click.stop="redirectToHomePage"
    />

    <v-spacer />

    <div class="d-flex align-center">
      <v-tooltip
        class="text-center"
        color="primary"
        left
      >
        <span>This functionality is currently unavailable.</span>

        <template #activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
          >
<!--            <v-btn-->
<!--              class="mx-2"-->
<!--              color="primary"-->
<!--              icon-->
<!--            >-->
<!--              <app-svg-->
<!--                size="24"-->
<!--                src="~/assets/img/icons/search.svg"-->
<!--              />-->
<!--            </v-btn>-->

<!--            <v-btn-->
<!--              class="mx-2"-->
<!--              color="primary"-->
<!--              icon-->
<!--            >-->
<!--              <app-svg-->
<!--                size="24"-->
<!--                src="~/assets/img/icons/notification.svg"-->
<!--              />-->
<!--            </v-btn>-->

          </div>
        </template>
      </v-tooltip>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-hover>
            <template v-slot="{ hover }" >
              <v-btn
                  :class="hover ? 'background-primary-lighten3' : '' "
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  rounded
                  fill
                  small
                  fab
                  @click="refreshBtnClick"
              >
                <app-svg
                    size="16"
                    src="~/assets/img/icons/icon-help.svg"
                />
              </v-btn>
            </template>

          </v-hover>
        </template>
        <span>Help Desk</span>
      </v-tooltip>

      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-tooltip
            bottom
            class="text-center"
            color="primary"
          >
            <span v-if="user && user.name">{{ user.name }}</span>
            <span v-else-if="!(user && user.name)"> User </span>

            <template #activator="data">
              <v-btn
                id="bar-account-button"
                class="mx-2"
                color="primary"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar
                  color="primary"
                  size="30"
                  v-bind="data.attrs"
                  v-on="data.on"
                >
                  <span class="white--text">
                   {{ userInitials }}
                  </span>
                </v-avatar>
              </v-btn>
            </template>
          </v-tooltip>
        </template>

        <v-list>
          <v-list-item id="menu-item-profile" link to="/profile">
            <v-list-item-title>Edit Profile</v-list-item-title>
          </v-list-item>

          <v-list-item id="menu-item-sign-out" link @click.native="logout()">
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>

import AppSvg from '@/components/app/AppSvg'
import { mapGetters } from 'vuex'

export default {
  name: 'TheDefaultLayoutWrapAppBar',
  components: { AppSvg },

  computed: {
    ...mapGetters(
      {
        user: 'auth/getCurrentUser'
      }
    ),

    userInitials () {
      if (!this.user?.name) {
        return 'NA'
      }

      const fullName = this.user.name.split(" ")

      if (fullName.length < 2) {
        return 'NA'
      }

      const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0)
      return initials.toUpperCase()
    }
  },

  methods: {
    getLogoImage () {
      return require('@/assets/img/sandbox-logo.png')
    },

    refreshBtnClick () {
      const url = "https://xd.adobe.com/view/4f9fc74a-facf-41cc-b97e-b0bde8fef9dc-1acc/?fullscreen"
      window.open(url, '_blank', 'noreferrer')
    },

    async logout () {
      await this.$store.dispatch('auth/signOut')
      await this.$router.replace('/login')
    },

    redirectToHomePage () {
      if (this.$route.path === '/dashboard') {
        return
      }

      this.$router.push(`/dashboard`)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-toolbar__content {
  padding: 15px 25px;
}
</style>
