<template>
  <div>
    <catalog-edit-left-side-panel
      v-if="catalog"
      :catalog-prop="catalog"
      @showDeleteDialog="showDeleteDialog"
      @showEditDescriptionDialog="showEditDescriptionDialog"
      @showEditImageDialog="showEditImageDialog"
      @showEditNameDialog="showEditNameDialog"
    />

    <app-image-dialog-edit
      v-if="isEditImageDialogVisible"
      :key="updateImageDialogKey + '-change-image-dialog'"
      v-model="isEditImageDialogVisible"
      :image-prop.sync="catalog.image"
      :is-error-occurred-while-uploading-prop.sync="isErrorOccurredWhileUploading"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      :validation-error-message-prop="validationErrorMessage"
      @changeImage="changeCatalogImage"
    />

    <catalog-dialog-edit-name
      v-if="isEditNameDialogVisible"
      :key="editNameDialogKey + '-catalog-dialog'"
      v-model="isEditNameDialogVisible"
      :catalog-name-prop="catalog.name"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      :is-name-already-exist-prop.sync="isNameAlreadyExist"
      :title-prop="'Edit Catalogue'"
      :validation-error-message-prop="validationErrorMessage"
      @changeCatalogName="changeCatalogName"
    />

    <catalog-dialog-edit-description
      v-if="isEditDescriptionDialogVisible"
      :key="editDescriptionDialogKey + '-catalog-bounding-size-dialog'"
      v-model="isEditDescriptionDialogVisible"
      :catalog-description-prop="catalog.description"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      :title-prop="'Edit Catalogue'"
      @changeCatalogDescription="changeCatalogDescription"
    />

    <catalog-dialog-delete
      v-if="isDeleteDialogVisible"
      v-model="isDeleteDialogVisible"
      :key="deleteDialogKey + '-delete-dialog'"
      :entity="catalog"
    />
  </div>
</template>

<script>
import CatalogEditLeftSidePanel from '@/components/catalog/edit/CatalogEditLeftSidePanel'
import AppImageDialogEdit from '@/components/app/image/AppImageDialogEdit'
import CatalogDialogEditName from '@/components/catalog/CatalogDialogEditName'
import CatalogDialogEditDescription from '@/components/catalog/CatalogDialogEditDescription'
import CatalogDialogDelete from '@/components/catalog/CatalogDialogDelete'

import { mapGetters } from 'vuex'
import { actionStates } from '@/store/modules/catalog'

export default {
  name: "CatalogEditLeftSidePanelContainer",
  components: {
    CatalogDialogEditDescription,
    CatalogEditLeftSidePanel,
    CatalogDialogEditName,
    AppImageDialogEdit,
    CatalogDialogDelete
  },

  data () {
    return {
      isEditNameDialogVisible: false,
      editNameDialogKey: 0,

      isEditImageDialogVisible: false,
      updateImageDialogKey: 0,

      isEditDescriptionDialogVisible: false,
      editDescriptionDialogKey: 0,

      isDeleteDialogVisible: false,
      deleteDialogKey: 0,

      actionStateConstants: actionStates,

      validationErrorMessage: '',
      isErrorOccurredWhileUploading: false,
      isNameAlreadyExist: false
    }
  },

  computed: {
    ...mapGetters(
      {
        catalog: 'catalog/getCatalog',
        currentState: 'catalog/getCurrentState'
      }
    )
  },

  methods: {
    showEditNameDialog () {
      this.isEditNameDialogVisible = true
      this.editNameDialogKey++
    },

    closeEditNameDialog () {
      this.isEditNameDialogVisible = false
    },

    showEditImageDialog () {
      this.isEditImageDialogVisible = true
      this.updateImageDialogKey++
    },

    showEditDescriptionDialog () {
      this.isEditDescriptionDialogVisible = true
      this.editDescriptionDialogKey++
    },

    showDeleteDialog () {
      this.isDeleteDialogVisible = true
      this.deleteDialogKey++
    },


    async changeCatalogName (catalogName) {
      try {
        await this.$store.dispatch('catalog/updateCatalogName', { id: this.catalog.id, name: catalogName })
      } catch (error) {
        if (error.response.status === 422) {
          this.isNameAlreadyExist = true
          this.validationErrorMessage = error.response.data.errors.name[0]
        }
      }
    },

    prepareFormDataForChangeImage (image) {
      let bodyFormData = new FormData()

      bodyFormData.set('id', this.catalog.id)
      bodyFormData.set('file', image)

      return bodyFormData
    },

    async changeCatalogImage (image) {
      try {
        const dataForChangingCatalogImage = this.prepareFormDataForChangeImage(image)
        await this.$store.dispatch('catalog/updateCatalogPicture', dataForChangingCatalogImage)
      } catch (error) {
        if (error.response.status === 422) {
          this.isErrorOccurredWhileUploading = true
          this.validationErrorMessage = error.response.data.errors.file[0]
        }
      }
    },

    async changeCatalogDescription (catalogDescription) {
      await this.$store.dispatch('catalog/updateCatalogDescription', {
        id: this.catalog.id,
        description: catalogDescription
      })
    }
  }
}
</script>

<style scoped>

</style>
