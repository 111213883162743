<template>
  <app-dialog
      v-model="isDialogVisible"
      :title-prop="'Project Limit Reached'"
      max-width="500"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <div>
      <div>
        You have reached your limitation of {{organization.project_limit}} projects.
        Delete one or more so you can add a new one.
      </div>

      <div class="mt-2">
        You can also contact the support to upgrade your license.
      </div>
    </div>

    <template #actions>
      <v-row class="d-flex justify-end">
        <v-col cols="6">
        <v-btn
              id="close-dialog-button"
              block
              color="primary"
              outlined
              raised
              rounded
              width="100"
              x-large
              @click.prevent="hideDialog"
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </template>

  </app-dialog>
</template>


<script>
import AppDialog from '@/components/app/AppDialog'
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectDialogLimitReached',

  components: { AppDialog },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      organization: 'organization/getOrganization'
    }),

    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }

  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },
  }


}
</script>



<style scoped lang="scss">

</style>