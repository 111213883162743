import Vue from 'vue'
import Vuex from 'vuex'
import snackbar from "@/store/modules/snackbar"
import auth from "@/store/modules/auth"
import project from '@/store/modules/project'
import module from '@/store/modules/module'
import catalog from '@/store/modules/catalog'
import category from '@/store/modules/category'
import organization from '@/store/modules/organization'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    snackbar,
    project,
    module,
    catalog,
    category,
    organization
  }
})
