import OrganizationService from '@/services/oeganizaiton.service'

const actionStates = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  ERRORED: "ERRORED",
  NOT_FOUND: "NOT_FOUND",
  DATA_LOADED: "DATA_LOADED",
  DIALOG_SUBMIT_LOADING: "DIALOG_SUBMIT_LOADING",
  DIALOG_SUBMIT_ERROR: "DIALOG_SUBMIT_ERROR"
}

const state = {
  currentState: actionStates.INITIAL,
  organization: null,
}
const getters = {
  getCurrentState (state) {
    return state.currentState
  },

  getOrganization (state) {
    return state.organization
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setOrganization (state, organization) {
    state.organization = organization
  },
}


const actions = {
  async fetchOrganization (vuexContext) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      const organization = await OrganizationService.getOrganization()

      vuexContext.commit('setOrganization', organization)
      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },
}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

