<template>
  <div>
    <app-single-item-edit-left-section
      v-if="projectProp"
      :entity-item-prop="projectProp"
      :entity-type-prop="'project'"
      @showEditDescriptionDialog="$emit('showEditDescriptionDialog')"
      @showEditImageDialog="$emit('showEditImageDialog')"
      @showEditNameDialog="$emit('showEditNameDialog')"
      @showEditProjectLogoDialog="$emit('showEditProjectLogoDialog')"
    >
      <template #other-edit-fields>
        <div class="text-center pb-7">
          <v-hover v-slot="{ hover }">
            <div
              class="hover-text-effect d-flex justify-center my-1 mx-auto"
              @click="$emit('showEditProjectLogoDialog')"
            >
              <div>
                <v-img
                  v-if="projectProp.logo && projectProp.logo.url"
                  id="project-logo"
                  :src="projectProp.logo.url"
                  contain
                  max-height="20"
                  max-width="80"
                  style="cursor:pointer; max-width:80px;"
                  v-bind="$attrs"
                  v-on="$listeners"
                />

                <span
                  v-else-if="!projectProp.logo"
                  class="logo-text "
                  style="cursor:pointer;"
                >
                Logo is empty click here to edit it
              </span>
              </div>

              <div>
                <v-btn
                  v-if="true"
                  :class="{ 'element-invisible': !hover }"
                  class="entity-fields-edit-logo-btn ml-1"
                  color="primary"
                  height="auto"
                  icon
                  small
                >
                  <app-svg
                    size="16"
                    src="~/assets/img/icons/camera.svg"
                  />
                </v-btn>
              </div>

            </div>
          </v-hover>
        </div>

        <div class="text-regular pb-4">
          <v-hover v-slot="{ hover }">
            <div
              class="description-text hover-text-effect"
              style="cursor:pointer;"
              @click="$emit('showEditDescriptionDialog')"
            >
              <span
                v-if="projectProp.description"
                style="
                 white-space: pre-wrap;
                 word-break: break-word;
                "
              >
                {{ projectProp.description }}
              </span>

              <span
                v-else-if="!projectProp.description"
                class="entity-fields-text-span"
              >
                Description is empty click here to edit it
              </span>

              <v-btn
                v-if="true"
                :class="{ 'element-invisible': !hover }"
                class="entity-fields-edit-description-btn ml-1"
                color="primary"
                height="22"
                icon
                small
                width="22"
              >
                <app-svg
                  size="14"
                  src="~/assets/img/icons/edit.svg"
                />
              </v-btn>
            </div>
          </v-hover>
        </div>

        <div class="pt-10">
          <v-tooltip
            bottom
            color="primary"
          >
            <span>Remove</span>

            <template #activator="{ on, attrs }">
              <v-btn
                class="delete-icon"
                color="primary"
                icon
                x-small
                v-bind="attrs"
                @click="$emit('showDeleteDialog')"
                v-on="on"
              >
                <app-svg
                  size="24"
                  src="~/assets/img/icons/delete.svg"
                />
              </v-btn>
            </template>

          </v-tooltip>
        </div>

      </template>
    </app-single-item-edit-left-section>
  </div>
</template>

<script>
import AppSingleItemEditLeftSection from '@/components/app/AppSingleItemEditLeftSection'

import AppSvg from '@/components/app/AppSvg'

export default {
  name: "ProjectEditLeftSidePanel",
  components: {
    AppSvg,
    AppSingleItemEditLeftSection
  },

  props: {
    projectProp: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
