<template>
  <div>
    <div
      class="add-new-rule-set d-flex align-center pa-2 mb-2"
      @click="clickAddNewRuleSet"
    >
      <v-avatar size="30">
        <div class="icon">
          <app-svg
            alt="icon"
            color="white"
            size="20"
            src="~/assets/img/icons/plus.svg"
          />
        </div>
      </v-avatar>

      <div class="section-title ml-5">
        Add new Rule Set
      </div>
    </div>

    <div class="rule-set-container overflow-auto my-1" v-for="ruleSet in shell.rule_sets" :key="ruleSet.id">
      <v-expansion-panels :value="ruleSet._expand">
        <app-expansion-panel
          class="rule-set-expansion-panel"
        >
          <template #header>
            <div class="d-flex  justify-space-between align-center py-1">
              <div>
                <span class="rule-set-name">{{ ruleSet.name }}</span>
              </div>

              <v-hover>
                <template v-slot="{ hover }">
                  <app-svg
                    class="mr-6 delete-rule-set-btn"
                    :color="hover ? 'primary' : 'var(--v-grey-darken2)'"
                    src="@/assets/img/icons/delete.svg"
                    size="20"
                    @click.native.stop="clickDeleteRuleSet(ruleSet)"
                  />
                </template>
              </v-hover>
            </div>
          </template>

          <project-tab-rule :rule-set="ruleSet" />

        </app-expansion-panel>
      </v-expansion-panels>
    </div>

<!--    Delete rule set dialog-->
    <delete-rule-set-or-single-rule-dialog
      v-model="showRuleSetDeleteDialog"
      @close="ruleSetDeleteDialog=false"
      class="delete-rule-set-dialog"
      :item-name="entityDeleteName"
      @delete="deleteItem"
    />

  </div>
</template>

<script>
import AppSvg from '@/components/app/AppSvg'
import AppExpansionPanel from '@/components/app/AppExpansionPanel'
import ProjectTabRule from '@/components/project/tab/rules/ProjectTabRule'
import DeleteRuleSetOrSingleRuleDialog from '@/components/project/tab/rules/dialogs/DeleteRuleSetOrSingleRuleDialog'

import { ProjectRuleSet } from '@/classes/project/ProjectRuleSet'

import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash'

export default {
  name: "ProjectTabRuleShellRuleSet",
  components: { AppSvg, AppExpansionPanel, ProjectTabRule, DeleteRuleSetOrSingleRuleDialog },
  props: {
    shell: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      ruleSetDeleteDialog: false,
      entityForDelete: {},
      entityDeleteName: ''
    }
  },
  computed: {
    showRuleSetDeleteDialog: {
      get () {
        return this.ruleSetDeleteDialog
      },
      set (value) {
        this.ruleSetDeleteDialog = value
        if (!value) {
          this.entityForDelete = {}
          this.entityDeleteName = ''
        }
      }
    },



    projectRuleModel () {
      return this.$store.state.project.ruleModel
    }
  },
  methods: {

    createRuleSetName () {
      const lastRuleSet = this.shell.rule_sets[this.shell.rule_sets.length - 1]

      let newNum

      if (lastRuleSet) {
        const currentNum = lastRuleSet._nameIndex
        newNum = currentNum + 1
      } else {
        newNum = 1
      }

      return newNum > 9 ? `Rule Set ${newNum}` : `Rule Set 0${newNum}`
    },

    clickAddNewRuleSet () {
      this.$store.dispatch('project/addNewRuleSet', {
        shell: this.shell,
        ruleSet: new ProjectRuleSet({
          id: uuidv4(),
          name: this.createRuleSetName(),
          rules: [{
            id: uuidv4(),
            parameter_name: null,
            operator: null,
            value: null
          }]
        })
      })
    },

    clickDeleteRuleSet (ruleSet) {
      this.entityForDelete = cloneDeep(ruleSet)
      this.entityDeleteName = this.entityForDelete.name
      this.ruleSetDeleteDialog = true
    },

    async deleteItem () {
      const payload = this.entityForDelete.getPayloadDeleteRuleSet(this.$store.getters['project/getRuleModel'])
      await this.$store.dispatch('project/deleteRuleSet',payload)
    }
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.add-new-rule-set {
  cursor: pointer;
  user-select: none;
  color: var(--v-grey-base);
  border: 1px solid var(--v-grey-lighten2);
  border-radius: $border-radius-root;
  transition: background $secondary-transition,
  border $secondary-transition,
  color $secondary-transition;

  .v-avatar {
    background: var(--v-grey-lighten1);
    transition: background $secondary-transition;
  }

  &:hover {
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);

    .v-avatar {
      background: var(--v-primary-base);
    }
  }
}

.rule-set-expansion-panel {
  .v-expansion-panel-header{
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .v-expansion-panel-content {
    .v-expansion-panel-content__wrap {
      padding: 0 !important;
    }
  }
}


@media screen and (max-width: 400px) {
  .rule-set-expansion-panel {
    .v-expansion-panel-content {
      .v-expansion-panel-content__wrap {
        padding: 20px !important;
      }
    }
  }
}

</style>