import { BaseClass } from '@/classes/base/BaseClass'
import { v4 as uuidv4 } from 'uuid'
// import store from '@/store'

export class ProjectSingleRule extends BaseClass {
  constructor (data) {
    super()

    this.id = data.id
    this.parameter_name = data.parameter_name
    this.operator = data.operator
    this.value = data.value
    // this._dataType = data.parameter_name ? this.getDataType() : null
    this._dataType = null

    this._valueError = null
    this._parameterError = null
    this._operatorError = null

    this._hasValueError = false
    this._hasParameterError = false
    this._hasOperatorError = false

    this._rerenderOperator = uuidv4()
    this._rerenderValue = uuidv4()
  }

  set setDataType (value) {
    this._dataType = value
  }

  get _disabledValueField () {
    return this._dataType === 'Bool' || !this.parameter_name || !this.operator
      || this.operator === 'has_value' || this.operator === 'has_no_value'
  }

  rulesMap () {
    const rulesIntAndDouble = [
      { id: uuidv4(), name: 'Equals', operator: '==' },
      { id: uuidv4(), name: 'Does not equal', operator: '!=' },
      { id: uuidv4(), name: 'Is greater than', operator: '>' },
      { id: uuidv4(), name: 'Is greater or equal to', operator: '>=' },
      { id: uuidv4(), name: 'Is less than', operator: '<' },
      { id: uuidv4(), name: 'Is less or equal to', operator: '<=' },
      { id: uuidv4(), name: 'Contains', operator: 'contains' },
      { id: uuidv4(), name: 'Contains', operator: 'contains' },
      { id: uuidv4(), name: 'Does not contain', operator: 'does_not_contain' },
      { id: uuidv4(), name: 'Begins with', operator: 'begins_with' },
      { id: uuidv4(), name: 'Does not begin with', operator: 'does_not_begin_with' },
      { id: uuidv4(), name: 'Ends with', operator: 'ends_with' },
      { id: uuidv4(), name: 'Does not end with', operator: 'does_not_end_with' },
      { id: uuidv4(), name: 'Has a value', operator: 'has_value' },
      { id: uuidv4(), name: 'Has no value', operator: 'has_no_value' }
    ]

    const map = {
      'ElementId': {
        type: 'string',
        toolTip: 'You can type anything!',
        rules: [
          { id: uuidv4(), name: 'Equals', operator: '==' },
          { id: uuidv4(), name: 'Does not equal', operator: '!=' }
        ]
      },
      'String': {
        type: 'string',
        toolTip: 'You can type anything!',
        rules: [
          { id: uuidv4(), name: 'Equals', operator: '==' },
          { id: uuidv4(), name: 'Does not equal', operator: '!=' },
          { id: uuidv4(), name: 'Contains', operator: 'contains' },
          { id: uuidv4(), name: 'Does not contain', operator: 'does_not_contain' },
          { id: uuidv4(), name: 'Begins with', operator: 'begins_with' },
          { id: uuidv4(), name: 'Does not begin with', operator: 'does_not_begin_with' },
          { id: uuidv4(), name: 'Ends with', operator: 'ends_with' },
          { id: uuidv4(), name: 'Does not end with', operator: 'does_not_end_with' },
          { id: uuidv4(), name: 'Has a value', operator: 'has_value' },
          { id: uuidv4(), name: 'Has no value', operator: 'has_no_value' }
        ]
      },
      'Double': {
        type: 'double',
        rules: rulesIntAndDouble,
        validationRegex: /^(?!-0(?!\.))(?!(?:\.-|\.)\d)(?!^\+)(?!0\d)(?!-\.)[-]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/,
        validationMsg: 'The value must be a positive or negative number!',
        toolTip: 'The value must be a positive or negative number!'
      },
      'Integer': {
        type: 'int',
        rules: rulesIntAndDouble,
        validationRegex: /^-?(?!0\d)(?!-0$)(?!0+$)\d+$|^0$/,
        validationMsg: 'The value must be a positive or negative whole number!',
        toolTip: 'The value must be a positive or negative whole number!'
      },
      'Bool': {
        type: 'boolean',
        rules: [
          { id: uuidv4(), name: 'Is yes', operator: 'true' },
          { id: uuidv4(), name: 'Is no', operator: 'false' },
          { id: uuidv4(), name: 'Has a value', operator: 'has_value' },
          { id: uuidv4(), name: 'Has no value', operator: 'has_no_value' }
        ]
      }
    }

    return map[`${ this._dataType }`]
  }

  get _suitableRules () {
    const rulesMap = this.rulesMap()
    if (rulesMap) {
      return rulesMap.rules
    }
    return []
  }

  get _currentOperator () {
    const rulesMap = this.rulesMap()
    if (this.operator && rulesMap) {
      return rulesMap.rules.find(item => item.operator === this.operator)
    }
    return null
  }

  get _deleteName () {
    if (this.parameter_name || this._currentOperator || this.value) {
      return `${ this.parameter_name } ${ this._currentOperator ? this._currentOperator.name : null } ${ this.value }`
    }
    return ''
  }

  getCurrentRuleSet (ruleModel) {
    let currentRuleSet
    let currentRule

    for (const shell of ruleModel.shells) {
      for (const ruleSet of shell.rule_sets) {
        for (const rule of ruleSet.rules) {
          if (rule.id === this.id) {
            currentRuleSet = ruleSet
            currentRule = rule
            return { currentRuleSet, currentRule }
          }
        }
      }
    }
  }

  getPayloadDeleteRule (ruleModel) {
    const { currentRuleSet } = this.getCurrentRuleSet(ruleModel)
    const { currentRule } = this.getCurrentRuleSet(ruleModel)

    const ruleIndex = currentRuleSet.rules.indexOf(currentRule)

    return {
      ruleSet: currentRuleSet,
      ruleIndex
    }
  }

  get _toolTip () {
    const rulesMap = this.rulesMap()
    return rulesMap ? rulesMap.toolTip : ''
  }

  validateValue () {
    if (!this._disabledValueField) {
      const rulesMap = this.rulesMap()
      const validationRegex = rulesMap?.validationRegex

      if ((!this.value || !this.value.length) && this._dataType !== 'Bool') {
        this._valueError = 'Value is required!'
        this._hasValueError = true
      } else if (rulesMap && validationRegex && !validationRegex.test(this.value)) {
        this._valueError = rulesMap.validationMsg
        this._hasValueError = true
      } else {
        this._valueError = null
        this._hasValueError = false
      }
    }

  }

  validateParameterName () {
    if (!this.parameter_name) {
      this._hasParameterError = true
      this._parameterError = 'You must select Module Parameter'
    } else {
      this._parameterError = null
      this._hasParameterError = false
    }
  }

  validateOperator () {
    if (!this.operator) {
      this._hasOperatorError = true
      this._operatorError = 'You must select Rule'
    } else {
      this._operatorError = null
      this._hasOperatorError = false
    }
  }

  get _hasAnyError () {
    return this._hasValueError || this._hasOperatorError || this._hasParameterError
  }

  validate () {
    this.validateOperator()
    this.validateValue()
    this.validateParameterName()
  }

  get _payload () {
    return {
      id: this.id,
      parameter_name: this.parameter_name,
      operator: this.operator,
      value: this.value
    }
  }

}