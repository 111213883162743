<template>
  <div>
    <project-edit-left-side-panel
      v-if="project"
      :project-prop="project"
      @showDeleteDialog="showDeleteDialog"
      @showEditDescriptionDialog="showEditDescriptionDialog"
      @showEditImageDialog="showEditImageDialog"
      @showEditNameDialog="showEditNameDialog"
      @showEditProjectLogoDialog="showEditProjectLogoDialog"
    />

    <app-image-dialog-edit
      v-if="isEditImageDialogVisible"
      :key="updateImageDialogKey + '-change-image-dialog'"
      v-model="isEditImageDialogVisible"
      :image-prop.sync="project.image"
      :is-error-occurred-while-uploading-prop.sync="isErrorOccurredWhileUploading"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      :validation-error-message-prop="validationErrorMessage"
      @changeImage="changeProjectImage"
    />

    <project-dialog-name-create-edit
      v-if="isEditNameDialogVisible"
      :key="editNameDialogKey + '-project-dialog'"
      v-model="isEditNameDialogVisible"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      :is-name-already-exist-prop.sync="isNameAlreadyExist"
      :project-name-prop="project.name"
      :title-prop="'Edit Project'"
      :validation-error-message-prop.sync="validationErrorMessage"
      action="edit"
      @changeProjectName="changeProjectName"
    />

    <project-dialog-description-edit
      v-if="isEditDescriptionDialogVisible"
      :key="editDescriptionDialogKey + '-project-description-dialog'"
      v-model="isEditDescriptionDialogVisible"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      :project-description-prop="project.description"
      :title-prop="'Edit Project Description'"
      @changeProjectDescription="changeProjectDescription"
    />

    <app-image-dialog-edit
      v-if="isEditLogoDialogVisible"
      :key="updateLogoDialogKey + '-change-logo-dialog'"
      v-model="isEditLogoDialogVisible"
      :image-prop="project.logo"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      @changeImage="changeProjectLogo"
    />

    <project-dialog-delete
      v-if="isDeleteDialogVisible"
      v-model="isDeleteDialogVisible"
      :key="deleteDialogKey + '-delete-dialog'"
     :entity="project"
    />
  </div>
</template>

<script>
import ProjectEditLeftSidePanel from '@/components/project/edit/ProjectEditLeftSidePanel'
import AppImageDialogEdit from '@/components/app/image/AppImageDialogEdit'
import ProjectDialogDescriptionEdit from '@/components/project/ProjectDialogDescriptionEdit'
import ProjectDialogNameCreateEdit from '@/components/project/ProjectDialogNameCreateEdit'
import ProjectDialogDelete from '@/components/project/ProjectDialogDelete'

import { mapGetters } from 'vuex'
import { actionStates } from '@/store/modules/project'

export default {
  name: "ProjectEditLeftSidePanelContainer",
  components: {
    ProjectEditLeftSidePanel,
    AppImageDialogEdit,
    ProjectDialogDescriptionEdit,
    ProjectDialogNameCreateEdit,
    ProjectDialogDelete
  },

  data () {
    return {
      isEditNameDialogVisible: false,
      editNameDialogKey: 0,

      isEditImageDialogVisible: false,
      updateImageDialogKey: 0,

      isEditDescriptionDialogVisible: false,
      editDescriptionDialogKey: 0,

      isEditLogoDialogVisible: false,
      updateLogoDialogKey: 0,

      actionStateConstants: actionStates,

      validationErrorMessage: '',
      isErrorOccurredWhileUploading: false,
      isNameAlreadyExist: false,

      isDeleteDialogVisible: false,
      deleteDialogKey: 0,
    }
  },

  computed: {
    ...mapGetters(
      {
        project: 'project/getProject',
        currentState: 'project/getCurrentState'
      }
    )
  },

  methods: {
    showEditNameDialog () {
      this.isEditNameDialogVisible = true
      this.editNameDialogKey++
    },

    closeEditNameDialog () {
      this.isEditNameDialogVisible = false
    },

    showEditImageDialog () {
      this.isEditImageDialogVisible = true
      this.updateImageDialogKey++
    },

    showEditProjectLogoDialog () {
      this.isEditLogoDialogVisible = true
      this.updateLogoDialogKey++
    },

    showEditDescriptionDialog () {
      this.isEditDescriptionDialogVisible = true
      this.editDescriptionDialogKey++
    },

    showDialogLocation () {
    },

    async changeProjectName (projectName) {
      try {
        await this.$store.dispatch('project/updateProjectName', { id: this.project.id, name: projectName })
      } catch (error) {
        if (error.response.status === 422) {
          this.isNameAlreadyExist = true
          this.validationErrorMessage = error.response.data.errors.name[0]
        }
      }
    },

    prepareFormDataForChangeImage (image) {
      let bodyFormData = new FormData()

      bodyFormData.set('id', this.project.id)
      bodyFormData.set('file', image)

      return bodyFormData
    },

    showDeleteDialog () {
      this.isDeleteDialogVisible = true
      this.deleteDialogKey++
    },

    async changeProjectImage (image) {
      try {
        const dataForChangingProjectImage = this.prepareFormDataForChangeImage(image)
        await this.$store.dispatch('project/updateProjectPicture', dataForChangingProjectImage)
      } catch (error) {
        if (error.response.status === 422) {
          this.isErrorOccurredWhileUploading = true
          this.validationErrorMessage = error.response.data.errors.file[0]
        }
      }
    },

    async changeProjectLogo (image) {
      const dataForChangingProjectImage = await this.prepareFormDataForChangeImage(image)
      await this.$store.dispatch('project/updateProjectLogo', dataForChangingProjectImage)
    },

    async changeProjectDescription (projectDescription) {
      await this.$store.dispatch('project/updateProjectDescription', {
        id: this.project.id,
        description: projectDescription
      })
    }
  }
}
</script>

<style scoped>

</style>
